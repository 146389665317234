import { ArrayDataTable, Link, Surface } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import ActionHeader from "../ActionHeader";

const Services = ({ services, childId }) => (
  <div style={{ maxWidth: "98.5%", margin: "0 8px 8px" }}>
    <Surface title="Services" hideTitle>
      <ActionHeader
        title="Services"
        action="Add Service"
        actionLink={`/admin/children/${childId}/services/new`}
      />
      <ArrayDataTable
        columns={[
          {
            id: "createdBy",
            columnName: {
              name: I18n.t("admin.reports.services.service_added_by"),
              justify: "start",
            },
            cell: ({ createdByName, createdByLink }) => (
              <Link href={createdByLink}>{createdByName}</Link>
            ),
          },
          {
            id: "category",
            columnName: {
              name: I18n.t("admin.reports.services.service_category"),
              justify: "start",
            },
            cell: "category",
          },
          {
            id: "service",
            columnName: {
              name: I18n.t("admin.reports.services.service"),
              justify: "start",
            },
            cell: "service",
          },
          {
            id: "offeringDates",
            columnName: {
              name: I18n.t("admin.reports.services.service_offering_dates"),
              justify: "start",
            },
            cell: ({ offeringDates }) => (
              <Fragment>
                {offeringDates.map(date => (
                  <div key={date}>{date}</div>
                ))}
              </Fragment>
            ),
          },
          {
            id: "usedDates",
            columnName: {
              name: I18n.t("admin.reports.services.service_used_dates"),
              justify: "start",
            },
            cell: ({ serviceFrequency, occurrenceDates }) => (
              <Fragment>
                <div>
                  <strong>{serviceFrequency}</strong>
                </div>
                <div>{occurrenceDates}</div>
              </Fragment>
            ),
          },
        ]}
        data={services}
      />
    </Surface>
  </div>
);

Services.propTypes = {
  childId: PropTypes.number.isRequired,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Services;
