import { Flex, Link, Surface } from "@heart/components";
import PropTypes from "prop-types";

import Education from "./Education";
import MedicalVisits from "./MedicalVisits";
import Medications from "./Medications";
import Providers from "./Providers";
import ChildInformation from "./child-information.svg";
import LinkedParentBadge from "./linked-parent-badge.svg";
import ParentHeading from "./parent-heading.svg";
import SiblingsInCare from "./siblings-in-care.svg";
import UnlinkedParentBadge from "./unlinked-parent-badge.svg";

const parentLink = new URL(window.location);
parentLink.searchParams.set("parent", "true");

const ChildInfo = ({ medicationsFormLink }) => (
  <Flex column>
    <img src={ChildInformation} alt="AI Buddy" />
    <div style={{ maxWidth: "98.5%", margin: "0 8px 8px" }}>
      <Surface title="Relatives" hideTitle>
        <img src={SiblingsInCare} alt="Siblings In Care" />
        <img src={ParentHeading} alt="Parent Heading" />
        <Flex row>
          <Link href={parentLink.toString()}>
            <img src={LinkedParentBadge} alt="Linked Parent Badge"></img>
          </Link>
          <img src={UnlinkedParentBadge} alt="Unlinked Parent Badge"></img>
        </Flex>
      </Surface>
    </div>
    <Education />
    <div style={{ maxWidth: "98.5%", margin: "0 8px 8px" }}>
      <Surface title="Permanency Goal" hideTitle>
        <Providers />
        <div className="space-below-2" />
        <Medications medicationsFormLink={medicationsFormLink} />
        <div className="space-below-2" />
        <MedicalVisits />
      </Surface>
    </div>
  </Flex>
);

ChildInfo.propTypes = {
  medicationsFormLink: PropTypes.string.isRequired,
};

export default ChildInfo;
