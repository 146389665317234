import { ArrayDataTable, Flex, Icons, Link, Surface } from "@heart/components";
import { Fragment } from "react";

import ActionHeader from "../ActionHeader";

const Education = () => (
  <div style={{ maxWidth: "98.5%", margin: "0 8px 8px" }}>
    <Surface title="Permanency Goal" hideTitle>
      <ActionHeader title="Education" action="Add New School Record" />
      <ArrayDataTable
        columns={[
          {
            id: "school",
            columnName: { name: "School", justify: "start" },
            cell: ({ school, dates, grade }) => (
              <Flex column>
                <Flex row>{school}</Flex>
                <If condition={dates}>
                  <Flex row>
                    <Icons.Calendar />
                    {dates}
                  </Flex>
                </If>
                <Flex row>{grade}</Flex>
              </Flex>
            ),
          },
          {
            id: "iep",
            columnName: {
              name: <Fragment>IEP&nbsp;or&nbsp;504</Fragment>,
              justify: "start",
            },
            cell: "iep",
          },
          {
            id: "information",
            columnName: { name: "Information", justify: "start" },
            cell: ({ phone, address }) => (
              <Flex column>
                <Flex row>
                  <Icons.Phone /> {phone}
                </Flex>
                <Flex row>
                  <Icons.MapMarker /> <Flex column>{address}</Flex>
                </Flex>
              </Flex>
            ),
          },
          {
            id: "notes",
            columnName: { name: "Notes", justify: "start" },
            cell: "notes",
          },
          {
            id: "actions",
            columnName: { name: "Actions", justify: "start" },
            cell: ({ pencilLink, dotDotDotLink }) => (
              <Flex row>
                <Link href={pencilLink}>
                  <Icons.Pencil />
                </Link>
                <Link href={dotDotDotLink}>
                  <Icons.Ellipsis />
                </Link>
              </Flex>
            ),
          },
        ]}
        data={[
          {
            school: "Happy Hearts Academy of Early Learning",
            dates: "08/15/2023 - Present",
            grade: "1st grade",
            iep: "No",
            phone: "(918) 835-2941",
            address: (
              <Fragment>
                1010 N Yale Ave
                <br />
                Tulsa, OK 74115
              </Fragment>
            ),
            notes: "School counselor is Ms. Garcia, extension #204",
            pencilLink: "#",
            dotDotDotLink: "#",
          },
          {
            school: "Hamilton Elementary School",
            grade: "Kindergarten",
            iep: "No",
            phone: "(918) 746-9440",
            address: (
              <Fragment>
                2316 N Norwood Pl
                <br />
                Tulsa, OK 74115
              </Fragment>
            ),
            notes: "Faxed school record request on 04/18",
            pencilLink: "#",
            dotDotDotLink: "#",
          },
        ]}
      />
    </Surface>
  </div>
);

export default Education;
