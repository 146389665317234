import { Layout } from "@heart/components";
import useCurrentPageSearchParam from "@heart/components/layout/page_layout/useCurrentPageSearchParam";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import DocumentsTable from "../family_finding/documents/DocumentsTable";
import AgencyHumanSidebar from "./AgencyHumanSidebar";

const { t } = translationWithRoot("agency_human.family_finding_documents");

const FamilyFindingDocuments = ({ agencyHumanId, agencyHumanName }) => {
  // must use this hook to get the correct bolding applied to the sidebar link
  useCurrentPageSearchParam({ defaultPage: "family_finding_documents" });

  return (
    <Layout
      pageTitle={t("page_title", { name: agencyHumanName })}
      sidebar={{
        fullHeight: true,
        opaque: true,
        content: (
          <AgencyHumanSidebar
            label={t("page_title", { name: agencyHumanName })}
            agencyHumanId={agencyHumanId}
          />
        ),
      }}
      main={{
        content: <DocumentsTable agencyHumanId={agencyHumanId} />,
      }}
    />
  );
};
FamilyFindingDocuments.propTypes = {
  agencyHumanId: PropTypes.string.isRequired,
  agencyHumanName: PropTypes.string.isRequired,
};

export default FamilyFindingDocuments;
