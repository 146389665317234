import { Flex } from "@heart/components";
import PropTypes from "prop-types";

import CourtInformation from "./CourtInformation";
import LevelOfCare from "./LevelOfCare";
import PermanencyGoal from "./PermanencyGoal";
import Services from "./Services";
import CaseInformation from "./case-information.svg";

const CaseManagement = ({ services, childId, courtEditLink }) => (
  <Flex column>
    <img src={CaseInformation} alt="Case Information" />
    <CourtInformation courtEditLink={courtEditLink} />
    <LevelOfCare />
    <PermanencyGoal />
    <Services services={services} childId={childId} />
  </Flex>
);
CaseManagement.propTypes = {
  childId: PropTypes.number.isRequired,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  courtEditLink: PropTypes.string.isRequired,
};

export default CaseManagement;
