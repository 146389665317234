import { Text } from "@heart/components";
import Icon from "@heart/components/icon/Icon";
import { Table, Tbody, Td, Th, Thead, Tr } from "@heart/components/table/Table";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";

import { translationWithRoot } from "@components/T";

import styles from "./AgenciesAssigned.module.scss";

const { t } = translationWithRoot("placement_search.agencies_assigned");

const emptyArray = [];

const AgenciesAssignedForm = ({ formState, onChange }) => {
  const assignedSearches = formState?.assignedSearches || emptyArray;

  const assignedAgencyIds =
    assignedSearches?.map(s => s.agency.id) || emptyArray;

  const unassignAgency = agencyId =>
    onChange(assignedSearches.filter(as => as.agency.id !== agencyId));

  const tableStyles = {
    verticalAlign: "middle",
  };

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>{I18n.t("admin.common.name")}</Th>
          <Th>{I18n.t("javascript.components.placement_search.response")}</Th>
          <Th>{I18n.t("admin.common.actions")}</Th>
        </Tr>
      </Thead>
      <Tbody aria-live="polite">
        <Choose>
          <When condition={!isEmpty(assignedAgencyIds)}>
            {assignedSearches.map(({ agency: { id, name }, response }) => (
              <Tr key={id}>
                <Td style={tableStyles}>
                  <Text>{name}</Text>
                </Td>
                <Td
                  style={tableStyles}
                  className={styles[response?.replaceAll("_", "-")]}
                >
                  {I18n.t(
                    `javascript.components.placement_search.responses.${
                      response || "awaiting_placement"
                    }`
                  )}
                </Td>
                <Td style={tableStyles}>
                  <Icon
                    icon="trash"
                    description={`${t("unassign")} ${name}`}
                    onClick={() => unassignAgency(id)}
                  />
                </Td>
              </Tr>
            ))}
          </When>
          <Otherwise>
            <Tr>
              <Td colSpan={3} style={{ textAlign: "left" }}>
                {t("no_agencies_assigned")}
              </Td>
            </Tr>
          </Otherwise>
        </Choose>
      </Tbody>
    </Table>
  );
};

AgenciesAssignedForm.propTypes = {
  formState: PropTypes.object,
  onChange: PropTypes.func,
};

export default AgenciesAssignedForm;
