import { gql, useQuery } from "@apollo/client";
import {
  Actions,
  InputDate,
  LoadingOverlay,
  SurfaceForm,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import _ from "lodash";
import { PropTypes } from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";
import AgencyDropdown from "@components/shared/AgencyDropdown";
import BannerContainer from "@components/shared/banner/BannerContainer";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const { t } = translationWithRoot("child_agency_placement_referral");

const emptyObject = {};

const ChildAgencyPlacementReferralForm = ({ childId, onSubmit, referral }) => {
  const { data, loading, error } = useQuery(
    gql`
      query Child($childId: ID!) {
        child(id: $childId) {
          id
          firstName
          lastName
        }
      }
    `,
    {
      variables: { childId },
    }
  );

  const child = data?.child || {};

  const onCancel = () => history.back();

  const { formState, setFormAttribute } = useBintiForm(referral || emptyObject);

  return (
    <LoadingOverlay active={loading}>
      <If condition={error}>
        <BannerContainer type="error" message={error.message} />
      </If>
      <SurfaceForm
        title={`${t("placement_for")} ${child.firstName} ${child.lastName}`}
        actions={<Actions cancelAction={onCancel} />}
        onSubmit={preventDefault(() =>
          onSubmit(_.omit(formState, "__typename"))
        )}
      >
        <AgencyDropdown
          required
          label={I18n.t("views.common.agency")}
          value={formState.referredAgencyId}
          onChange={setFormAttribute("referredAgencyId")}
        />
        <InputDate
          required
          label={t("assigned_at")}
          value={formState.assignedAt}
          onChange={setFormAttribute("assignedAt")}
        />
        <InputDate
          label={t("withdrawn_at")}
          value={formState.withdrawnAt}
          onChange={setFormAttribute("withdrawnAt")}
        />
      </SurfaceForm>
    </LoadingOverlay>
  );
};

ChildAgencyPlacementReferralForm.propTypes = {
  childId: BintiPropTypes.ID,
  onSubmit: PropTypes.func,
  referral: PropTypes.object,
};

export default ChildAgencyPlacementReferralForm;
