import { useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  HeartTable,
  LabeledContent,
  Layout,
  LoadingOverlay,
  Surface,
  TableCellList,
  Text,
} from "@heart/components";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { Fragment } from "react";
import {
  detailsFamilyFindingClearPersonSearchPath,
  newFamilyFindingClearPersonSearchPath,
} from "routes";

import { translationWithRoot } from "@components/T";
import ErrorBanner from "@components/shared/banner/ErrorBanner";

import ClearPersonSearch from "@graphql/queries/ClearPersonSearch.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

const { Table, Caption, Td, Th, Thead, Tr, Tbody } = HeartTable;

const { t, T } = translationWithRoot("family_finding.potential_kin_search");

const { T: CommonT } = translationWithRoot("views.common", {
  escapeJavascriptRoot: true,
});

const SearchField = ({ label, value }) => {
  if (value) {
    return (
      <Text>
        {label}: {value}
      </Text>
    );
  }

  return null;
};
SearchField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  value: PropTypes.any,
};

const SearchResults = ({ searchId }) => {
  const {
    data = {},
    error,
    loading,
  } = useQuery(ClearPersonSearch, {
    variables: { id: searchId },
  });
  const { clearPersonSearch = { searchFields: {}, results: [] } } = data;
  const { searchFields } = clearPersonSearch;
  const { addressLine1, addressLine2, city, state, zip } = searchFields;

  const address =
    city && state
      ? [addressLine1, addressLine2, [city, state].join(", "), zip]
          .filter(Boolean)
          .join(" ")
      : [addressLine1, addressLine2, city, state, zip]
          .filter(Boolean)
          .join(" ");

  return (
    <LoadingOverlay active={loading}>
      <If condition={error}>
        <ErrorBanner message={error.graphQLErrors[0].message} />
      </If>
      <Layout
        pageTitle={<T t="results.search_results" />}
        subtitle={t("results.num_matching", {
          count: clearPersonSearch.results.length,
        })}
        main={{
          content: (
            <Fragment>
              <Surface
                title={<T t="results.search_criteria" />}
                secondary={
                  <Button
                    variant="secondary"
                    href={newFamilyFindingClearPersonSearchPath({
                      editing_search_id: searchId,
                    })}
                  >
                    <T t="results.edit_search" />
                  </Button>
                }
              >
                <Flex gap="200" row>
                  <If
                    condition={searchFields.firstName || searchFields.lastName}
                  >
                    <LabeledContent
                      label={<T t="results.name" />}
                      content={[
                        searchFields.firstName,
                        searchFields.middleName,
                        searchFields.lastName,
                      ].join(" ")}
                    />
                  </If>
                  <LabeledContent
                    label={<CommonT t="phone_number" />}
                    content={searchFields.phoneNumber}
                  />
                  <LabeledContent
                    label={<CommonT t="email_address" />}
                    content={searchFields.email}
                  />
                  <LabeledContent
                    label={<T t="common.ssn" />}
                    content={searchFields.ssn}
                  />
                  <LabeledContent
                    label={<T t="common.drivers_license" />}
                    content={searchFields.driversLicenseNumber}
                  />
                  <LabeledContent
                    label={<T t="common.entity_id" />}
                    content={searchFields.entityId}
                  />
                  <LabeledContent
                    label={<CommonT t="date_of_birth" />}
                    content={
                      searchFields.dateOfBirth &&
                      DateTime.fromISO(
                        searchFields.dateOfBirth
                      ).toLocaleString()
                    }
                  />
                  <LabeledContent
                    label={<T t="common.age_range_min" />}
                    content={searchFields.ageRangeMin}
                  />
                  <LabeledContent
                    label={<T t="common.age_range_max" />}
                    content={searchFields.ageRangeMax}
                  />
                  <LabeledContent
                    label={<T t="common.address_line_1" />}
                    content={address}
                  />
                </Flex>
              </Surface>
              <T t="results.comprehensive_search" />
              <Table>
                <Caption hidden>
                  <T t="results.results" />
                </Caption>
                <Thead>
                  <Tr>
                    <Th>
                      <T t="results.name" />
                    </Th>
                    <Th>
                      <T t="results.personal_information" />
                    </Th>
                    <Th>
                      <T t="results.contact_information" />
                    </Th>
                    <Th>
                      <T t="results.actions" />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <If
                    condition={!loading && isEmpty(clearPersonSearch.results)}
                  >
                    <Tr>
                      <Td colSpan={4}>
                        <Flex grow justify="center">
                          {t("no_data")}
                        </Flex>
                      </Td>
                    </Tr>
                  </If>
                  {clearPersonSearch.results.map(result => (
                    <Tr key={result.groupId}>
                      <Td>
                        <Text textStyle="emphasis-200">{result.fullName}</Text>
                      </Td>
                      <Td>
                        <TableCellList
                          items={[
                            <LabeledContent
                              key={result.dateOfBirth}
                              label={<CommonT t="date_of_birth" />}
                              content={DateTime.fromISO(result.dateOfBirth, {
                                setZone: true,
                              }).toLocaleString()}
                            />,
                            <If
                              condition={result.dateOfDeath}
                              key={result.dateOfDeath}
                            >
                              <LabeledContent
                                label={<CommonT t="date_of_death" />}
                                content={DateTime.fromISO(result.dateOfDeath, {
                                  setZone: true,
                                }).toLocaleString()}
                              />
                            </If>,
                            <LabeledContent
                              label={<CommonT t="address" />}
                              content={
                                result.primaryAddress ? (
                                  result.primaryAddress.addressLine1
                                ) : (
                                  <Text textColor="neutral-600">None</Text>
                                )
                              }
                              key={result.primaryAddress?.addressLine1}
                            />,
                            result.primaryAddress
                              ? `${result.primaryAddress.city}, ${result.primaryAddress.state} ${result.primaryAddress.zip}`
                              : "",
                            t("results.additional_addresses", {
                              count: result.additionalAddressesCount,
                            }),
                          ].filter(Boolean)}
                        />
                      </Td>
                      <Td>
                        <TableCellList
                          items={[
                            <LabeledContent
                              key={`${result.fullName}_phoneNumber`}
                              label={<CommonT t="phone_number" />}
                              content={
                                result.phoneNumber ? (
                                  result.phoneNumber.phoneNumber
                                ) : (
                                  <Text textColor="neutral-600">None</Text>
                                )
                              }
                            />,
                            t("results.additional_phone_numbers", {
                              count: result.additionalPhoneNumbersCount,
                            }),
                            <LabeledContent
                              key={result.emailAddress}
                              label={<CommonT t="email_address" />}
                              content={
                                result.emailAddress ? (
                                  result.emailAddress
                                ) : (
                                  <Text textColor="neutral-600">None</Text>
                                )
                              }
                            />,
                            t("results.additional_email_addresses", {
                              count: result.additionalEmailAddressesCount,
                            }),
                          ]}
                        />
                      </Td>
                      <Td>
                        <Button
                          variant="primary"
                          href={detailsFamilyFindingClearPersonSearchPath(
                            searchId,
                            { groupId: result.groupId }
                          )}
                        >
                          <T t="results.more_details" />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Fragment>
          ),
        }}
      />
    </LoadingOverlay>
  );
};

SearchResults.propTypes = {
  searchId: BintiPropTypes.ID,
};

export default SearchResults;
