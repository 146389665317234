import { gql, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  InputFilterable,
  LoadingOverlay,
  Surface,
  Text,
} from "@heart/components";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";

const emptyArray = [];
const dummyId = 1;

const { t } = translationWithRoot("placement_search.agencies_assigned");

const AddAgenciesForm = ({ placementSearchId, onSubmit, onCancel }) => {
  const { data, loading: dataLoading } = useQuery(
    gql`
      query AddAgenciesModal(
        $placementSearchId: ID!
        $placementExists: Boolean!
      ) {
        agencies {
          id
          name
        }
        placementSearch(id: $placementSearchId) @include(if: $placementExists) {
          id
          assignedSearches {
            id
            agency {
              id
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        placementSearchId: placementSearchId || dummyId,
        placementExists: !!placementSearchId,
      },
    }
  );

  const [agenciesToAdd, setAgenciesToAdd] = useState(null);

  const agenciesAvailable = data?.agencies || emptyArray;
  const assignedSearches =
    data?.placementSearch?.assignedSearches || emptyArray;

  const assignedAgencyIds =
    assignedSearches?.map(s => s.agency.id) || emptyArray;

  const availableAgencies = useMemo(
    () => agenciesAvailable.filter(({ id }) => !assignedAgencyIds.includes(id)),
    [assignedAgencyIds, agenciesAvailable]
  );

  return (
    <LoadingOverlay active={dataLoading}>
      <Surface hideTitle data-testid="add-agencies-form">
        <Choose>
          <When condition={!!availableAgencies.length}>
            <InputFilterable
              isMulti
              label={t("available")}
              onChange={setAgenciesToAdd}
              options={availableAgencies.map(({ id, name }) => ({
                label: name,
                value: id.toString(),
              }))}
              value={agenciesToAdd}
            />
            <Flex justify="end">
              <Button variant="tertiary" onClick={onCancel}>
                {t("cancel")}
              </Button>
              <Button
                onClick={() =>
                  onSubmit(
                    agenciesToAdd.map(a => ({ id: a.value, name: a.label }))
                  )
                }
              >
                {t("assign")}
              </Button>
            </Flex>
          </When>
          <Otherwise>
            <Text>{t("no_agencies_available")}</Text>
          </Otherwise>
        </Choose>
      </Surface>
    </LoadingOverlay>
  );
};

AddAgenciesForm.propTypes = {
  placementSearchId: BintiPropTypes.ID,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default AddAgenciesForm;
