import { useQuery, gql, useMutation } from "@apollo/client";
import { Flex, LoadingOverlay, Surface } from "@heart/components";
import I18n from "i18n-js";
import _ from "lodash";
import { PropTypes } from "prop-types";

import ErrorBanner from "@components/shared/banner/ErrorBanner";

import UpdatePlacementSearchAssignedAgenciesMutation from "@graphql/mutations/UpdatePlacementSearchAssignedAgencies.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import AgenciesAssignedForm from "./AgenciesAssignedForm";
import AddAgencies from "./add_agencies/AddAgencies";

/** View and edit assigned workers for a given placement search.
 *    Placement Searches > Row Action > Edit Placements Search
 */
const AgenciesAssignedEdit = ({ placementSearchId, onCompleted }) => {
  const {
    data,
    loading: dataQueryLoading,
    error: dataQueryError,
  } = useQuery(
    gql`
      query AgenciesAssignedEditData($placementSearchId: ID!) {
        placementSearch(id: $placementSearchId) {
          id
          assignedSearches {
            id
            response
            agency {
              id
              name
            }
          }
        }
      }
    `,
    {
      variables: { placementSearchId },
    }
  );

  const [
    updateAgenciesAssigned,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UpdatePlacementSearchAssignedAgenciesMutation, {
    onCompleted,
  });

  const onChange = assignedSearches => {
    updateAgenciesAssigned({
      variables: {
        input: {
          ..._.omit(data?.placementSearch, "__typename", "assignedSearches"),
          assignedAgencyIds: assignedSearches?.map(as => as.agency.id) || [],
        },
      },
    });
  };

  const error = dataQueryError || mutationError;

  return (
    <LoadingOverlay active={dataQueryLoading || mutationLoading}>
      <If condition={error}>
        <ErrorBanner message={error.message} />
      </If>
      <Surface
        title={I18n.t(
          "javascript.components.placement_search.agencies_assigned.title"
        )}
      >
        <Flex column>
          <AgenciesAssignedForm
            formState={data?.placementSearch}
            onChange={onChange}
          />
          <AddAgencies
            placementSearchId={placementSearchId}
            onSubmit={newAgencies =>
              onChange([
                ...data.placementSearch.assignedSearches,
                ...newAgencies.map(agency => ({ agency })),
              ])
            }
          />
        </Flex>
      </Surface>
    </LoadingOverlay>
  );
};

AgenciesAssignedEdit.propTypes = {
  placementSearchId: BintiPropTypes.ID,
  onCancel: PropTypes.func,
  onCompleted: PropTypes.func,
};

export default AgenciesAssignedEdit;
