import { ArrayDataTable, Flex, Icons, Link, Pill } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

const SearchResults = ({ link }) => (
  <ArrayDataTable
    columns={[
      {
        id: "name",
        columnName: { name: "name", justify: "start" },
        cell: ({ name }) => <Link href={link}>{name}</Link>,
      },
      {
        id: "role",
        columnName: { name: "Role", justify: "start" },
        cell: ({ name, roles }) => (
          <Fragment>
            {roles.map(role => (
              <Pill
                key={[name, role].join("-")}
                text={role}
                variant="neutral"
              />
            ))}
          </Fragment>
        ),
      },
      {
        id: "dob",
        columnName: { name: "Date of Birth", justify: "start" },
        cell: ({ dob, age }) => (
          <Fragment>
            {dob} (Age {age})
          </Fragment>
        ),
      },
      {
        id: "address",
        columnName: { name: "Primary Address", justify: "start" },
        cell: "address",
      },
      {
        id: "notices",
        columnName: { name: "Notices", justify: "start" },
        cell: "notices",
      },
      {
        id: "actions",
        columnName: { name: "Actions", justify: "start" },
        cell: ({ pencilLink, dotDotDotLink }) => (
          <Flex row>
            <Link href={pencilLink}>
              <Icons.Pencil />
            </Link>
            <Link href={dotDotDotLink}>
              <Icons.Ellipsis />
            </Link>
          </Flex>
        ),
      },
    ]}
    data={[
      {
        name: "Liam Lopez",
        roles: ["Child"],
        dob: "01/27/2018",
        age: 6,
        address: "(Placement) 2316 N Norwood Pl, Tulsa, OK 74115",
        notices: "",
      },
      {
        name: "William Lopez",
        roles: ["Agency Worker", "Caregiver"],
        dob: "03/12/1972",
        age: 52,
        address: "(Residence) 8501 W 71st St, Tulsa, OK 74131",
        notices: "",
      },
      {
        name: "Liam Gomez",
        roles: ["Caregiver"],
        dob: "07/21/1986",
        age: 37,
        address: "(Residence) 8611 Woodridge Cir, Tulsa, OK 74131",
        notices: "",
      },
      {
        name: "Isabel Lopez",
        roles: ["Child"],
        dob: "03/14/2010",
        age: 14,
        address: "(Removal) 7501 S 58th W Ave, Tulsa, OK 74131",
        notices: "Child is on Runaway",
      },
      {
        name: "Liamson Diaz",
        roles: ["Child"],
        dob: "09/08/2013",
        age: 10,
        address: "(Placement) 8510 Shari Ln, Tulsa, OK 74131",
        notices: "",
      },
      {
        name: "Liam McCarthy",
        roles: ["Caregiver"],
        dob: "08/09/1975",
        age: 48,
        address: "(Residence) 5801 W Canyon Rd, Tulsa, OK 74131",
        notices: "ID expiring within 90 days",
      },
      {
        name: "William Gomez",
        roles: ["Caregiver"],
        dob: "07/28/1966",
        age: 57,
        address: "(Residence) 7601 S 49th W Ave, Tulsa, OK 74131",
        notices: "",
      },
    ]}
  />
);

SearchResults.propTypes = {
  link: PropTypes.string,
};

export default SearchResults;


