import { useMutation } from "@apollo/client";
import { UploadButton, Alert } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import RequirementStatus from "@components/requirements/RequirementStatus";
import { Td, Tr } from "@components/reusable_ui/Table";
import useUploadRow from "@components/reusable_ui/useUploadRow";

import CreateApplicationUploadedRecord from "@graphql/mutations/CreateApplicationUploadedRecord.graphql";

import { policy, prepareShrineFiles } from "@lib/graphqlHelpers";
import {
  NOT_APPLICABLE,
  TRACKED_OUTSIDE_OF_BINTI,
} from "@lib/overridableHelpers";
import useFeatureFlag from "@lib/useFeatureFlag";

import UploadTypeDocuments from "../application_requirements_shared/UploadTypeDocuments";
import UploadTypeOverride from "../application_requirements_shared/UploadTypeOverride";
import UploadTypeRequirementTitle from "../requirements/UploadTypeRequirementTitle";

const SupportingDocumentsV2Row = ({ fulfillment, application }) => {
  const [createUploadedRecord] = useMutation(CreateApplicationUploadedRecord);
  const [showAlert, setShowAlert] = useState(false);
  const { flag: ffShrineSdv2Uploads042024, loading } = useFeatureFlag(
    "ff_shrine_sdv2_uploads_04_2024"
  );

  const { requirement, title } = fulfillment;

  const uploadFiles = async files => {
    if (files.length === 0) {
      setShowAlert(true);
      return;
    }

    let fileVariables;

    if (ffShrineSdv2Uploads042024) {
      fileVariables = {
        files: [],
        shrineFiles: await prepareShrineFiles(files),
      };
    } else {
      fileVariables = { files };
    }

    await createUploadedRecord({
      variables: {
        applicationId: application.id,
        uploadTypeSlug: requirement.uploadType.slug,
        userAgencyProfileId: requirement.userAgencyProfile?.id,
        ...fileVariables,
      },
    });
  };

  const { getTrProps, getInputProps, aroundLoader, isLoading } =
    useUploadRow(uploadFiles);

  // avoid rendering until the feature flag is loaded.
  // it can cause a race condition if we try uploading when the feature flag is on,
  // but the flag variable is still undefined while loading.
  if (loading) return null;

  return (
    <Fragment>
      <Tr {...getTrProps()}>
        <Td>
          <UploadTypeRequirementTitle {...{ requirement, title }} />
        </Td>
        <Td>
          <RequirementStatus
            isFulfilled={fulfillment.isFulfilled}
            progressPercentage={fulfillment.progressPercentage}
          />
        </Td>
        <Td>
          <UploadTypeDocuments
            disabled={isLoading}
            {...{
              holderToken: application.holderToken,
              fulfillment,
              aroundLoader,
            }}
          />
        </Td>
        <Td>
          <UploadTypeOverride
            createOverrideLabel="Not Applicable"
            deleteOverrideLabel="Unmark N/A"
            reason={NOT_APPLICABLE}
            disabled={isLoading}
            {...{ application, fulfillment, aroundLoader }}
          />
        </Td>
        <Td>
          <UploadTypeOverride
            createOverrideLabel="Received"
            deleteOverrideLabel="Unmark Received"
            reason={TRACKED_OUTSIDE_OF_BINTI}
            disabled={isLoading}
            {...{ application, fulfillment, aroundLoader }}
          />
        </Td>
        <Td>
          {policy(fulfillment).mayCreateUploadedRecord() && (
            <UploadButton
              passedInputProps={getInputProps}
              disabled={isLoading}
            />
          )}
        </Td>
      </Tr>

      <Alert
        hidden={!showAlert}
        isAlert={true}
        title={I18n.t("javascript.components.common.alert")}
        onSubmit={() => setShowAlert(false)}
        submitText={I18n.t("attachments.errors.ok")}
      >
        {I18n.t("attachments.errors.unpermitted_format")}
      </Alert>
    </Fragment>
  );
};

SupportingDocumentsV2Row.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
};

export default SupportingDocumentsV2Row;
