import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  Icons,
  Layout,
  Surface,
  ContentTabs,
  LoadingOverlay,
} from "@heart/components";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { familyFindingClearPersonReportDownloadReportPath } from "routes";

import { translationWithRoot } from "@components/T";

import CreateClearPersonReport from "@graphql/mutations/CreateClearPersonReport.graphql";
import ClearPersonReport from "@graphql/queries/ClearPersonReport.graphql";
import ClearPersonSearchDetail from "@graphql/queries/ClearPersonSearchDetail.graphql";

import BintiPropTypes from "@lib/BintiPropTypes.js";

import LinkPotentialConnectionModal from "./LinkPotentialConnectionModal.js";
import PersonalInformation from "./PersonalInformation.js";
import PotentialKin from "./PotentialKin.js";
import SuccessModalNotice from "./SuccessModalNotice";

const { t } = translationWithRoot(
  "family_finding.potential_kin_search.search_result_details"
);

const SearchResultDetails = ({ searchId, groupId }) => {
  const [modalTypeVisibility, setModalTypeVisibility] = useState();
  const [
    childAgencyHumansForSuccessModal,
    setChildAgencyHumansForSuccessModal,
  ] = useState([]);
  // Search Result Detail query
  // Supplies initial data not gated by Comprehensive Report button
  const { data: searchDetailData = {}, loading } = useQuery(
    ClearPersonSearchDetail,
    {
      variables: { id: searchId, groupId },
    }
  );
  const { clearPersonSearch = { resultDetail: {} } } = searchDetailData;
  const { resultDetail } = clearPersonSearch;

  // Mutation
  // Triggers creating ClearPersonReport (aka Comprehensive Search) record in DB
  const [createClearPersonReport, createClearPersonReportResult] = useMutation(
    CreateClearPersonReport,
    { variables: { searchId, groupId } }
  );

  const clearPersonReportId =
    createClearPersonReportResult?.data?.createClearPersonReport?.report?.id;

  // Comprehensive Report is "pending" if a record has not been created in the DB
  const comprehensiveReportPending = isNil(clearPersonReportId);

  // ClearPersonReport (aka Comprehensive Search) query
  // Querying the record triggers the request to CLEAR to generate the report
  const {
    data = {},
    startPolling,
    stopPolling,
  } = useQuery(ClearPersonReport, {
    // Skip running this query if there is no ID to query for
    skip: comprehensiveReportPending,
    variables: { id: clearPersonReportId },
  });
  const { clearPersonReport = { reportData: {} } } = data;
  const { reportData } = clearPersonReport;
  const comprehensiveReportLoaded = reportData?.status === 200;

  // Effect to manage polling until Comprehensive Report is available
  useEffect(() => {
    // Start polling for report if the report was created, but the status was not 200
    if (!comprehensiveReportPending && reportData?.status !== 200) {
      startPolling(1000);
    }

    // Stop polling when the status becomes 200
    if (comprehensiveReportLoaded) {
      stopPolling();
    }

    // Ensure polling stops when component unmounts
    return () => stopPolling();
  }, [
    comprehensiveReportPending,
    reportData,
    startPolling,
    stopPolling,
    comprehensiveReportLoaded,
  ]);

  return (
    <LoadingOverlay active={loading}>
      <Layout
        pageTitle={t("title", { name: resultDetail.fullName })}
        main={{
          content: (
            <Surface title={t("search_results")} gap="0" hideTitle>
              <ContentTabs
                tabs={[
                  {
                    title: t("personal_information"),
                    contents: (
                      <PersonalInformation
                        searchData={resultDetail}
                        createClearPersonReport={createClearPersonReport}
                        mutationLoading={createClearPersonReportResult?.loading}
                        comprehensiveReportPending={comprehensiveReportPending}
                        comprehensiveReportLoaded={comprehensiveReportLoaded}
                        reportData={reportData}
                      />
                    ),
                  },
                  {
                    title: t("potential_kin"),
                    contents: (
                      <PotentialKin
                        reportData={reportData}
                        createClearPersonReport={createClearPersonReport}
                        mutationLoading={createClearPersonReportResult?.loading}
                        comprehensiveReportPending={comprehensiveReportPending}
                        comprehensiveReportLoaded={comprehensiveReportLoaded}
                      />
                    ),
                  },
                ]}
              />
            </Surface>
          ),
        }}
        secondary={
          <If condition={resultDetail.firstName}>
            <Flex>
              <LinkPotentialConnectionModal
                hidden={modalTypeVisibility !== "linkPotential"}
                setModalTypeVisibility={setModalTypeVisibility}
                onCancel={() => setModalTypeVisibility(undefined)}
                childAgencyHumans={[]}
                setChildAgencyHumansForSuccessModal={
                  setChildAgencyHumansForSuccessModal
                }
                searchData={resultDetail}
                socialMediaLinks={reportData?.socialMediaLinks}
                riskFlags={reportData?.riskFlags}
                comprehensiveReportLoaded={comprehensiveReportLoaded}
                comprehensiveReportId={clearPersonReportId}
              />
              <SuccessModalNotice
                hidden={modalTypeVisibility !== "success"}
                onClose={() => setModalTypeVisibility(undefined)}
                childAgencyHumans={childAgencyHumansForSuccessModal}
              />
              <Button
                icon={Icons.FileDownload}
                disabled={!comprehensiveReportLoaded}
                variant="secondary"
                href={
                  isNil(clearPersonReportId)
                    ? ""
                    : familyFindingClearPersonReportDownloadReportPath(
                        clearPersonReportId
                      )
                }
              >
                {t("download_report", { name: resultDetail.firstName })}
              </Button>
              <Button
                variant="primary"
                onClick={() => setModalTypeVisibility("linkPotential")}
              >
                {t("link_as_connection", { name: resultDetail.firstName })}
              </Button>
            </Flex>
          </If>
        }
      />
    </LoadingOverlay>
  );
};
SearchResultDetails.propTypes = {
  searchId: BintiPropTypes.ID,
  groupId: PropTypes.string,
};

export default SearchResultDetails;
