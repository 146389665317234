import { Button, Flex, Link } from "@heart/components";
import PropTypes from "prop-types";
import {
  collatedDocumentsApplicationPath,
  regenerateCollatedDocumentsAdminApplicationPath,
} from "routes";

const ApplicationPDFButton = ({ application, currentUserBintiAdmin }) => (
  <Flex column>
    <Flex row>
      <Button
        variant="secondary"
        target="_blank"
        rel="noopener noreferrer"
        href={collatedDocumentsApplicationPath(application.id, {
          format: "pdf",
        })}
      >
        {I18n.t(
          "javascript.components.application_requirements.application_pdf_button.pdf_of_all_documents"
        )}
      </Button>
    </Flex>
    <If condition={currentUserBintiAdmin && application.collatedPdf}>
      <p>
        <i>
          {I18n.t(
            "javascript.components.application_requirements.application_pdf_button.generated"
          )}{" "}
          {I18n.l(
            "time.formats.datetime",
            new Date(application.collatedPdf.createdAt)
          )}
        </i>
      </p>
      <Link
        role="button"
        href={regenerateCollatedDocumentsAdminApplicationPath(application.id)}
        data-method="post"
      >
        {I18n.t(
          "javascript.components.application_requirements.application_pdf_button.force_regenerate"
        )}
      </Link>
    </If>
  </Flex>
);

ApplicationPDFButton.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // GraphQL serializes as string, Rails views as int
    collatedPdf: PropTypes.shape({
      createdAt: PropTypes.date,
    }),
  }),
  currentUserBintiAdmin: PropTypes.bool.isRequired,
};

export default ApplicationPDFButton;
