import { Link, Surface } from "@heart/components";
import { identity, isObject } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import styles from "../CcwisDemo.module.scss";
import InfoTable from "../InfoTable";

const CurrentPlacement = ({ placement }) => (
  <div style={{ maxWidth: "98.5%", margin: "0 8px 8px" }}>
    <Surface title="Placement" hideTitle>
      <h2 className={styles.surfaceTitle}>Placement</h2>
      <div style={{ paddingLeft: "8px" }}>
        <InfoTable
          rows={[
            placement.type && {
              label: "Type",
              value: placement.type,
            },
            placement.placementDisplayName && {
              label: "Name",
              value: (
                <Link href={placement.placementLink}>
                  {placement.placementDisplayName}
                </Link>
              ),
            },
            !!placement.privateAgencyAddressComponents.join("") && {
              label: "Address",
              value: (
                <Fragment>
                  {placement.privateAgencyAddressComponents
                    .filter(identity)
                    .map(address => (
                      <p key={address}>{address}</p>
                    ))}
                </Fragment>
              ),
            },
            placement.startDate && {
              label: <Fragment>Start&nbsp;date</Fragment>,
              value: placement.startDate,
            },
          ].filter(isObject)}
        />
      </div>
    </Surface>
  </div>
);

CurrentPlacement.propTypes = {
  placement: PropTypes.object.isRequired,
};

export default CurrentPlacement;
