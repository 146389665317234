import completedIcon from "green_check.png";
import PropTypes from "prop-types";
import errorIcon from "red_warning.png";
import warningIcon from "yellow_warning.png";

import styles from "./SummaryItem.module.scss";

const SummaryItem = ({
  link,
  showWarningIcon,
  showErrorIcon,
  showCompletedIcon,
  count,
  label,
  newTab = false,
}) => (
  <a
    href={link}
    {...(newTab ? { target: "_blank", rel: "noopener noreferrer" } : {})}
  >
    <div className={styles.summaryItem}>
      <div className={styles.count} data-testid="summary-item-count">
        {count == null ? "---" : count}
      </div>

      <div className={styles.label} data-testid="summary-item-label">
        {label}
      </div>

      {showWarningIcon && count > 0 && (
        <img className={styles.icon} src={warningIcon} alt="" />
      )}

      {showErrorIcon && count > 0 && (
        <img className={styles.icon} src={errorIcon} alt="" />
      )}

      {showCompletedIcon && count === 0 && (
        <img className={styles.icon} src={completedIcon} alt="" />
      )}

      {showCompletedIcon && count === 0 && (
        <div className={styles.comment}>Great job!</div>
      )}
    </div>
  </a>
);

SummaryItem.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  count: PropTypes.number,
  showWarningIcon: PropTypes.bool,
  showErrorIcon: PropTypes.bool,
  showCompletedIcon: PropTypes.bool,
  newTab: PropTypes.bool,
};
export default SummaryItem;
