import { ArrayDataTable, Flex, Icons, Link } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import ActionHeader from "../ActionHeader";

const Medications = ({ medicationsFormLink }) => (
  <Fragment>
    <ActionHeader
      title="Medications"
      action="Add New Medication"
      actionLink={medicationsFormLink}
    />
    <ArrayDataTable
      columns={[
        {
          id: "medication",
          columnName: { name: "Medication", justify: "start" },
          cell: "medication",
        },
        {
          id: "dosage",
          columnName: { name: "Dosage", justify: "start" },
          cell: "dosage",
        },
        {
          id: "notes",
          columnName: { name: "Notes", justify: "start" },
          cell: "notes",
        },
        {
          id: "actions",
          columnName: { name: "Actions", justify: "start" },
          cell: ({ pencilLink, dotDotDotLink }) => (
            <Flex row>
              <Link href={pencilLink}>
                <Icons.Pencil />
              </Link>
              <Link href={dotDotDotLink}>
                <Icons.Ellipsis />
              </Link>
            </Flex>
          ),
        },
      ]}
      data={[
        {
          medication: "Zyrtec (cetirizine)",
          dosage: "5mL",
          notes:
            "Seasonal Allergy - 5ml before bed. NO more than 10mL in 24 hours.",
          pencilLink: "#",
          dotDotDotLink: "#",
        },
      ]}
    />
  </Fragment>
);

Medications.propTypes = {
  medicationsFormLink: PropTypes.string.isRequired,
};

export default Medications;
