import {
  ArrayDataTable,
  Flex,
  Icons,
  Link,
  Pill,
  Surface,
} from "@heart/components";

import InfoTable from "@components/ccwis_demo/child_file/InfoTable";

import ActionHeader from "../ActionHeader";
import styles from "../CcwisDemo.module.scss";

const PermanencyGoal = () => (
  <div style={{ maxWidth: "98.5%", margin: "0 8px 8px" }}>
    <Surface title="Permanency Goal" hideTitle>
      <ActionHeader title="Permanency Goal" action="Edit Permanency Goal" />
      <InfoTable
        rows={[
          {
            label: "Primary Goal",
            value: <Pill text="Reunification" variant="success" />,
          },
          {
            label: "Secondary Goal",
            value: <Pill text="Permanency with Kin" variant="info" />,
          },
        ]}
      />
      <h3 className={styles.tableTitle}>Historic Permanency Goals</h3>
      <ArrayDataTable
        columns={[
          {
            id: "goal",
            columnName: { name: "Goal", justify: "start" },
            cell: "goal",
          },
          {
            id: "information",
            columnName: { name: "Information", justify: "start" },
            cell: "information",
          },
          {
            id: "actions",
            columnName: { name: "Actions", justify: "start" },
            cell: ({ pencilLink, dotDotDotLink }) => (
              <Flex row>
                <Link href={pencilLink}>
                  <Icons.Pencil />
                </Link>
                <Link href={dotDotDotLink}>
                  <Icons.Ellipsis />
                </Link>
              </Flex>
            ),
          },
        ]}
        data={[]}
      />
    </Surface>
  </div>
);

export default PermanencyGoal;
