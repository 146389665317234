import { Button, Flex } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./CcwisDemo.module.scss";

const ActionHeader = ({ title, action, actionLink = "#" }) => (
  <Flex row>
    <h2 className={styles.surfaceTitle}>{title}</h2>
    <Button
      style={{ marginLeft: "auto" }}
      href={actionLink}
      variant="secondary"
    >
      {action}
    </Button>
  </Flex>
);

ActionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  actionLink: PropTypes.string,
};

export default ActionHeader;
