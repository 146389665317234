import { Flex, LiveRegion } from "@heart/components";
import { useKeyboardEvent, useMountEffect } from "@react-hookz/web";
import { isEmpty, keyBy } from "lodash";
import PropTypes from "prop-types";
import React, {
  createRef,
  Fragment,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";

import useStateList from "@lib/react-use/useStateList";
import {
  getSearchParams,
  getSearchParamForAttribute,
  setSearchParams,
} from "@lib/searchParams";

import styles from "./ContentTabs.module.scss";
import TabPanelButton from "./TabPanelButton";
import TabPanelContents from "./TabPanelContents";

/**
 * Used to display tabbed contents, and optionally indicate a count
 * for each tab. This count will generally correlate to the number of
 * items displayed in a table on a given tab
 *
 * The tabs also utilize the `tab` query parameter to enable navigation
 * directly to a particular tab, and enable page refreshes without losing
 * the tab that was previously active
 *
 * **Note:** Only **one** instance of `ContentTabs` should appear on a given
 * page as the keyboard navigation will not function independently for
 * each instance. The keyboard nav in Storybook is also a bit as a result of
 * this limitation. Use the "Canvas" view rather than the docs view when
 * testing keyboard navigation
 *
 * The a11y spec for this component can be found [here](https://www.w3.org/WAI/ARIA/apg/example-index/tabs/tabs-manual.html)
 */

const ContentTabs = ({
  showTitlesInContents = false,
  tabs,
  onActiveTabChange,
  "data-testid": testId,
  loading,
}) => {
  const searchParams = useMemo(getSearchParams, []);
  const setTabSearchParam = useCallback(
    tabTitle => setSearchParams({ attribute: "tab", value: tabTitle }),
    []
  );

  /**
   * tabsById gives us a consistent `id` to use when referencing a particular
   * tab. This is helpful for things like a11y aria tags, and the keys which help
   * React keep track of components generated out of a `map`.
   */
  const tabsByTitle = useMemo(
    () =>
      keyBy(
        tabs.map((tab, index) => ({
          id: `navigation-tab-${index}`,
          ref: createRef(null),
          tabPanelTitleRef: createRef(null),
          ...tab,
        })),
        "title"
      ),
    [tabs]
  );

  const [activeTab, setActiveTab] = useState(tabs[0].title);

  useEffect(() => {
    const activeTabFromUrl = () => {
      const param = decodeURI(getSearchParamForAttribute({ attribute: "tab" }));
      if (Object.keys(tabsByTitle).includes(param)) return param;
      setTabSearchParam(undefined);
      return undefined;
    };
    setActiveTab(activeTabFromUrl() || tabs[0].title);
  }, [tabs, searchParams, setTabSearchParam, tabsByTitle]);

  /** In order to avoid setting focus on the initial render, we need to track
   * the first time a relevant key is pressed, and only then do we take
   * `state` into account in `useEffect`
   */
  const [keyPressed, setKeyPressed] = useState(false);
  /** state used to track which tab is focused */
  const {
    state: focusedTabTitle,
    prev,
    next,
    setState,
    setStateAt,
  } = useStateList(Object.keys(tabsByTitle));

  const focusTab = stateSetter => {
    stateSetter();
    setKeyPressed(true);
  };

  useKeyboardEvent("ArrowLeft", () => focusTab(prev));
  useKeyboardEvent("ArrowRight", () => focusTab(next));
  useKeyboardEvent("Home", () => focusTab(() => setStateAt(0)));
  useKeyboardEvent("End", () => focusTab(() => setStateAt(tabs.length - 1)));
  useKeyboardEvent("ArrowDown", () =>
    tabsByTitle[focusedTabTitle].tabPanelTitleRef.current.focus()
  );

  /** Initialize our focus state to the active tab in query params or the first tab */
  useMountEffect(() => {
    setState(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (
      Object.values(tabsByTitle)
        .map(({ ref }) => ref?.current)
        .includes(document.activeElement)
    ) {
      /* Rotate focus to next tab when focused on a tab */
      const refToFocus = tabsByTitle[focusedTabTitle].ref.current;
      if (keyPressed && !isEmpty(refToFocus)) refToFocus.focus();
    } else {
      /* Otherwise reset place in state list to active tab */
      setStateAt(Object.keys(tabsByTitle).indexOf(activeTab));
    }
  }, [keyPressed, focusedTabTitle, tabsByTitle, setStateAt, activeTab]);

  const changeActiveTab = tabTitle => {
    if (onActiveTabChange) {
      onActiveTabChange(tabTitle);
    }
    setActiveTab(tabTitle);
    setTabSearchParam(tabTitle);
  };

  return (
    <Fragment>
      <nav
        aria-label="page-content-tabs"
        data-testid={testId}
        aria-busy={loading}
      >
        <Flex as="ul" gap="0" role="tablist" className={styles.tabs}>
          {Object.values(tabsByTitle).map(({ title, count, ref, id }) => {
            const isActiveTab = activeTab === title;
            return (
              <li role="presentation" key={id}>
                <TabPanelButton
                  id={id}
                  active={isActiveTab}
                  count={count}
                  loading={loading}
                  onClick={() => changeActiveTab(title)}
                  tabRef={ref}
                  title={title}
                />
              </li>
            );
          })}
        </Flex>
      </nav>
      <LiveRegion>
        {Object.values(tabsByTitle).map(
          ({ title, contents, tabPanelTitleRef, id }) => {
            const isActiveTab = activeTab === title;
            return (
              <TabPanelContents
                id={id}
                key={id}
                active={isActiveTab}
                contents={contents}
                showTitlesInContents={showTitlesInContents}
                title={title}
                tabPanelTitleRef={tabPanelTitleRef}
              />
            );
          }
        )}
      </LiveRegion>
    </Fragment>
  );
};

ContentTabs.propTypes = {
  /** Adds a loading spinner to the ContentTab buttons if content is loading */
  loading: PropTypes.bool,
  /** Whether to show the tab title at the top of the contents of the tab */
  showTitlesInContents: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      contents: PropTypes.node.isRequired,
      count: PropTypes.number,
    })
  ).isRequired,
  /** Function that is called whenever the active tab is changed */
  onActiveTabChange: PropTypes.func,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default ContentTabs;
