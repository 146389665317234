import {
  Breadcrumbs,
  ContentTabs,
  Flex,
  PageContainer,
} from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";
import {
  downloadFamilyFindingContactLogsCsvPath,
  downloadRelationshipsCsvPath,
  newPersonAsChildConnectionPath,
} from "routes";

import ChildRequirementTableWrapper from "@components/child_requirements/ChildRequirementsTable";
import RelationshipsDashboard from "@components/family_finding/RelationshipsDashboard";

import AISurface from "./AISurface";
import aiAlert from "./ai-alert.svg";
import CaseNotes from "./case_notes/CaseNotes";
import ChildInfo from "./child_info/ChildInfo";
import CaseManagement from "./cm/CaseManagement";
import FamilyTree from "./family-tree.svg";
import Placements from "./placements/Placements";
import CurrentPlacement from "./sidebar/CurrentPlacement";
import ChildCard from "./sidebar/child-card.svg";
import ImportantDates from "./sidebar/important-dates.svg";

const Sidebar = ({ placement }) => (
  <Flex column style={{ width: "425px" }}>
    <img src={ChildCard} alt="Child card" />
    <If condition={placement?.current}>
      <CurrentPlacement placement={placement} />
    </If>
    <img src={ImportantDates} alt="Important dates" />
  </Flex>
);
Sidebar.propTypes = {
  placement: PropTypes.object,
};
const ChildFile = ({
  caseNotes,
  caseNoteSummary,
  childId,
  childAgencyHumanId,
  missingNames,
  placementPeriods,
  services,
  medicationsFormLink,
  courtEditLink,
}) => (
  <Flex column style={{ margin: "20px" }}>
    <Breadcrumbs
      pages={[
        { label: "Home", href: "/homepage" },
        { label: "Children/Youth", href: "/admin/children" },
        { label: "Liam Lopez", href: "" },
      ]}
    />
    <Flex row>
      <Sidebar placement={placementPeriods[0]} />
      <Flex column>
        <ContentTabs
          tabs={[
            {
              title: "Child Info",
              contents: <ChildInfo medicationsFormLink={medicationsFormLink} />,
            },
            {
              title: "Case Management",
              contents: (
                <CaseManagement
                  services={services}
                  childId={childId}
                  courtEditLink={courtEditLink}
                />
              ),
            },
            {
              title: "Requirements",
              contents: (
                <ChildRequirementTableWrapper
                  childId={childId}
                  collatedAttachmentsPdfPath=""
                />
              ),
            },
            {
              title: "Placements",
              contents: (
                <Placements
                  placementPeriods={placementPeriods}
                  childId={childId}
                />
              ),
            },
            {
              title: "Relationships",
              contents: (
                <Fragment>
                  <If condition={!isEmpty(missingNames)}>
                    <AISurface>
                      {missingNames.map(name => (
                        <Flex key={name} row>
                          <img
                            src={aiAlert}
                            alt="AI Alert"
                            aria-hidden="true"
                          />{" "}
                          <b>Unlisted name found in case notes:</b> {name}
                        </Flex>
                      ))}
                    </AISurface>
                  </If>
                  <RelationshipsDashboard
                    childAgencyHumanId={childAgencyHumanId}
                    addPersonFormPath={newPersonAsChildConnectionPath(childId)}
                    downloadRelationshipsCsvPath={downloadRelationshipsCsvPath(
                      childAgencyHumanId
                    )}
                    editContactLogBasePath="/family_finding_contact_logs"
                    downloadContactLogsCsvPath={downloadFamilyFindingContactLogsCsvPath()}
                  />
                  <PageContainer fullWidth>
                    <img
                      src={FamilyTree}
                      alt="Family Tree"
                      width="100%"
                      style={{
                        boxShadow:
                          "0px 6px 10px -1px rgba(6, 25, 56, 0.0705882353)",
                      }}
                    />
                  </PageContainer>
                </Fragment>
              ),
            },
            {
              title: "Case Notes",
              contents: (
                <CaseNotes
                  caseNotes={caseNotes}
                  childId={childId}
                  summary={caseNoteSummary}
                />
              ),
            },
          ]}
        ></ContentTabs>
      </Flex>
    </Flex>
  </Flex>
);

ChildFile.propTypes = {
  caseNotes: PropTypes.arrayOf(PropTypes.object).isRequired,
  childId: PropTypes.number.isRequired,
  childAgencyHumanId: PropTypes.string.isRequired,
  placementPeriods: PropTypes.arrayOf(PropTypes.object).isRequired,
  caseNoteSummary: PropTypes.string,
  missingNames: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  medicationsFormLink: PropTypes.string.isRequired,
  courtEditLink: PropTypes.string.isRequired,
};

export default ChildFile;
