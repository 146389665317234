import { useQuery } from "@apollo/client";
import { LoadingOverlay } from "@heart/components";
import { Table, Td, Th, Thead, Tr } from "@heart/components/table/Table";

import { translationWithRoot } from "@components/T";

import PlacementSearchDetailsQuery from "@graphql/queries/PlacementSearchDetails.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import { reasonClosedNeedsExplanationTitles } from "./PlacementSearchDetailsForm";

const { t } = translationWithRoot("placement_search");

const PlacementSearchDetailsView = ({ id }) => {
  const { data, loading: dataLoading } = useQuery(PlacementSearchDetailsQuery, {
    variables: { id },
  });

  const placementSearch = data?.placementSearch || {};

  return (
    <LoadingOverlay active={dataLoading}>
      <Table>
        <Thead>
          <Tr>
            <Th>Label</Th>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tr>
          <Td>{t("date_placement_requested")}</Td>
          <Td>{placementSearch.requestedAt}</Td>
        </Tr>
        <Tr>
          <Td>{t("reason_requested.title")}</Td>
          <Td>
            {placementSearch.reasonRequested
              ? t(`reason_requested.${placementSearch.reasonRequested}`)
              : null}
          </Td>
        </Tr>
        <Tr>
          <Td>{t("reason_requested_explanation")}</Td>
          <Td>{placementSearch.reasonRequestedExplanation}</Td>
        </Tr>
        <Tr>
          <Td>{t("date_placement_due")}</Td>
          <Td>{placementSearch.dueAt}</Td>
        </Tr>
        <Tr>
          <Td>{t("search_completed_date")}</Td>
          <Td>{placementSearch.closedAt}</Td>
        </Tr>
        <Tr>
          <Td>{t("reason_search_closed")} </Td>
          <Td>
            {placementSearch.reasonClosed
              ? t(`reason_closed.${placementSearch.reasonClosed}`)
              : null}
          </Td>
        </Tr>
        <Tr>
          <If
            condition={
              reasonClosedNeedsExplanationTitles[placementSearch.reasonClosed]
            }
          >
            <Td>
              {reasonClosedNeedsExplanationTitles[placementSearch.reasonClosed]}
            </Td>
            <Td>{placementSearch.reasonClosedOtherExplanation}</Td>
          </If>
        </Tr>
        <Tr>
          <If condition={placementSearch?.isAssignedFromOtherAgency}>
            <Td>{t("response")}</Td>
            <Td>
              {placementSearch.response
                ? t(`responses.${placementSearch.response}`)
                : null}
            </Td>
          </If>
        </Tr>
      </Table>
    </LoadingOverlay>
  );
};

PlacementSearchDetailsView.propTypes = {
  id: BintiPropTypes.ID,
  childId: BintiPropTypes.ID,
};

export default PlacementSearchDetailsView;
