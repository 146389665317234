import { Flex, Icons } from "@heart/components";
import classnames from "classnames";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import avatar from "stage_icons/icon-person-active.svg";

import { Table, Tbody, Tr, Th, Td } from "@components/reusable_ui/Table";

import { LUXON_DATEPICKER_FORMAT } from "@lib/dates";
import { formatPhoneNumberIntl } from "@lib/phoneNumbers";

import styles from "./PersonCard.module.scss";
import constructName from "./constructName";
import isPlaceholderEmail from "./isPlaceholderEmail";

const WIDTH = 50;
const HEIGHT = 50;

const PersonCard = ({
  actions,
  iconActions,
  icons,
  person,
  showDateOfBirth,
  showSubrole = true,
  genericMessage,
}) => (
  <div
    className={classnames("space-below-2", styles.personPanel)}
    data-testid="listed-person"
  >
    <If condition={person.masqueradeLink}>
      <a href={person.masqueradeLink}>{I18n.t("views.common.masquerade_as")}</a>
    </If>
    <Table aria-label={`Information for ${constructName(person)}`}>
      <caption>
        <Flex justify="space-between">
          <div>
            <img
              width={WIDTH}
              height={HEIGHT}
              src={avatar}
              alt="person avatar"
              data-testid="person-active-icon"
            />
            <br />
            {constructName(person)}
          </div>
          <Flex column>
            <If condition={!isEmpty(icons)}>
              <div className={styles.icons}>{icons}</div>
            </If>
            <If condition={!isEmpty(iconActions)}>
              <Flex justify="end" className={styles.iconActions}>
                {iconActions.map(
                  (
                    { ariaLabel, onClick, icon, "data-testid": testId },
                    index
                  ) => {
                    const Icon = Icons[icon];
                    return (
                      <Icon
                        key={index}
                        description={ariaLabel}
                        onClick={onClick}
                        data-testid={testId}
                      />
                    );
                  }
                )}
              </Flex>
            </If>
          </Flex>
        </Flex>
      </caption>

      <Tbody>
        <If condition={!isPlaceholderEmail({ email: person.email })}>
          <Tr>
            <Th>{I18n.t("views.common.email_address")}</Th>
            <Td>{person.email}</Td>
          </Tr>
        </If>
        <If condition={person.phoneNumber?.length}>
          <Tr>
            <Th>{I18n.t("views.common.phone_number")}</Th>
            <Td>{formatPhoneNumberIntl(person.phoneNumber)}</Td>
          </Tr>
        </If>
        <If condition={showSubrole && person.subrole}>
          <Tr>
            <Th>{I18n.t("views.reference_requests.form.role_in_household")}</Th>
            <Td>
              {I18n.t(
                `activerecord.enums.application_other_adult.adult_types.${person.subrole}`
              )}
            </Td>
          </Tr>
        </If>
        <If condition={showDateOfBirth && person.dateOfBirth}>
          <Tr>
            <Th>{I18n.t("views.common.date_of_birth")}</Th>
            <Td>
              {DateTime.fromISO(person.dateOfBirth).toFormat(
                LUXON_DATEPICKER_FORMAT
              )}
            </Td>
          </Tr>
        </If>

        <If condition={person.joinedAt}>
          <Tr>
            <Th>{I18n.t("views.reference_requests.form.joined_date")}</Th>
            <Td>
              {DateTime.fromISO(person.joinedAt).toFormat(
                LUXON_DATEPICKER_FORMAT
              )}
            </Td>
          </Tr>
        </If>

        <If condition={person.leftAt}>
          <Tr>
            <Th>{I18n.t("views.reference_requests.form.left_date")}</Th>
            <Td>
              {DateTime.fromISO(person.leftAt).toFormat(
                LUXON_DATEPICKER_FORMAT
              )}
            </Td>
          </Tr>
        </If>

        <If condition={genericMessage}>
          <Tr>
            <Td colSpan="100%">{genericMessage}</Td>
          </Tr>
        </If>

        <If condition={actions}>
          <Tr>
            <Td colSpan="100%">{actions}</Td>
          </Tr>
        </If>
      </Tbody>
    </Table>
  </div>
);

PersonCard.defaultProps = {
  showDateOfBirth: true,
};

PersonCard.propTypes = {
  actions: PropTypes.any,
  iconActions: PropTypes.arrayOf(
    PropTypes.shape({
      ariaLabel: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.string.isRequired,
      "data-testid": PropTypes.string,
    })
  ),
  icons: PropTypes.array,
  person: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    subrole: PropTypes.string,
    masqueradeLink: PropTypes.string,
    joinedAt: PropTypes.string,
    leftAt: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    dateOfBirth: PropTypes.string,
  }).isRequired,
  /* Boolean flag as to whether to we want to show/capture date of birth,
   this varies depending on context of who is viewing the component.
   Defaults to true (see above)   */
  showDateOfBirth: PropTypes.bool,
  /* Boolean flag whether to show the subrole, in case subroles were turned
  on and then off again */
  showSubrole: PropTypes.bool,
  genericMessage: PropTypes.string,
};

export default PersonCard;
