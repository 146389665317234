import { Flex } from "@heart/components";
import { omit } from "lodash";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";

import {
  useInputProps,
  useInputId,
  useInputDescription,
  useInputError,
  useInputContainerClassName,
  inputCommonPropTypes,
} from "./common";
import styles from "./common/RadioAndCheckboxCommonStyles.module.scss";

/**
 * A singular checkbox with associated label.
 * **Note**: Using the `required` prop will *not* prevent form submissions.
 *
 * Relevant Cypress commands:
 *   * `cy.checkBox(label)` for singular checkboxes
 *   * `cy.findCheckbox(label)` for singular checkboxes
 *   * `cy.findRadioOrCheckboxInput(groupName, label).check()` for checkboxes in groups
 */
const InputCheckbox = forwardRef((props, ref) => {
  const { onChange, htmlValue: value, value: checked } = props;

  const [isChecked, setIsChecked] = useState(checked || false);
  useEffect(() => setIsChecked(checked || false), [checked]);
  const handleChange = e => {
    const checkedState = e.target.checked;
    setIsChecked(checkedState);
    if (onChange) onChange(checkedState);
  };

  const id = useInputId(props);
  const description = useInputDescription(props);
  const error = useInputError(props);
  const inputProps = useInputProps({ ...props, id });
  const containerClass = useInputContainerClassName(props);

  return (
    <Flex column className={containerClass}>
      <label className={styles.radioCheckContainer}>
        <input
          {...inputProps}
          type="checkbox"
          onChange={handleChange}
          checked={isChecked}
          value={value}
          ref={ref}
        />
        {props.label}
      </label>
      {description}
      {error}
    </Flex>
  );
});

InputCheckbox.displayName = "InputCheckbox";
InputCheckbox.propTypes = {
  ...omit(inputCommonPropTypes, "hideLabel"),
  /** Whether or not this checkbox is checked. */
  value: PropTypes.bool,
  /** The HTML "value" for this checkbox, which will be sent over-the-wire as the
   * `value` for this name in a POST payload **only if the checkbox is checked**.
   *
   * If we run into a scenario where we desire this value over-the-wire
   * (or a different value) when the checkbox is unchecked, we can add a
   * hidden input to this component when `value` is `false`. */
  htmlValue: PropTypes.string,
  /** `onChange` is invoked with a `boolean` of the new checked state. */
  onChange: PropTypes.func,
};
export default InputCheckbox;
