import PropTypes from "prop-types";
import { Component } from "react";

import Block from "./Block";
import Question from "./Question";

class ConditionalBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  handlePreconditionChange(newVal) {
    this.setState({
      value: newVal,
    });
  }

  static showConditional(expandOnVals, value) {
    if (!expandOnVals) {
      return false;
    }
    let arrayedExpandOnVals = expandOnVals;

    if (!Array.isArray(arrayedExpandOnVals)) {
      arrayedExpandOnVals = [arrayedExpandOnVals];
    }

    if (Array.isArray(value)) {
      // check to see if there is an overlap for checkbox conditionals
      return (
        arrayedExpandOnVals.filter(val => value.indexOf(val) !== -1).length > 0
      );
    }

    return arrayedExpandOnVals.indexOf(value) >= 0;
  }

  render() {
    const { value } = this.state;

    const showConditional = ConditionalBlock.showConditional(
      this.props.expand_on,
      value
    );
    const showConditional2 = ConditionalBlock.showConditional(
      this.props.expand_on_2,
      value
    );

    return (
      <div>
        <div className="precondition">
          <Question
            type={this.props.answer_type || "radio"}
            value={value}
            id={this.props.id}
            values={this.props.values}
            title={this.props.title}
            name={this.props.name}
            description={this.props.description}
            onChange={this.handlePreconditionChange.bind(this)}
            required={this.props.required}
            disabled={this.props.disabled}
            error={this.props.error}
            renderErrors={this.props.renderErrors}
          />
        </div>
        {showConditional && (
          <Block
            questions={this.props.items}
            disabled={this.props.disabled}
            renderErrors={this.props.renderErrors}
          />
        )}
        {showConditional2 && (
          <Block
            questions={this.props.items_2}
            disabled={this.props.disabled}
            renderErrors={this.props.renderErrors}
          />
        )}
      </div>
    );
  }
}

ConditionalBlock.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  values: PropTypes.array,
  title: PropTypes.node,
  expand_on: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  expand_on_2: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  items: PropTypes.array,
  items_2: PropTypes.array,
  answer_type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.node,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  renderErrors: PropTypes.bool,
};

ConditionalBlock.defaultProps = {
  expand_on: [],
  expand_on_2: [],
};
export default ConditionalBlock;
