import { useMutation, useQuery } from "@apollo/client";
import {
  SurfaceForm,
  InputDate,
  InputDropdown,
  Actions,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import Spinner from "@components/Spinner";

import UpdateAppeal from "@graphql/mutations/UpdateAppeal.graphql";
import AppealQuery from "@graphql/queries/Appeal.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const EditAppealDetails = ({
  appealId,
  representatives,
  appealLocations,
  onSubmit,
  onCancel,
}) => {
  const { data: appealData, loading: appealLoading } = useQuery(AppealQuery, {
    variables: { appealId },
  });
  const [updateAppeal] = useMutation(UpdateAppeal, {
    refetchQueries: [{ query: AppealQuery, variables: { appealId } }],
    onCompleted: onSubmit,
  });

  const { formState, setFormAttribute } = useBintiForm(appealData?.appeal);

  if (appealLoading) {
    return <Spinner />;
  }

  return (
    <SurfaceForm
      title={I18n.t("javascript.components.appeals.appeal_details")}
      actions={<Actions cancelAction={onCancel} />}
      onSubmit={preventDefault(() => {
        updateAppeal({
          variables: { appealId, ..._.omit(formState, "__typename") },
        });
      })}
    >
      {appealLocations.length > 1 && (
        <InputDropdown
          label={I18n.t(
            "javascript.components.change_application_status.appeals.appeal_location"
          )}
          onChange={setFormAttribute("appealLocation")}
          value={formState.appealLocation}
          values={appealLocations}
        />
      )}
      <InputDropdown
        label={I18n.t("javascript.components.appeals.agency_representative")}
        name="representative"
        values={representatives.map(([label, value]) => [label, value])}
        value={formState.representative}
        onChange={setFormAttribute("representative")}
        required={false}
      />
      <InputDate
        label={I18n.t("javascript.components.appeals.date_appeal_started")}
        name="appealStartedDate"
        value={formState.appealStartedDate}
        onChange={setFormAttribute("appealStartedDate")}
        required={false}
      />
      <InputDate
        label={I18n.t(
          "javascript.components.appeals.date_approving_agency_notified"
        )}
        name="approvingAgencyNotifiedDate"
        value={formState.approvingAgencyNotifiedDate}
        onChange={setFormAttribute("approvingAgencyNotifiedDate")}
        required={false}
      />
      <InputDate
        label={I18n.t(
          "javascript.components.appeals.date_appeals_body_notified"
        )}
        name="appealsBodyNotifiedDate"
        value={formState.appealsBodyNotifiedDate}
        onChange={setFormAttribute("appealsBodyNotifiedDate")}
        required={false}
      />
      <InputDate
        label={I18n.t(
          "javascript.components.appeals.date_docs_sent_to_appeals_body"
        )}
        name="docsSentToAppealsBodyDate"
        value={formState.docsSentToAppealsBodyDate}
        onChange={setFormAttribute("docsSentToAppealsBodyDate")}
        required={false}
      />
    </SurfaceForm>
  );
};

EditAppealDetails.propTypes = {
  appealId: BintiPropTypes.ID,
  applicationId: BintiPropTypes.ID,
  representatives: PropTypes.array,
  appealLocations: PropTypes.array,
  applicationType: PropTypes.string,
  showDeleteIcon: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditAppealDetails;
