import { useMutation } from "@apollo/client";
import React from "react";

import CreateChildAgencyPlacementReferralMutation from "@graphql/mutations/CreateChildAgencyPlacementReferral.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import ChildAgencyPlacementReferralForm from "./ChildAgencyPlacementReferralForm";
import ChildAgencyPlacementReferralPage from "./ChildAgencyPlacementReferralPage";

const CreateChildAgencyPlacementReferral = ({ childId }) => {
  const [createChildReferral, { loading, error }] = useMutation(
    CreateChildAgencyPlacementReferralMutation,
    {
      onCompleted: () => {
        window.location.href = "/admin/children";
      },
    }
  );

  return (
    <ChildAgencyPlacementReferralPage
      childId={childId}
      loading={loading}
      error={error}
    >
      <ChildAgencyPlacementReferralForm
        childId={childId}
        onSubmit={formState =>
          createChildReferral({
            variables: {
              input: {
                ...formState,
                childId,
              },
            },
          })
        }
      />
    </ChildAgencyPlacementReferralPage>
  );
};

CreateChildAgencyPlacementReferral.propTypes = {
  childId: BintiPropTypes.ID,
};

export default CreateChildAgencyPlacementReferral;
