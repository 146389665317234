import { escape } from "lodash";

// TODO: ENG-12579 Add new dynamic UI components to Placement Period page
$(() => {
  const $trigger = $(".js-placement-period-open-trigger");
  const $toShowPlacement = $(".js-placement-period-placement");
  const $underlyingPlacementSelect = $toShowPlacement.find("select");
  const $privateAgencyInfo = $(".js-placement-period-private-agency-info");
  const $toShowOtherExplanation = $(".js-placement-period-description");
  const $toShowIsPreAdoptionPlacement = $(".js-is-pre-adoption-placement");
  const agencyPlacementOptions = {};

  const addSelectOptions = ($select, opts) => {
    const currentVal = $select.val();
    let str = "<option></option>";
    let i = 0;
    if (!$select[0]) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    $select[0].innerHTML = "";
    for (i; i < opts.length; i += 1) {
      str += `<option value='${opts[i].value}'>${escape(
        opts[i].label
      )}</option>`;
    }
    $select.append(str);
    $select.val(currentVal);
  };

  const toggleAgencyPlacement = () => {
    $.ajax({
      dataType: "json",
      url: "/admin/placements/placement_by_reason_closed",
      data: {
        reason_closed: $trigger.val(),
        agency_id: $toShowPlacement.data("agency"),
      },
      success: response => {
        let i = 0;
        for (i; i < response.results.length; i += 1) {
          agencyPlacementOptions[response.results[i].value] =
            response.results[i];
        }

        if (response.results.length > 0) {
          const currentPlacementId =
            $toShowPlacement.data("currentPlacementId");
          const currentPlacementName = $toShowPlacement.data(
            "currentPlacementName"
          );
          const currentPlacementClosed = $toShowPlacement.data(
            "currentPlacementClosed"
          );
          if (
            currentPlacementClosed &&
            response.results.filter(item => item.id === currentPlacementId)
              .length === 0
          ) {
            response.results.push({
              label: currentPlacementName,
              value: currentPlacementId,
            });
            $toShowPlacement.find("select").prop("disabled", true);
          }
          $toShowPlacement.show();
          addSelectOptions($underlyingPlacementSelect, response.results);
        } else {
          $toShowPlacement.hide();
          addSelectOptions($underlyingPlacementSelect, []);
        }
      },
    });

    togglePrivateAgencyFamilyInfo();
    toggleOtherExplanation();
    toggleIsPreAdoptionPlacement();
  };

  const handlePlacementChange = () => {
    const val = $underlyingPlacementSelect.val();
    const message = ["Warning:"];
    if (!agencyPlacementOptions[val]?.approved) {
      message.push("This family has not been approved yet.");
    }
    if (agencyPlacementOptions[val]?.availability === 0) {
      message.push("This family has no availability.");
    }
    if (agencyPlacementOptions[val]?.hold_status) {
      message.push("This family is currently on hold.");
    }
    if (message.length > 1) {
      $(".js-warning-notice").text(message.join(" "));
    } else {
      $(".js-warning-notice").text("");
    }
  };

  const togglePrivateAgencyFamilyInfo = () => {
    if ($trigger.val() === "placed_with_ooc_family") {
      $privateAgencyInfo.show();
    } else if ($trigger.val() === "provider_not_in_binti") {
      $privateAgencyInfo.show();
    } else if (
      $trigger.val() === "placed_with_private_agency" &&
      $toShowPlacement.find("select").val() !== undefined &&
      $toShowPlacement.find("select").val() !== ""
    ) {
      $privateAgencyInfo.show();
    } else {
      $privateAgencyInfo.hide();
    }
  };

  const editCloseOtherExplanationTitle = title => {
    const element = $toShowOtherExplanation.find("label");
    const label = element.text().replace(/[\w|\s]+/, title); // to preserve the '*' if the field is required
    element.text(label);
  };

  const toggleOtherExplanation = () => {
    const toggleOnOptions = {
      closed_other: "Other Closure Reason Explanation",
      hospitalization: "Hospital Details",
      juvenile_detention_center: "Juvenile Detention Center Details",
      silp: "Independent Living Details",
      hotel: "Hotel or Motel Details",
      receiving_center: "Receiving Center Details",
      office: "Office Details",
      shelter: "Shelter Details",
      other: "Other Details",
    };
    if (toggleOnOptions[$trigger.val()]) {
      editCloseOtherExplanationTitle(toggleOnOptions[$trigger.val()]);
      $toShowOtherExplanation.show();
    } else {
      $toShowOtherExplanation.hide();
    }
  };
  const toggleIsPreAdoptionPlacement = () => {
    const toggleOnOptions = {
      office: "Office",
      other: "Other",
      hospitalization: "Hospitalization",
      silp: "Independent Living",
      hotel: "Hotel",
      juvenile_detention_center: "Juvenile Detention Center",
      receiving_center: "Receiving Center",
      shelter: "Shelter",
    };
    if (toggleOnOptions[$trigger.val()]) {
      $toShowIsPreAdoptionPlacement.hide();
    } else {
      $toShowIsPreAdoptionPlacement.show();
    }
  };
  if ($trigger.length > 0) {
    // trigger placement_type or placement_ended_reason on placement period
    // TODO: turn all of the logic on this page into react
    // https://binti.atlassian.net/browse/ENG-6337
    toggleAgencyPlacement();
  }
  $trigger.on("change", toggleAgencyPlacement);
  $underlyingPlacementSelect.on("change", handlePlacementChange);
  $toShowPlacement.find("select").on("change", togglePrivateAgencyFamilyInfo);
});
