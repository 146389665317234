import PropTypes from "prop-types";

import styles from "./CcwisDemo.module.scss";

const InfoTable = ({ rows }) => (
  <table className={styles.infoTable}>
    <tbody>
      {rows.map(row => (
        <tr key={row.id ?? row.label}>
          <th scope="row">{row.label}</th>
          <td>{row.value}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

InfoTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default InfoTable;
