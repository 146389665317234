import { useQuery } from "@apollo/client";
import { Flex, Link } from "@heart/components";
import { flatten, isArray } from "lodash";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";

import { translationWithRoot } from "@components/T";

import preventDefault from "@lib/preventDefault";

import styles from "./InputFilterable.module.scss";
import { BasicInputLayout, inputCommonPropTypes } from "./common";

const { T } = translationWithRoot("heart.components.inputs.input_filterable");

const { t } = translationWithRoot("common");

// Gives us a way to find the root select container in tests using
// cy.findTestId("react-select-container")
const SelectContainer = ({ children, innerProps = {}, ...props }) => (
  <components.SelectContainer
    innerProps={Object.assign({}, innerProps, {
      "data-testid": "react-select-container",
    })}
    {...props}
  >
    {children}
  </components.SelectContainer>
);

SelectContainer.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

// Gives us a way to find the clear button in tests, e.g.
// cy.findTestId("react-select-clear")
const ClearIndicator = ({ children, innerProps = {}, ...props }) => (
  <components.ClearIndicator
    innerProps={Object.assign({}, innerProps, {
      "data-testid": "react-select-clear",
    })}
    {...props}
  >
    {children}
  </components.ClearIndicator>
);

ClearIndicator.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

const customStyles = {
  control: provided => ({
    ...provided,
    // This style proved impossible to beat using SCSS, so we unset it using CSS-in-JS.
    // Everything else in InputFilterable is styled using SCSS.
    outline: "unset",
  }),
};

/** We need to do some translation of Binti input props into react-select props.
 * This hook takes all the props and returns the adjusted names for them.
 *
 * It incidentally also includes our `label` / `description` / etc props, but
 * they don't seem to do any harm so we haven't engineered their removal in this
 * function.
 */
const reactSelectInputProps = props => {
  const { label, required, id, disabled, values, name, ...otherProps } = props;

  return {
    label,
    required,
    inputId: id,
    isDisabled: disabled,
    options: values,
    styles: customStyles,
    className: styles.bintiSelectContainer,
    classNamePrefix: "bintiSelect",
    components: { ClearIndicator, SelectContainer },
    name: name || (required && label.replace(/ /g, "_")),
    ...otherProps,
  };
};

const InputFilterableWithSelectAll = props => {
  const onSelectAll = preventDefault(() => {
    // flatten out grouped options
    const flattenedValue = value => {
      if (isArray(value.options)) {
        return flatten(value.options.map(flattenedValue));
      }

      return value;
    };

    props.onChange(flatten(props.values.map(flattenedValue)));
  });

  return (
    <BasicInputLayout
      {...props}
      // put the select all on the same line as the label
      label={({ Label, ...labelProps }) => (
        <Flex row justify="space-between">
          <Label {...labelProps}>{props.label}</Label>
          <Link onClick={onSelectAll}>
            <T t="select_all" />
          </Link>
        </Flex>
      )}
      inputComponent={inputProps => (
        <Select {...reactSelectInputProps({ ...props, ...inputProps })} />
      )}
    />
  );
};

InputFilterableWithSelectAll.propTypes = {
  ...inputCommonPropTypes,
  placeholder: PropTypes.string,
  /** This will be invoked with a `{label, value}` pair or an array of them,
   * if `isMulti` is set to `true`. This function must be specified when
   * withSelectAll is true. */
  onChange: PropTypes.func.isRequired,
  /** A singular value, or an array of them if `isMulti` is true */
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  ]),
  /** A singular value, or an array of them if `isMulti` is true.
   * Use this prop if you're not attaching an `onChange` handler.
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  ]),
  /** Allow the user to clear their selection once they select it. */
  isClearable: PropTypes.bool,
  /** Allow multiple selection. */
  isMulti: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

/**
 * ### Cypress
 *
 * ```
 * cy.reactSelectOption({
 *   label: "Input Label Here",
 *   option: "Option To Select",
 * });
 * ```
 */
export const InputFilterable = props => {
  if (props.showSelectAll && props.isMulti) {
    return <InputFilterableWithSelectAll {...props} />;
  }

  return (
    <BasicInputLayout
      {...props}
      inputComponent={inputProps => (
        <Select {...reactSelectInputProps({ ...props, ...inputProps })} />
      )}
    />
  );
};

InputFilterable.propTypes = {
  ...InputFilterableWithSelectAll.propTypes,
  /** To indicate if this input is required
   *
   * setting `required` on a react-select input and relying on HTML form
   * validation to enforce that the field is required only works if the input has
   * a name. rather than making our devs set one themselves, if the input is
   * required we will generate a name for the input based on the provided label
   *
   * Per the react-select docs (https://react-select.com/props#select-props), if
   * a `name` is not provided there will be no `input` rendered, in which case
   * there's nothing for the HTML validation to validate against
   */
  required: PropTypes.bool,
  /** if true, shows a button to select all options.
   * Only relevant when the component is controlled. That is, the select all
   * button calls the onChange callback with all the values, but will not
   * change anything in an uncontrolled component. If true on an element
   * where isMulti is falsy, no select all button will be shown. */
  showSelectAll: PropTypes.bool,
  /** This will be invoked with a `{label, value}` pair or an array of them,
   * if `isMulti` is set to `true`. This function must be specified when
   * withSelectAll is true. */
  onChange: PropTypes.func,
};

/**
 * InputFilterable with values in dropdown populated by results from GraphQL query
 */

export const InputFilterableGraphQL = props => {
  const { data } = useQuery(props.query, {
    variables: props.queryVariables || {},
  });
  const values = data ? props.transformQueryData(data) : [];

  return <InputFilterable values={values} {...props} />;
};

InputFilterableGraphQL.propTypes = {
  ...InputFilterable.propTypes,
  /** Query to be executed */
  query: PropTypes.object.isRequired,
  /** Optional variables object passed along when calling the query */
  queryVariables: PropTypes.object,
  /** Function that takes in data from the query as an argument and
   * returns valid values for the dropdown */
  transformQueryData: PropTypes.func.isRequired,
};

/**
 * An asynchronous autocomplete component.
 *
 * If you don't know (or don't want to provide) the list of options for the user
 * upon first render, use `AsyncSelect` and provide a `loadOptions` function that
 * will be deferred until the user starts typing.
 *
 * **This is a "building block" component for making other select components.**
 * It's exposed in Heart's public API to support legacy purposes.  If you find yourself
 * reaching for this component, bring up your use case in #guild-frontend and
 * let's try to find a generic use case so that other teams can benefit from
 * your use case.
 */
export const InputFilterableAsync = props => (
  <BasicInputLayout
    {...props}
    inputComponent={inputProps => (
      <AsyncSelect
        loadingMessage={() => t("loading")}
        noOptionsMessage={() => t("no_options")}
        {...reactSelectInputProps({ ...props, ...inputProps })}
      />
    )}
  />
);

InputFilterableAsync.propTypes = {
  ...inputCommonPropTypes,
  /** To indicate if this input is required
   *
   * setting `required` on a react-select input and relying on HTML form
   * validation to enforce that the field is required only works if the input has
   * a name. rather than making our devs set one themselves, if the input is
   * required we will generate a name for the input based on the provided label
   *
   * Per the react-select docs (https://react-select.com/props#select-props), if
   * a `name` is not provided there will be no `input` rendered, in which case
   * there's nothing for the HTML validation to validate against
   */
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  /** This will be invoked with a `{label, value}` pair or an array of them,
   * if `isMulti` is set to `true`.
   */
  onChange: PropTypes.func,
  /** A singular value, or an array of them if `isMulti` is true */
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  ]),
  /** A singular value, or an array of them if `isMulti` is true.
   * Use this prop if you're not attaching an `onChange` handler.
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  ]),
  /** Allow the user to clear their selection once they select it. */
  isClearable: PropTypes.bool,
  /** Allow multiple selection. */
  isMulti: PropTypes.bool,
  /** Fetch the list of options.  Signature:
   * `(userEnteredText, callback) => callback([{label, value}])` */
  loadOptions: PropTypes.func,
  /** Only load results the first time; use a cache thereafter */
  cacheOptions: PropTypes.bool,
  /** If true, fires the request for `loadOptions` immediately, not waiting for
   * user interaction.
   */
  defaultOptions: PropTypes.bool,
  /** options => options */
  filterOptions: PropTypes.func,
};
