import {
  InputCheckboxGroup,
  InputDropdown,
  LiveRegion,
} from "@heart/components";
import I18n from "i18n-js";
import { isEqual, sortBy } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import { GENDERS_OPEN_TO_OPTIONS } from "@root/constants";

const { t } = translationWithRoot(
  "placement_preferences.child_genders_open_to"
);

const ALL_GENDER_OPTIONS = GENDERS_OPEN_TO_OPTIONS.map(gender =>
  I18n.t(`activerecord.enums.common.gender.${gender}`, {
    locale: "en",
  })
);

const GenderSelect = ({
  gendersOpenTo,
  openToAllGenders,
  gendersOpenToInputName,
  requiredInput,
  onChangeHandler,
}) => (
  <InputCheckboxGroup
    required={requiredInput}
    label={t("checkbox_title")}
    value={gendersOpenTo}
    values={GENDERS_OPEN_TO_OPTIONS.map(gender => ({
      /* Label is translated for user */
      label: I18n.t(`activerecord.enums.common.gender.${gender}`),
      /* Value is English string. Ticket to use slug instead: ENG-11322  */
      value: I18n.t(`activerecord.enums.common.gender.${gender}`, {
        locale: "en",
      }),
    }))}
    name={gendersOpenToInputName}
    onChange={value => {
      const hasSelectedAllOptions = isEqual(
        sortBy(value),
        sortBy(ALL_GENDER_OPTIONS)
      );

      /* if user has selected all options, set openToAll to true */
      const openToAllGendersValue = hasSelectedAllOptions
        ? "true"
        : openToAllGenders;

      onChangeHandler({
        gendersOpenToValue: value,
        openToAllGendersValue,
      });
    }}
    /* hide all these inputs if openToAll is true */
    hidden={openToAllGenders === "true"}
  />
);

GenderSelect.propTypes = {
  gendersOpenTo: PropTypes.arrayOf(PropTypes.string).isRequired,
  openToAllGenders: PropTypes.string.isRequired,
  gendersOpenToInputName: PropTypes.string.isRequired,
  requiredInput: PropTypes.bool.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

/** Allow the user to select their placement preference of which genders they're open to.
 *    Placements > Row Actions > Edit Placement Information
 */
const ChildGendersOpenTo = ({
  gendersOpenTo,
  openToAllGenders,
  openToAllGendersInputName,
  gendersOpenToInputName,
  requiredInput,
  onChangeHandler,
}) => (
  <Fragment>
    <InputDropdown
      required={requiredInput}
      hideBlank
      id="placement_preferences_child_gender"
      name={openToAllGendersInputName}
      label={t("open_to_all_label")}
      values={[
        [t("open_to_all_true"), true],
        [t("open_to_all_false"), false],
      ]}
      value={openToAllGenders}
      onChange={value => {
        /* if openToAll is set to true, set genders to all options */
        const gendersOpenToValue = value ? ALL_GENDER_OPTIONS : [];

        onChangeHandler({
          gendersOpenToValue,
          openToAllGendersValue: String(value),
        });
      }}
    />
    <LiveRegion>
      <GenderSelect
        gendersOpenTo={gendersOpenTo}
        openToAllGenders={openToAllGenders}
        gendersOpenToInputName={gendersOpenToInputName}
        requiredInput={requiredInput}
        onChangeHandler={onChangeHandler}
      />
    </LiveRegion>
  </Fragment>
);
ChildGendersOpenTo.defaultProps = {
  requiredInput: true,
  openToAllGendersInputName: "open_to_all_genders",
  gendersOpenToInputName: "genders_open_to",
};

ChildGendersOpenTo.propTypes = {
  gendersOpenTo: PropTypes.arrayOf(PropTypes.string).isRequired,
  openToAllGenders: PropTypes.oneOf(["true", "false"]).isRequired,
  openToAllGendersInputName: PropTypes.string,
  gendersOpenToInputName: PropTypes.string,
  requiredInput: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired,
};

export default ChildGendersOpenTo;
