import { useQuery } from "@apollo/client";
import {
  compact,
  get,
  isArrayLike,
  isEmpty,
  isEqual,
  isNil,
  omit,
  omitBy,
  pick,
} from "lodash";
import { useEffect, useMemo, useState } from "react";

import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import onUploadForCell from "./common/onUploadForCell";

const directionOptions = Object.freeze([null, "ASC", "DESC"]);

const paginationKeys = Object.freeze(["first", "last", "before", "after"]);
const sortingKeys = Object.freeze(["sortBy", "sortDirection"]);

/** Returns the next sort direction in the list, looping around
 * if the end of the list has been reached
 */
export const nextDirection = direction =>
  directionOptions[(directionOptions.indexOf(direction) + 1) % 3];

const useGraphQLTable = ({
  columns,
  query,
  queryTitle,
  pageSize = 20,
  requiredVariables = [],
  isVariableSet = ({ value }) => value !== undefined,
  onQueryCompleted,
  onFilterChange = () => false,
  defaultValues = {},
  defaultSort = {},
  disableUploadForRow,
  disableUploadColumnIdCellIndexes,
  onUpload,
  defaultFilters = {},
  filtersToPreserveOnClear = [],
}) => {
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const queryParamsFromUrl = JSON.parse(queryParams.get("query"));

  const tableIsSortable = columns.some(
    ({ sortBy: columnSortBy }) => !isNil(columnSortBy)
  );

  const [queryVariables, setQueryVariables] = useState(
    isNil(queryParamsFromUrl)
      ? {
          ...defaultValues,
          first: pageSize,
          ...defaultSort,
          ...defaultFilters,
        }
      : { ...defaultValues, ...defaultFilters, ...queryParamsFromUrl } // query params override defaults
  );

  useEffect(() => {
    onFilterChange(omit(queryVariables, paginationKeys));
  }, [onFilterChange, queryVariables]);

  const { sortBy, sortDirection } = queryVariables;

  const appliedFilterValueFor = field => queryVariables[field];

  const allRequiredVariablesSet = requiredVariables.every(variable =>
    isVariableSet({ variable, value: appliedFilterValueFor(variable.field) })
  );

  const {
    data: graphQLData,
    loading,
    refetch,
  } = useQuery(query, {
    variables: queryVariables,
    skip: !allRequiredVariablesSet,
    onCompleted: onQueryCompleted,
  });

  useEffect(() => {
    if (allRequiredVariablesSet) {
      refetch(queryVariables);
    }
  }, [refetch, queryVariables, allRequiredVariablesSet]);

  useEffect(() => {
    queryParams.set("query", JSON.stringify(queryVariables));
    queryVariablesVar(queryVariables);

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${queryParams}`
    );
  }, [queryVariables, queryParams]);

  const isActiveSortColumn = columnSortBy => columnSortBy === sortBy;
  const onSortToggle = columnSortBy => {
    if (isActiveSortColumn(columnSortBy)) {
      const newSortDirection = nextDirection(sortDirection);
      setQueryVariables({
        ...queryVariables,
        sortBy: newSortDirection ? columnSortBy : null,
        sortDirection: newSortDirection,
      });
    } else {
      setQueryVariables({
        ...queryVariables,
        sortBy: columnSortBy,
        sortDirection: "ASC",
      });
    }
  };

  const isFirstPage = !get(
    graphQLData,
    `${queryTitle}.pageInfo.hasPreviousPage`
  );
  const isLastPage = !get(graphQLData, `${queryTitle}.pageInfo.hasNextPage`);

  const fetchPreviousPage = () => {
    setQueryVariables({
      ...queryVariables,
      first: null,
      last: pageSize,
      before: get(graphQLData, `${queryTitle}.pageInfo.startCursor`),
      after: null,
    });
  };
  const fetchNextPage = () => {
    setQueryVariables({
      ...queryVariables,
      first: pageSize,
      last: null,
      after: get(graphQLData, `${queryTitle}.pageInfo.endCursor`),
      before: null,
    });
  };

  /**
   * When filters are applied, we update the query variables, which will
   * update the URL parameters and then trigger a refetch.  We omit
   * empty strings and arrays because that indicates the user was trying
   * to clear the filter.
   */
  const applyFilters = filters => {
    const newQueryVars = omitBy(
      {
        ...queryVariables,
        ...filters,
        // When the query changes, we need to return to the first page of results
        last: null,
        after: null,
        before: null,
        first: pageSize,
      },
      value =>
        // we discard [] and "" because those are probably filters
        // that we're trying to clear
        (isArrayLike(value) && isEmpty(value)) || value == null
    );
    /** Only set query variables if they have changed */
    if (!isEqual(queryVariables, newQueryVars)) setQueryVariables(newQueryVars);
  };

  /** When we want to clear out all the filters applied, we clear out the
   * query variables which will update the URL params and trigger a refetch
   */
  const clearFilters = ({ preserveOnClear } = {}) => {
    setQueryVariables({
      ...pick(
        queryVariables,
        compact([...sortingKeys, preserveOnClear, ...filtersToPreserveOnClear])
      ),
      // When the query changes, we need to return to the first page of results
      first: pageSize,
    });
  };

  const tableData = get(graphQLData, `${queryTitle}.nodes`);

  const getOnUploadForCell = onUploadForCell({
    disableUploadForRow,
    disableUploadColumnIdCellIndexes,
    onUpload,
  });

  return {
    tableIsSortable,
    loading,
    onSortToggle,
    isFirstPage,
    isLastPage,
    fetchPreviousPage,
    fetchNextPage,
    sortDirection,
    isActiveSortColumn,
    tableData,
    applyFilters,
    appliedFilterValueFor,
    clearFilters,
    getOnUploadForCell,
  };
};

export default useGraphQLTable;
