import { Link, ModalNotice } from "@heart/components";
import PropTypes from "prop-types";
import { relationshipsDashboardAdminChildPath } from "routes";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "family_finding.potential_kin_search.modal_notice"
);

const SuccessModalNotice = ({
  keystoneAgencyHuman,
  childAgencyHumans,
  hidden,
  onClose,
}) => (
  <ModalNotice
    hidden={hidden}
    onClose={onClose}
    title={
      keystoneAgencyHuman
        ? t("profile_was_created", { name: keystoneAgencyHuman.firstName })
        : t("potential_connections_created")
    }
  >
    {t("click_to_view_connections_of")}
    {childAgencyHumans.map(({ childId, label }) => (
      <Link
        key={childId}
        href={`${relationshipsDashboardAdminChildPath(
          childId
        )}?tab=Potential+Connections`}
      >
        {label}
      </Link>
    ))}
  </ModalNotice>
);
SuccessModalNotice.propTypes = {
  /** Whether modal should be hidden or not */
  hidden: PropTypes.bool.isRequired,
  /** Function called when the modal is closed */
  onClose: PropTypes.func.isRequired,
  /** When present, keystone's name will be displayed in the modal title */
  keystoneAgencyHuman: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }),
  /** List of children to link out to within the modal
   * We're using a `label` as the fullName + DOB as these entries are coming
   * back from a GraphQL query powered input
   */
  childAgencyHumans: PropTypes.arrayOf(
    PropTypes.shape({
      childId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default SuccessModalNotice;
