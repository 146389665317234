import { Link } from "@heart/components";
import I18n from "i18n-js";
import { isEmpty, pick, sortBy } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";

import BGCheckLogRow from "./BGCheckLogRow";

class ApplicationBGCheckLogs extends Component {
  constructor(props) {
    super(props);

    const logsWithKeys = props.logs.map(log =>
      Object.assign(log, { key: this.logKey(log) })
    );
    const logsSortedWithKeys = sortBy(logsWithKeys, log => log.key);
    this.state = {
      logs: logsSortedWithKeys,
    };
  }

  replaceLogInLogs = (log, updating = false) => {
    const { logs } = this.state;
    const index = logs.findIndex(el => el.key === log.key);
    const newLog = Object.assign({}, log, { updating: updating });
    const newLogs = Object.assign([...logs], { [index]: newLog });
    if (index === null || newLogs.length !== logs.length) {
      alert("yipes!");
    }

    this.setState({
      logs: newLogs,
    });
  };

  currentLogObjForAjax = log =>
    JSON.stringify({
      background_check_log: pick(log, [
        "adult_profile_id",
        "background_check_id",
        "completed_at",
        "status",
        "notes",
        "application_id",
        "logged_by_id",
      ]),
    });

  updateLog = log => {
    let currentLog = log;
    const { applicationId } = this.props;

    if (currentLog.background_check_renewal_required) {
      currentLog = Object.assign({}, currentLog, {
        application_id: applicationId,
      });
    }

    this.replaceLogInLogs(currentLog, true);

    $.ajax({
      method: "patch",
      url: `/admin/applications/${applicationId}/background_check_records/${currentLog.id}`,
      dataType: "json",
      contentType: "application/json",
      data: this.currentLogObjForAjax(currentLog),
      success: result => {
        this.replaceLogInLogs(Object.assign(result, { key: currentLog.key }));
      },
      error: () => {
        alert("request failed");
        this.replaceLogInLogs(currentLog);
      },
    });
  };

  createLog = log => {
    let currentLog = log;
    const { applicationId, loggedByOptions } = this.props;

    if (currentLog.background_check_renewal_required) {
      currentLog = Object.assign({}, currentLog, {
        application_id: applicationId,
      });
    }

    this.replaceLogInLogs(currentLog, true);

    if (
      currentLog.status &&
      (currentLog.completed_at || currentLog.status === "not_applicable") &&
      (currentLog.logged_by_id || isEmpty(loggedByOptions))
    ) {
      $.ajax({
        method: "post",
        url: `/admin/applications/${applicationId}/background_check_records`,
        dataType: "json",
        contentType: "application/json",
        data: this.currentLogObjForAjax(currentLog),
        success: result => {
          this.replaceLogInLogs(Object.assign(result, { key: currentLog.key }));
        },
        error: () => {
          alert("request failed");
          this.replaceLogInLogs(currentLog);
        },
      });
    } else {
      this.replaceLogInLogs(currentLog);
    }
  };

  logKey = log =>
    `${log.adult_role}-${log.adult_profile_id}-${log.background_check}-${log.id}`;

  render() {
    const { statusOptions, loggedByOptions, exemptionStatuses, applicationId } =
      this.props;
    const { logs } = this.state;

    const exemptionsBanner = [];
    exemptionStatuses.forEach(data => {
      exemptionsBanner.push(
        <div>
          {data[0]} has a background check that requires an exemption. The
          exemption status is: {I18n.t(`admin.background.${data[1]}`)}. Click{" "}
          <Link
            href={`/admin/applications/${applicationId}/background_check_exemptions`}
          >
            here
          </Link>{" "}
          to view.
        </div>
      );
    });

    return (
      <div>
        <If
          condition={
            exemptionStatuses.length > 0 &&
            [
              "needs_exemption",
              "exemption_denied",
              "exemption_completed",
            ].includes(exemptionStatuses[0][1])
          }
        >
          <div className="banner">{exemptionsBanner}</div>
        </If>
        <br />
        <table
          border="0"
          cellSpacing="0"
          cellPadding="0"
          className="index_table index"
        >
          <thead>
            <tr>
              <th>Adult Name</th>
              <th>Role</th>
              <th>Background Check</th>
              <th>Logged By</th>
              <th>Status</th>
              <th>Date</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {logs.map(log => (
              <BGCheckLogRow
                key={log.key}
                log={log}
                createLog={this.createLog}
                updateLog={this.updateLog}
                statusOptions={statusOptions[log.background_check_step]}
                loggedByOptions={loggedByOptions}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

// exemptionStatuses for two applicants with exemptions would be passed as
// [[name1, exemption_status], [name2, exemption_status]]

ApplicationBGCheckLogs.propTypes = {
  applicationId: PropTypes.number.isRequired,
  logs: PropTypes.array.isRequired,
  statusOptions: PropTypes.object.isRequired,
  exemptionStatuses: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  loggedByOptions: PropTypes.array,
};
export default ApplicationBGCheckLogs;
