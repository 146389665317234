import { Flex, LiveRegion, ScrollWhenTooWide } from "@heart/components";
import * as HeartTable from "@heart/components/table/Table";
import { flatten, isEmpty, isNil } from "lodash";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import TableCaption from "./TableCaption";
import TableCell from "./TableCell";
import TableColumnHeader from "./TableColumnHeader";
import TableLoadingIndicator from "./TableLoadingIndicator";
import styles from "./tableCommon.module.scss";

const { t } = translationWithRoot("data_table");
const { Table, Td, Thead, Tr, Tbody } = HeartTable;

const DataTable = ({
  title,
  tableIsSortable,
  sortDirection,
  columns,
  colHeaderProps = () => ({}),
  colCellProps = () => ({}),
  loading,
  data,
  emptyStatePrompt,
}) => (
  <LiveRegion>
    <ScrollWhenTooWide>
      <Table>
        <TableCaption caption={title} tableIsSortable={tableIsSortable} />
        <Thead>
          <Tr>
            {columns.map((column, columnIndex) => (
              <TableColumnHeader
                key={`th-${column.id}`}
                id={column.id}
                name={column.columnName.name}
                justify={column.columnName.justify}
                sortDirection={sortDirection}
                colSpan={
                  Array.isArray(column.cell) ? column.cell.length : undefined
                }
                {...colHeaderProps({ column, columnIndex })}
              />
            ))}
          </Tr>
        </Thead>
        <Tbody>
          <Choose>
            <When condition={!loading && isNil(data) && emptyStatePrompt}>
              <Tr>
                <Td colSpan={columns.length}>
                  <Flex row align="center" justify="center">
                    {emptyStatePrompt}
                  </Flex>
                </Td>
              </Tr>
            </When>
            <When condition={Array.isArray(data) && isEmpty(data) && !loading}>
              <Tr>
                <Td colSpan={columns.length}>
                  <Flex row align="center" justify="center">
                    {t("default_no_data_available")}
                  </Flex>
                </Td>
              </Tr>
            </When>
            <When condition={Array.isArray(data)}>
              {data.map(rowData => (
                <Tr key={rowData.id} className={styles.tableRow}>
                  {columns.map(column => {
                    const cells = flatten([column.cell]);
                    return cells.map((cell, cellIndex) => (
                      <TableCell
                        key={`td-${rowData.id}-${column.id}-${cellIndex}`}
                        rowData={rowData}
                        cell={cell}
                        iconOnlyUpload={column.iconOnlyUpload}
                        /** remove right side table cell padding from all but the last
                         * cell when a column header spans more than one column
                         */
                        removeRightPadding={cellIndex < cells.length - 1}
                        {...colCellProps({ rowData, column, cellIndex })}
                      />
                    ));
                  })}
                </Tr>
              ))}
            </When>
          </Choose>
          <If condition={loading}>
            <TableLoadingIndicator />
          </If>
        </Tbody>
      </Table>
    </ScrollWhenTooWide>
  </LiveRegion>
);
DataTable.propTypes = {
  /** A caption for the table. Required when the DetailsTable is not
   * on top of a `Surface` (or any `SurfaceBase` like `SurfaceForm` / `Notice` etc)
   * or when the `Surface`'s title doesn't adequately convey to non-sighted users
   * what the data in this table refers to.
   * When inferred from the `Surface` title, it's hidden. */
  title: PropTypes.string,
  /** Indicates whether the table is sortable. When true, adds an accessible message
   * to the table so folks know that columns can be sorted on column header click
   */
  tableIsSortable: PropTypes.bool,
  /** What direction the table contents should be sorted in */
  sortDirection: PropTypes.string,
  /** A list of the columns for the table, indicating name, display logic,
   * filterability, sortability, and whether the dropzone for the column
   * should be icon only (when used in conjunction with onUpload)
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cell: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.func, PropTypes.string])
        ),
      ]).isRequired,
      id: PropTypes.string.isRequired,
      columnName: PropTypes.shape({
        name: PropTypes.string.isRequired,
        justify: PropTypes.oneOf(["start", "center", "end"]),
      }).isRequired,
      iconOnlyUpload: PropTypes.bool,
    })
  ).isRequired,
  /** Function called with the column & columnIndex to determine any
   * additional props for the header
   */
  colHeaderProps: PropTypes.func,
  /** Function called with the rowData, column, & cellIndex to determine
   * any additional props for a table cell
   */
  colCellProps: PropTypes.func,
  /** When true, displays a loading state in the table */
  loading: PropTypes.bool,
  /** Data to be displayed in the table */
  data: PropTypes.arrayOf(PropTypes.shape),
  /** Prompt to display when action is required to trigger data load */
  emptyStatePrompt: PropTypes.string,
};

export default DataTable;
