import { ArrayDataTable, Flex, Icons, Link } from "@heart/components";
import { Fragment } from "react";

import ActionHeader from "../ActionHeader";

const Providers = () => (
  <Fragment>
    <ActionHeader title="Providers" action="Add New Provider" />
    <ArrayDataTable
      columns={[
        {
          id: "office",
          columnName: { name: "Medical Office", justify: "start" },
          cell: "office",
        },
        {
          id: "information",
          columnName: { name: "Information", justify: "start" },
          cell: ({ doctor = "----", phone, address }) => (
            <Flex column>
              <Flex row>
                <Icons.UserMd /> {doctor}
              </Flex>
              <Flex row>
                <Icons.Phone /> {phone}
              </Flex>
              <Flex row>
                <Icons.MapMarker /> <Flex column>{address}</Flex>
              </Flex>
            </Flex>
          ),
        },
        {
          id: "notes",
          columnName: { name: "Notes", justify: "start" },
          cell: "notes",
        },
        {
          id: "actions",
          columnName: { name: "Actions", justify: "start" },
          cell: ({ pencilLink, dotDotDotLink }) => (
            <Flex row>
              <Link href={pencilLink}>
                <Icons.Pencil />
              </Link>
              <Link href={dotDotDotLink}>
                <Icons.Ellipsis />
              </Link>
            </Flex>
          ),
        },
      ]}
      data={[
        {
          office: "Just Kids Pediatrics",
          phone: "(918) 591-3969",
          address: (
            <Fragment>
              1724 S Harvard Ave
              <br />
              Tulsa, OK 74112
            </Fragment>
          ),
          notes: "N/A",
          pencilLink: "#",
          dotDotDotLink: "#",
        },
      ]}
    />
  </Fragment>
);

export default Providers;
