import { translationWithRoot } from "@components/T";

import { formatAsLongDate } from "@lib/dates";

const { t } = translationWithRoot("family_finding.common");

const createNameAndDOBLabel = (fullName, dateOfBirth) =>
  dateOfBirth
    ? t("name_with_dob", {
        name: fullName,
        dob: formatAsLongDate(dateOfBirth),
      })
    : fullName;

export default createNameAndDOBLabel;
