/* eslint-disable no-console, no-alert */
const tryReload = (_jqXHR, textStatus, errorThrown) => {
  console.log(textStatus, errorThrown);
  window.alert(
    "\
We had a problem with your request and need to reload the \
page. Please try again when the page reloads. If you continue to \
encounter problems, please contact the engineering team."
  );

  window.location.reload();
};
/* eslint-enable no-console, no-alert */

export default class UploadTranslationsClient {
  constructor(parameters, templateCreated) {
    this.parameters = parameters;
    this.templateCreated = templateCreated;
  }

  uploadTranslations = files => {
    const data = new FormData();
    files.forEach(file => data.append("files[]", file));

    const {
      uploadPath: url,
      sourceHellosignTemplateId,
      targetLocale,
      field,
      formSlug,
    } = this.parameters;

    data.append("source_hellosign_template_id", sourceHellosignTemplateId);

    if (formSlug) {
      data.append("form_slug", formSlug);
      data.append("target_locale", targetLocale);
      data.append("field", field);
    }

    $.ajax({
      url,
      cache: false,
      contentType: false,
      processData: false,
      data,
      method: "post",
      success: ({ templateId }) => {
        this.templateCreated(templateId);
      },
      error: tryReload,
    });
  };
}
