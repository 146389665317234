import browserDetect from "browser-detect";
import { capitalize } from "lodash";

const browserName = browser => {
  if (!browser) return "";

  if (browser.name === "ie") {
    return "Internet Explorer";
  }

  return capitalize(browser.name);
};

const isIE = browser => Boolean(browser && browser.name === "ie");

const isIECompatibilityView = browser => {
  if (!browser) return false;
  if (!isIE(browser)) return false;
  if (browser.versionNumber < 11) return false;
  return navigator.userAgent.toLowerCase().includes("compatible");
};

const browserString = browser => {
  if (!browserName(browser)) return null;

  return `${browserName(browser)} (${browser.version}${
    isIECompatibilityView(browser) ? " Compatibility View" : ""
  })`;
};

export default userAgent => {
  const browser = browserDetect(userAgent);

  return Object.freeze({
    browserString: browserString(browser),
  });
};
