import {
  InputCheckbox,
  InputFilterable,
  InputText,
  SurfaceFieldset,
} from "@heart/components";
import { startCase } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import InputZipcode from "./InputZipcode";

const Address = props => {
  const { address, usStateOptions, title } = props;
  const [mailingAddressSameAsPhysical, setMailingAddressSameAsPhysical] =
    useState(true);
  const stateKeys = Object.keys(usStateOptions || {});
  const stateValues = stateKeys.map(label => ({
    label: startCase(label),
    value: usStateOptions[label],
  }));

  const defaultStateValue = stateValues.find(
    pair => pair.value === address?.addressState
  );
  return (
    <div>
      <SurfaceFieldset title={title || <T t="address.primary_residence" />}>
        <InputText
          label={<T t={"address.street_address"} />}
          type="text"
          name={
            "application[applicant_agency_profile_attributes][address_street]"
          }
          id={
            "application[applicant_agency_profile_attributes][address_street]"
          }
          required
          value={address?.addressStreet || ""}
        />
        <InputText
          label={<T t={"address.city"} />}
          type="text"
          name={
            "application[applicant_agency_profile_attributes][address_city]"
          }
          id={"application[applicant_agency_profile_attributes][address_city]"}
          required
          value={address?.addressCity || ""}
        />
        <InputFilterable
          label={<T t="address.state" />}
          name="application[applicant_agency_profile_attributes][address_state]"
          id="application[applicant_agency_profile_attributes][address_state]"
          required
          options={stateValues}
          defaultValue={defaultStateValue}
        />
        <InputZipcode
          label={<T t={"address.zip_code"} />}
          name={
            "application[applicant_agency_profile_attributes][address_zipcode]"
          }
          id={
            "application[applicant_agency_profile_attributes][address_zipcode]"
          }
          required
          value={address?.addressZipcode || ""}
        />
        <InputCheckbox
          label={<T t="address.mailing_address_same_as_physical" />}
          value={mailingAddressSameAsPhysical}
          name={
            "application[applicant_agency_profile_attributes][mailing_address_is_same_as_physical]"
          }
          id={
            "application[applicant_agency_profile_attributes][mailing_address_is_same_as_physical]"
          }
          onChange={checked => {
            setMailingAddressSameAsPhysical(checked);
          }}
        />
        {!mailingAddressSameAsPhysical && (
          <InputText
            label={<T t={"address.mailing_street_address"} />}
            type="text"
            name={
              "application[applicant_agency_profile_attributes][mailing_address_street]"
            }
            id={
              "application[applicant_agency_profile_attributes][mailing_address_street]"
            }
            required
            value={address?.addressStreet || ""}
          />
        )}
        {!mailingAddressSameAsPhysical && (
          <InputText
            label={<T t={"address.mailing_city"} />}
            type="text"
            name={
              "application[applicant_agency_profile_attributes][mailing_address_city]"
            }
            id={
              "application[applicant_agency_profile_attributes][mailing_address_city]"
            }
            required
            value={address?.addressCity || ""}
          />
        )}
        {!mailingAddressSameAsPhysical && (
          <InputFilterable
            label={<T t="address.mailing_state" />}
            name="application[applicant_agency_profile_attributes][mailing_address_state]"
            id="application[applicant_agency_profile_attributes][mailing_address_state]"
            required
            options={stateKeys.map(label => ({
              label: startCase(label),
              value: usStateOptions[label],
            }))}
          />
        )}
        {!mailingAddressSameAsPhysical && (
          <InputZipcode
            label={<T t={"address.mailing_zip_code"} />}
            name={
              "application[applicant_agency_profile_attributes][mailing_address_zipcode]"
            }
            id={
              "application[applicant_agency_profile_attributes][mailing_address_zipcode]"
            }
            required
            value={address?.addressZipcode || ""}
          />
        )}
      </SurfaceFieldset>
    </div>
  );
};

Address.propTypes = {
  /** Address of the applicant */
  address: PropTypes.shape({
    addressStreet: PropTypes.string,
    addressCity: PropTypes.string,
    addressState: PropTypes.string,
    addressZipcode: PropTypes.string,
  }),
  title: PropTypes.string,
  /** US States options for user to choose */
  usStateOptions: PropTypes.object,
};

export default Address;
