import I18n from "i18n-js";
import { isEmpty, isNil } from "lodash";

/** Map a single constant to the value it correlates to in our dropdowns */
export const mapConstantToValue = ({ translationKey, value }) =>
  isNil(value)
    ? undefined
    : {
        label: I18n.t(`activerecord.enums.${translationKey}.${value}`),
        value,
      };

/** Map a set of constants to values that can be used with our dropdowns */
export const mapConstantSetToValues = ({ constant, translationKey }) =>
  constant.map(value => mapConstantToValue({ translationKey, value }));

/** Map a set of constants that are stored as a nested enum to a set of
 * options that can be used with our InputCheckboxGroup
 */
export const mapNestedEnumConstantToValues = ({ constant = [] }) =>
  constant.map(({ path, label, children }) => {
    const options = mapNestedEnumConstantToValues({
      constant: children,
    });
    const values = {
      label,
      value: path,
    };
    return isEmpty(options) ? values : { ...values, options };
  });
