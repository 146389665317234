import {
  Button,
  Flex,
  InputCheckbox,
  InputDropdown,
  InputFilterable,
  InputText,
  InputHidden,
} from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import RRuleInput from "@components/schedules/rrule/RRuleInput";

import styles from "./ScheduledRequirementTemplateFieldset.module.scss";

// Name formats are required for ActiveAdmin compatibility
const NAME_PREFIX = "schedule[requirement_templates_attributes]";
const ID_PREFIX = "scheduled_requirement_template_attribute";

const FOREIGN_KEY_FOR_TEMPLATE_TYPE = {
  ScheduledUploadTypeRequirementTemplate: "upload_type_id",
  ScheduledFormRequirementTemplate: "form_id",
};
const REQUIREMENT_TYPE_FOR_TEMPLATE_TYPE = {
  ScheduledUploadTypeRequirementTemplate: "ScheduledUploadTypeRequirement",
  ScheduledFormRequirementTemplate: "ScheduledFormRequirement",
};
const DISPLAY_NAME_FOR_TEMPLATE_TYPE = {
  ScheduledUploadTypeRequirementTemplate: "Upload Type",
  ScheduledFormRequirementTemplate: "Form",
};

/**
 * Allows you to configure the schedule for a requirement template.
 * Case Management Setup > Schedules > Edit > "Scheduled Requirement Templates"
 */
const ScheduledRequirementTemplateFieldset = ({
  requirementTemplate,
  type,
  startDateEventOptions,
  handleRemoveClick,
  associationOptions,
  index,
}) => {
  const foreignKey = FOREIGN_KEY_FOR_TEMPLATE_TYPE[type];
  const selectedValue = associationOptions.find(
    option => option.value === requirementTemplate[foreignKey]
  ) || { label: "Select..." };

  const [isDeleted, updateIsDeleted] = useState(false);
  const [selectedOption, updateSelectedOption] = useState(selectedValue);
  const [showDelayStartDateFields, updateShowDelayStartDateFields] = useState(
    requirementTemplate.specification.start_date_delay_number
  );

  const deleteCheckbox = (
    <InputCheckbox
      name={`${NAME_PREFIX}[${index}][_destroy]`}
      onChange={() => updateIsDeleted(!isDeleted)}
      label="Delete"
      htmlValue={isDeleted}
    />
  );

  const removeButton = (
    <Button onClick={handleRemoveClick(index)}>Remove</Button>
  );

  const innerForm = () => {
    const selectedStartEvent = startDateEventOptions.find(
      option =>
        option.value === requirementTemplate.specification.start_date_event
    );

    return (
      <ol>
        <li key={`${NAME_PREFIX}[${index}]_select`}>
          <label
            htmlFor={`${ID_PREFIX}_${index}_${foreignKey}`}
            className={styles.associatedObjectSelect}
          >
            {DISPLAY_NAME_FOR_TEMPLATE_TYPE[type]}
          </label>
          <InputFilterable
            labeledExternally
            id={`${ID_PREFIX}_${index}_${foreignKey}`}
            options={associationOptions}
            name={`${NAME_PREFIX}[${index}][${foreignKey}]`}
            defaultValue={selectedOption}
            onChange={option => updateSelectedOption(option)}
          />
        </li>
        <li>
          <InputFilterable
            label="Schedule Start Date Event"
            id={`${ID_PREFIX}_${index}_start_date_event`}
            name={`${NAME_PREFIX}[${index}][start_date_event]`}
            defaultValue={selectedStartEvent}
            options={startDateEventOptions}
          />
        </li>
        <li className="space-above-1">
          <InputCheckbox
            value={Boolean(showDelayStartDateFields)}
            onChange={() =>
              updateShowDelayStartDateFields(!showDelayStartDateFields)
            }
            label="Delay Schedule Start Date"
          />
        </li>
        <If condition={showDelayStartDateFields}>
          <li>
            <Flex>
              <InputText
                type="number"
                label="Start date delay number"
                hideLabel
                id={`${ID_PREFIX}_${index}_start_date_delay_number`}
                name={`${NAME_PREFIX}[${index}][start_date_delay_number]`}
                value={
                  requirementTemplate.specification.start_date_delay_number
                }
              />
              <InputDropdown
                label="Start date delay unit (e.g. days, weeks, months)"
                hideLabel
                id={`${ID_PREFIX}_${index}_start_date_delay_unit`}
                name={`${NAME_PREFIX}[${index}][start_date_delay_unit]`}
                value={requirementTemplate.specification.start_date_delay_unit}
                values={[
                  [
                    I18n.t(
                      "admin.requirement_templates.start_date_delay_units.days"
                    ),
                    "days",
                  ],
                  [
                    I18n.t(
                      "admin.requirement_templates.start_date_delay_units.weeks"
                    ),
                    "weeks",
                  ],
                  [
                    I18n.t(
                      "admin.requirement_templates.start_date_delay_units.months"
                    ),
                    "months",
                  ],
                  [
                    I18n.t(
                      "admin.requirement_templates.start_date_delay_units.years"
                    ),
                    "years",
                  ],
                ]}
              />
            </Flex>
          </li>
        </If>
        <If condition={!showDelayStartDateFields}>
          <InputHidden
            id={`${ID_PREFIX}_${index}_start_date_delay_number`}
            name={`${NAME_PREFIX}[${index}][start_date_delay_number]`}
            value=""
          />
          <InputHidden
            id={`${ID_PREFIX}_${index}_start_date_delay_unit`}
            name={`${NAME_PREFIX}[${index}][start_date_delay_unit]`}
            value=""
          />
        </If>
        <li>
          <RRuleInput
            name={`${NAME_PREFIX}[${index}][rrule]`}
            rruleString={requirementTemplate.specification.rrule}
          />
        </li>
      </ol>
    );
  };

  return (
    <li
      key={`${requirementTemplate.id}_${index}`}
      className={isDeleted ? styles.deletedFieldSet : undefined}
    >
      <fieldset
        className={`inputs has_many_fields ${styles.associatedObjectFieldset}`}
        data-testid={`${type}-fieldset`}
      >
        <legend>
          {`[${DISPLAY_NAME_FOR_TEMPLATE_TYPE[type]}] ${
            selectedOption.label || "New"
          }`}
          {(requirementTemplate.id && deleteCheckbox) || removeButton}
        </legend>
        <input
          name={`${NAME_PREFIX}[${index}][id]`}
          defaultValue={requirementTemplate.id}
          hidden
        />
        <input
          name={`${NAME_PREFIX}[${index}][type]`}
          defaultValue={type}
          hidden
        />
        <input
          name={`${NAME_PREFIX}[${index}][requirement_type]`}
          defaultValue={REQUIREMENT_TYPE_FOR_TEMPLATE_TYPE[type]}
          hidden
        />
        {!isDeleted && innerForm()}
      </fieldset>
    </li>
  );
};

ScheduledRequirementTemplateFieldset.propTypes = {
  handleRemoveClick: PropTypes.func.isRequired,
  requirementTemplate: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  startDateEventOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  associationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
};

export default ScheduledRequirementTemplateFieldset;
