import { Breadcrumbs, Flex, LoadingOverlay } from "@heart/components";
import { PropTypes } from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";
import BannerContainer from "@components/shared/banner/BannerContainer";

import BintiPropTypes from "@lib/BintiPropTypes";

const { t } = translationWithRoot("child_agency_placement_referral");

const ChildAgencyPlacementReferralPage = ({
  childId,
  children,
  loading,
  error,
}) => (
  <LoadingOverlay active={loading}>
    <If condition={error}>
      <BannerContainer type="error" message={error.message} />
    </If>
    <Flex column gap="100">
      <Breadcrumbs
        pages={[
          {
            label: I18n.t("views.common.child"),
            href: `/admin/children/${childId}`,
          },
          {
            label: t("referral"),
            href: `/child/${childId}/agency_placement_referral`,
          },
        ]}
      />
      {children}
    </Flex>
  </LoadingOverlay>
);

ChildAgencyPlacementReferralPage.propTypes = {
  childId: BintiPropTypes.ID,
  children: PropTypes.node,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

export default ChildAgencyPlacementReferralPage;
