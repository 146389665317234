import { useQuery, gql, useMutation } from "@apollo/client";
import { Flex, LoadingOverlay, Surface } from "@heart/components";
import _ from "lodash";
import { PropTypes } from "prop-types";

import ErrorBanner from "@components/shared/banner/ErrorBanner";

import UpdatePlacementSearchAssignedWorkersMutation from "@graphql/mutations/UpdatePlacementSearchAssignedWorkers.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import WorkersAssignedForm from "./WorkersAssignedForm";
import AddWorkers from "./add_workers/AddWorkers";

/** View and edit assigned workers for a given placement search.
 *    Placement Searches > Row Action > Edit Placements Search
 */
const WorkersAssignedEdit = ({ placementSearchId, childId, onCompleted }) => {
  const {
    data,
    loading: dataQueryLoading,
    error: dataQueryError,
  } = useQuery(
    gql`
      query WorkersAssignedEditData($placementSearchId: ID!) {
        placementSearch(id: $placementSearchId) {
          id
          agencyWorkers {
            id
            name
          }
          primaryWorkerAssignedId
        }
      }
    `,
    {
      variables: { placementSearchId },
    }
  );

  const [
    updateWorkersAssigned,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UpdatePlacementSearchAssignedWorkersMutation, {
    onCompleted,
  });

  const onChange = ({ agencyWorkers, primaryWorkerAssignedId }) =>
    updateWorkersAssigned({
      variables: {
        input: {
          ..._.omit(data.placementSearch, "__typename", "agencyWorkers"),
          primaryWorkerAssignedId,
          agencyWorkerIds: agencyWorkers?.map(aw => aw.id) || [],
        },
      },
    });

  const error = dataQueryError || mutationError;

  return (
    <LoadingOverlay active={dataQueryLoading || mutationLoading}>
      <If condition={error}>
        <ErrorBanner message={error.message} />
      </If>

      <Surface
        title={I18n.t(
          "javascript.components.placement_search.workers_assigned.title"
        )}
      >
        <Flex column>
          <WorkersAssignedForm
            formState={data?.placementSearch}
            onChange={onChange}
          />
          <AddWorkers
            childId={childId}
            placementSearchId={placementSearchId}
            onSubmit={newWorkers =>
              onChange({
                agencyWorkers: [
                  ...data.placementSearch.agencyWorkers,
                  ...newWorkers,
                ],
                primaryWorkerAssignedId: data.primaryWorkerAssignedId,
              })
            }
          />
        </Flex>
      </Surface>
    </LoadingOverlay>
  );
};

WorkersAssignedEdit.propTypes = {
  placementSearchId: BintiPropTypes.ID,
  childId: BintiPropTypes.ID,
  formName: PropTypes.string,
  onCancel: PropTypes.func,
  onCompleted: PropTypes.func,
};

export default WorkersAssignedEdit;
