import {
  ArrayDataTable,
  Flex,
  Icons,
  Link,
  Pill,
  Surface,
} from "@heart/components";
import PropTypes from "prop-types";

import ActionHeader from "../ActionHeader";
import styles from "../CcwisDemo.module.scss";

const CourtInformation = ({ courtEditLink }) => (
  <div style={{ maxWidth: "98.5%", margin: "0 8px 8px" }}>
    <Surface title="Court information" hideTitle>
      <ActionHeader title="Court Information" action="Add Court Date" />
      <h3 className={styles.tableTitle}>Recent and Upcoming Court Dates</h3>
      <ArrayDataTable
        columns={[
          {
            id: "hearing",
            columnName: { name: "Hearing", justify: "start" },
            cell: ({ hearingName, hearingDate }) => (
              <Flex column>
                <Flex>{hearingName}</Flex>
                <Flex row>
                  <Icons.Calendar />
                  {hearingDate}
                </Flex>
              </Flex>
            ),
          },
          {
            id: "status",
            columnName: { name: "Status", justify: "start" },
            cell: ({ status, statusVariant }) => (
              <Pill text={status} variant={statusVariant} />
            ),
          },
          {
            id: "disposition",
            columnName: { name: "Disposition", justify: "start" },
            cell: "disposition",
          },
          {
            id: "whoWasPresent",
            columnName: {
              name: "Who was Present",
              justify: "start",
            },
            cell: "whoWasPresent",
          },
          {
            id: "actions",
            columnName: { name: "Actions", justify: "start" },
            cell: ({ pencilLink, dotDotDotLink }) => (
              <Flex row>
                <Link href={pencilLink}>
                  <Icons.Pencil />
                </Link>
                <Link href={dotDotDotLink}>
                  <Icons.Ellipsis />
                </Link>
              </Flex>
            ),
          },
        ]}
        data={[
          {
            hearingName: "Adjudication Hearing",
            hearingDate: "05/27/2024",
            status: "Planned",
            statusVariant: "alert",
            disposition: "N/A",
            whoWasPresent: "N/A",
            pencilLink: courtEditLink,
          },
          {
            hearingName: "Initial Hearing",
            hearingDate: "05/14/2024",
            status: "Complete",
            statusVariant: "info",
            disposition: "Temporary Custody",
            whoWasPresent:
              "Emily Lopez, Mother's Attorney, Becky Smith, FRC Attorney",
          },
        ]}
      />
    </Surface>
  </div>
);

CourtInformation.propTypes = {
  courtEditLink: PropTypes.string.isRequired,
};

export default CourtInformation;
