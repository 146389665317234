import { InputDate, InputDropdown } from "@heart/components";
import classnames from "classnames";
import { debounce, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import Textarea from "react-textarea-autosize";

import Label from "@components/reusable_ui/forms/Label";

import { generateIds } from "@lib/generateId";

/** A row in a household's BG check logs: Applicant Data > BG Checks */
class BGCheckLogRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: generateIds("notesId")(),
    };
  }

  createOrUpdateLog = log => {
    if (log.updating) {
      return false;
    }

    if (log.id) {
      return this.props.updateLog(log);
    }

    return this.props.createLog(log);
  };

  dateBlurred = selectedDate => {
    const { log } = this.props;

    if (log.completed_at !== selectedDate && selectedDate !== "Invalid date") {
      this.createOrUpdateLog(
        Object.assign({}, log, { completed_at: selectedDate })
      );
    }
  };

  notesChanged = debounce(value => {
    const { log } = this.props;
    this.createOrUpdateLog(Object.assign({}, log, { notes: value }));
  }, 500);

  statusChanged = selectedStatus => {
    const { log } = this.props;

    this.createOrUpdateLog(Object.assign({}, log, { status: selectedStatus }));
  };

  loggedByChanged = selectedLoggedBy => {
    const { log } = this.props;

    this.createOrUpdateLog(
      Object.assign({}, log, { logged_by_id: selectedLoggedBy })
    );
  };

  render() {
    const { log, statusOptions, loggedByOptions } = this.props;
    const { loggedById, statusId, notesId } = this.state.ids;

    const rowClassName = classnames({
      "background-loading": log.updating,
    });

    const statusSelectOptions = statusOptions.map(
      opt => [
        I18n.t(`activerecord.attributes.background_check_log/statuses.${opt}`),
        opt,
      ],
      {}
    );

    return (
      <tr className={rowClassName}>
        <td key="adult name">{log.adult_name}</td>
        <td key="role">
          {I18n.t(`activerecord.attributes.user/roles.${log.adult_role}`)}
        </td>
        <td key="background check">{log.background_check}</td>
        <td key="logged by">
          {isEmpty(loggedByOptions) ? (
            log.logged_by
          ) : (
            <InputDropdown
              hideLabel
              label={`${log.background_check} Logged By`}
              hideBlank
              name="logged-by-select"
              onChange={this.loggedByChanged}
              value={`${log.logged_by_id}`}
              values={loggedByOptions}
            />
          )}
        </td>
        <td key="status">
          <InputDropdown
            hideBlank
            hideLabel
            label={`${log.background_check} Status`}
            name="status-select"
            onChange={this.statusChanged}
            value={log.status}
            disabled={!log.current_user_can_edit}
            values={statusSelectOptions}
          />
        </td>
        <td key="date" className="min-required-width">
          <InputDate
            onBlur={this.dateBlurred}
            required
            disabled={
              !log.current_user_can_edit || log.status === "not_applicable"
            }
            label={`${log.background_check} Completed At Date`}
            hideLabel
            value={log.completed_at || ""}
          />
        </td>
        <td key="notes">
          <Label hide htmlFor={notesId}>
            {`${log.background_check} Notes`}
          </Label>
          <Textarea
            defaultValue={log.notes}
            id={notesId}
            onChange={e => this.notesChanged(e.target.value)}
            disabled={!log.current_user_can_edit}
          />
        </td>
      </tr>
    );
  }
}

BGCheckLogRow.propTypes = {
  log: PropTypes.object.isRequired,
  statusOptions: PropTypes.array.isRequired,
  createLog: PropTypes.func.isRequired,
  updateLog: PropTypes.func.isRequired,
  loggedByOptions: PropTypes.any,
};

export default BGCheckLogRow;
