import { useQuery, useMutation } from "@apollo/client";
import { Flex, InputCheckboxGroup, Modal, Text } from "@heart/components";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

import { translationWithRoot } from "@components/T";

import CreateOrUpdateAgencyHuman from "@graphql/mutations/CreateOrUpdateAgencyHuman.graphql";
import AgencyHumanAutocompleteQuery from "@graphql/queries/AgencyHumanAutocomplete.graphql";
import PrimarySubdivisionsQuery from "@graphql/queries/PrimarySubdivisions.graphql";

import createNameAndDOBLabel from "../common/createNameAndDOBLabel";
import {
  convertToAddressInputs,
  convertToPhoneNumberInputs,
} from "./modalConversions";

const { t } = translationWithRoot(
  "family_finding.potential_kin_search.potential_kin"
);

export const potentialKinKeyedByEntityId = potentialKin =>
  potentialKin.reduce(
    (keyedObject, { fullName, dateOfBirth, entityId, ...kin }) => ({
      ...keyedObject,
      [entityId]: {
        label: createNameAndDOBLabel(fullName, dateOfBirth),
        value: entityId,
        fullName,
        dateOfBirth,
        ...kin,
      },
    }),
    {}
  );

const LinkAllPotentialConnectionsModal = ({
  childAgencyHumans,
  setChildAgencyHumans,
  potentialKin,
  hidden,
  setModalTypeVisibility,
  onCancel,
}) => {
  const { data: primarySubdivisionsData = {} } = useQuery(
    PrimarySubdivisionsQuery,
    {
      variables: { countryCode: "US" },
    }
  );
  const { primarySubdivisions = [] } = primarySubdivisionsData;

  const humansFromResponse = response => [
    ...(response?.agencyHumanMatches || []).map(
      ({ id, childId, fullName, dateOfBirth, agencyId }) => ({
        label: createNameAndDOBLabel(fullName, dateOfBirth),
        value: id,
        childId,
        agencyId,
      })
    ),
  ];
  const keyedPotentialKin = useMemo(
    () => potentialKinKeyedByEntityId(potentialKin),
    [potentialKin]
  );
  const potentialKinValues = useMemo(
    () => Object.values(keyedPotentialKin),
    [keyedPotentialKin]
  );
  const [selectedPotentialConnections, setSelectedPotentialConnections] =
    useState(Object.keys(keyedPotentialKin));

  useEffect(() => {
    setSelectedPotentialConnections(Object.keys(keyedPotentialKin));
  }, [keyedPotentialKin]);

  const [createOrUpdateAgencyHuman, { loading: createAHLoading }] = useMutation(
    CreateOrUpdateAgencyHuman
  );

  return (
    <Modal
      asForm
      hidden={hidden}
      onCancel={onCancel}
      title={t("link_all_potential_kin_as_potential_connections")}
      submitting={createAHLoading}
      submitText={t("create_new_potential_connections")}
      onSubmit={async () => {
        if (isEmpty(childAgencyHumans)) return;

        await Promise.all(
          selectedPotentialConnections.map(label => {
            const {
              firstName,
              middleName,
              lastName,
              dateOfBirth,
              dateOfDeath,
              addresses,
              phoneNumbers,
              emailAddress,
            } = keyedPotentialKin[label];
            const { agencyId } = childAgencyHumans[0];
            /** Create all new AgencyHumans who will be potential connections,
             * and connecting them to the children selected
             */
            return createOrUpdateAgencyHuman({
              variables: {
                agencyId,
                childAgencyHumanIds: childAgencyHumans.map(
                  ({ value }) => value
                ),
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                dateOfBirth: dateOfBirth,
                dateOfDeath: dateOfDeath
                  ? DateTime.fromFormat(dateOfDeath, "MM/dd/yyyy")
                  : null,
                addresses: convertToAddressInputs({
                  addresses: addresses.filter(({ valid }) => valid),
                  agencyId,
                  primarySubdivisions,
                }),
                phoneNumbers: convertToPhoneNumberInputs({
                  phoneNumbers: phoneNumbers.filter(({ valid }) => valid),
                }),
                emailAddresses: emailAddress
                  ? [{ emailAddress: emailAddress, primary: true }]
                  : [],
              },
            });
          })
        );

        setModalTypeVisibility("success");
      }}
    >
      <Flex column gap="200">
        <Text>{t("link_help_text")}</Text>
        <InputAutocompleteGraphQL
          required
          label={t("children")}
          query={AgencyHumanAutocompleteQuery}
          queryVariables={{ filterForChildren: true }}
          valuesFromResponse={humansFromResponse}
          onChange={setChildAgencyHumans}
          value={childAgencyHumans}
          isMulti
        />
        <InputCheckboxGroup
          label={t("confirm_potential_kin_selected")}
          value={selectedPotentialConnections}
          values={potentialKinValues}
          onChange={setSelectedPotentialConnections}
          selectAll={{ type: "links" }}
        />
      </Flex>
    </Modal>
  );
};
LinkAllPotentialConnectionsModal.propTypes = {
  /** Whether modal should be hidden or not */
  hidden: PropTypes.bool.isRequired,
  /** A function to change the state variable which determines whether this modal is hidden */
  setModalTypeVisibility: PropTypes.func.isRequired,
  /** Action to take when cancel button is clicked */
  onCancel: PropTypes.func.isRequired,
  /** When provided, will prepopulate the InputFilterable with the provided child(ren).
   * Should be provided with a label/value pair, as well as the agencyId
   */
  childAgencyHumans: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      agencyId: PropTypes.string.isRequired,
      childId: PropTypes.string.isRequired,
    })
  ).isRequired,
  setChildAgencyHumans: PropTypes.func.isRequired,
  /** A list of the potential connections to be connected to the child(ren) */
  potentialKin: PropTypes.arrayOf(
    PropTypes.shape({ fullName: PropTypes.string.isRequired })
  ),
};

export default LinkAllPotentialConnectionsModal;
