import classNames from "classnames";
import PropTypes from "prop-types";
import { Fragment } from "react";

import styles from "./TabPanelContents.module.scss";

const TabPanelContents = ({
  id,
  active,
  contents,
  showTitlesInContents,
  title,
  tabPanelTitleRef,
}) => (
  <div
    key={`${id}-panel`}
    id={`${id}-panel`}
    name={title}
    hidden={!active}
    aria-hidden={!active}
    aria-labelledby={id}
    role="tabpanel"
    className={classNames(styles.tabContents, {
      [styles.hiddenTabContents]: !active,
      "space-above-2": !showTitlesInContents,
    })}
    data-heart-component="TabPanelContent"
  >
    <If condition={active}>
      <h3
        tabIndex="-1"
        ref={tabPanelTitleRef}
        className={classNames({
          [styles.hiddenTabTitle]: !showTitlesInContents,
        })}
      >
        {title}
      </h3>
      <Fragment key={`${id}-content`}>{contents}</Fragment>
    </If>
  </div>
);

TabPanelContents.propTypes = {
  /** Unique identifier for the content tab */
  id: PropTypes.string.isRequired,
  /** Whether tab is currently active, determines whether tab panel is hidden */
  active: PropTypes.bool,
  /** Content to display in tab */
  contents: PropTypes.node,
  /** Whether to show the tab title at the top of the contents of the tab */
  showTitlesInContents: PropTypes.bool,
  /** Title for the tab */
  title: PropTypes.string.isRequired,
  /** `ref` for the panel's title */
  tabPanelTitleRef: PropTypes.object.isRequired,
};

export default TabPanelContents;
