import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";

import { DocumentsClientContext } from "../../clients/DocumentsClient";
import DocumentLink from "./DocumentLink";
import DocumentTableDataCell from "./DocumentTableDataCell";
import PartialLink from "./PartialLink";
import ResetSignatureData from "./ResetSignatureData";

class DocumentLinks extends Component {
  render() {
    const { doc, withLinksToDelete, backgroundLoading } = this.props;

    return (
      <DocumentTableDataCell header="Documents">
        {isEmpty(doc.links) && doc.completedOffline && "Paper copy on file"}
        {!isEmpty(doc.links) && (
          <ul>
            {doc.links.map(link => (
              <li key={link.url}>
                <DocumentLink {...{ doc, link, withLinksToDelete }} />
              </li>
            ))}
          </ul>
        )}
        <PartialLink {...{ doc, backgroundLoading }} />
        {doc.canResetSignatureData && (
          <DocumentsClientContext.Consumer>
            {client => (
              <div>
                <ResetSignatureData {...{ doc, backgroundLoading, client }} />
              </div>
            )}
          </DocumentsClientContext.Consumer>
        )}
      </DocumentTableDataCell>
    );
  }
}

DocumentLinks.propTypes = {
  doc: PropTypes.object.isRequired,
  backgroundLoading: PropTypes.bool.isRequired,
  withLinksToDelete: PropTypes.bool,
};

export default DocumentLinks;
