import { Flex } from "@heart/components";
import classNames from "classnames";
import { PropTypes } from "prop-types";

import ProgressArc from "@components/ProgressArc";

import styles from "./SidebarSectionTitle.module.scss";

const SidebarSectionTitle = ({ progress, title, active }) => (
  <Flex
    align="start"
    gap="100"
    className={classNames([styles.container, active ? styles.active : null])}
  >
    <ProgressArc progress={progress} />
    {title}
  </Flex>
);

SidebarSectionTitle.propTypes = {
  progress: PropTypes.number,
  title: PropTypes.string,
  active: PropTypes.boolean,
};

export default SidebarSectionTitle;
