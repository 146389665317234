import { Flex, Text, Button } from "@heart/components";
import PropTypes from "prop-types";

const ShimUserLandingPage = ({ shimUser, usersData }) => (
  <Flex column justify="center" align="center">
    <Text textStyle="body-200">Welcome {shimUser.name}</Text>
    <Text textStyle="body-200">{shimUser.email}</Text>
    <Text textStyle="body-100">Please select an option</Text>
    <Flex row>
      {usersData.map(data => (
        <Button
          key={data.user.id}
          description={data.description}
          href={data.link}
          type="submit"
          variant="secondary"
        >
          {data.description}
        </Button>
      ))}
    </Flex>
  </Flex>
);

ShimUserLandingPage.propTypes = {
  shimUser: PropTypes.object,
  usersData: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.object,
      link: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default ShimUserLandingPage;
