import { Button, ContainsTrustedHTML } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import preventDefault from "@lib/preventDefault";

import { REACT_FORM_LOADED } from "../../legacy/events";
import styles from "./Block.module.scss";
import Question from "./Question";

const Block = ({
  questions,
  title,
  hideBorder,
  description,
  onRemoveClick,
  canRemove,
  shouldComparePreviousAnswers,
  shouldPrePopulateFromPrevious,
  shouldTriggerFormLoadedEvent,
  disabled = false,
  renderErrors = false,
}) => {
  const [wasTriggered, setWasTriggered] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (shouldTriggerFormLoadedEvent && wrapperRef.current && !wasTriggered) {
      // we have a legacy jQuery dirty checker that we can't really remove
      // yet because it's used with backend rendered ActiveAdmin forms as well
      // as React ones. Because there might be a race condition between when
      // it hooks into a form and React rendering it, we send it a signal
      // when we're done to make sure it gets the message to hook after we're
      // ready.
      setWasTriggered(true);
      $(wrapperRef.current)
        .closest(".js-check-dirty-and-warn")
        .trigger(REACT_FORM_LOADED);
    }
  });

  const handleRemoveClick = preventDefault(() => {
    if (onRemoveClick) onRemoveClick();
  });

  return (
    <div
      className={classNames({
        "question-block test-question-block panel panel-default":
          hideBorder !== true,
      })}
      ref={wrapperRef}
    >
      <If condition={title}>
        <div className="panel-heading">
          <div className="panel-title test-panel-title">
            {/** This is probably already escaped if it's coming from a Question, but */}
            {/** this is rendered directly from AA as well, so we escape it again to be sure */}
            <ContainsTrustedHTML html={title} trustedSource="TAM config" />
          </div>
        </div>
      </If>
      <If condition={description}>
        <div className="description">
          <ContainsTrustedHTML html={description} trustedSource="TAM config" />
        </div>
      </If>
      <div className={classNames(styles.questionsContainer, "panel-body")}>
        {questions.map(question => (
          <Question
            key={question.id}
            {...question}
            shouldPrePopulateFromPrevious={shouldPrePopulateFromPrevious}
            shouldComparePreviousAnswers={shouldComparePreviousAnswers}
            disabled={disabled}
            renderErrors={renderErrors}
          />
        ))}

        <If condition={canRemove}>
          <div className="pull-right">
            <Button onClick={handleRemoveClick} disabled={disabled}>
              {I18n.t("views.questions.block.remove")}
            </Button>
          </div>
        </If>
      </div>
    </div>
  );
};

Block.propTypes = {
  questions: PropTypes.array.isRequired,
  title: PropTypes.node,
  description: PropTypes.node,
  hideBorder: PropTypes.bool,
  onRemoveClick: PropTypes.func,
  canRemove: PropTypes.bool,
  shouldPrePopulateFromPrevious: PropTypes.bool,
  shouldComparePreviousAnswers: PropTypes.bool,
  shouldTriggerFormLoadedEvent: PropTypes.bool,
  disabled: PropTypes.bool,
  renderErrors: PropTypes.bool,
};

export default Block;
