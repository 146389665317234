import { useMutation, useQuery } from "@apollo/client";
import {
  Actions,
  Flex,
  InputCheckbox,
  InputDate,
  InputFilterable,
  InputPhone,
  InputSSN,
  InputText,
  Layout,
  LoadingOverlay,
  SurfaceForm,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import { isEmpty, isEqual, omit, pickBy, startCase } from "lodash";
import PropTypes from "prop-types";
import { familyFindingClearPersonSearchPath } from "routes";

import { translationWithRoot } from "@components/T";

import CreateClearPersonSearch from "@graphql/mutations/CreateClearPersonSearch.graphql";
import ClearPersonSearch from "@graphql/queries/ClearPersonSearch.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const { T } = translationWithRoot("family_finding.potential_kin_search");

const { T: CommonT } = translationWithRoot("views.common", {
  escapeJavascriptRoot: true,
});

const SearchForm = ({ usStateOptions, editingSearchId }) => {
  const { data: search, loading: queryLoading } = useQuery(ClearPersonSearch, {
    variables: { id: editingSearchId },
    skip: !editingSearchId,
  });

  const searchFields = search?.clearPersonSearch?.searchFields;
  const { formState, setFormAttribute, setFormState } =
    useBintiForm(searchFields);

  const [createSearch, { loading: mutationLoading }] = useMutation(
    CreateClearPersonSearch
  );

  const states = Object.entries(usStateOptions).map(([short, long]) => ({
    label: startCase(long),
    value: short.toUpperCase(),
  }));

  const stateWithLabel = state =>
    state ? { value: state, label: usStateOptions[state] } : null;

  const clearAll = () => setFormState({});

  const onSubmit = () => {
    // if the new search has the same fields as the search being edited,
    // just redirect to the results for that search
    if (isEqual(pickBy(formState), pickBy(searchFields))) {
      window.location = familyFindingClearPersonSearchPath(editingSearchId);
    } else {
      createSearch({
        variables: { criteria: omit(formState, "__typename") },
      }).then(({ data }) => {
        window.location = familyFindingClearPersonSearchPath(
          data.createClearPersonSearch.search.id
        );
      });
    }
  };

  const submitDisabled = isEmpty(pickBy(omit(formState, "__typename")));

  return (
    <LoadingOverlay active={queryLoading}>
      <Layout
        pageTitle={<T t="search_form.title" />}
        subtitle={<T t="search_form.subtitle" />}
        main={{
          content: (
            <SurfaceForm
              title={<T t="search_form.search_criteria" />}
              actions={
                <Actions
                  cancelAction={clearAll}
                  cancelText={<T t="search_form.clear_all" />}
                  primaryText={<T t="search_form.search" />}
                  primaryDisabled={submitDisabled}
                  isSubmitting={mutationLoading}
                />
              }
              onSubmit={preventDefault(onSubmit)}
            >
              <Flex row mobileColumn fullWidth>
                <InputText
                  label={<CommonT t="first_name" />}
                  value={formState.firstName}
                  onChange={setFormAttribute("firstName")}
                  fullWidth
                />
                <InputText
                  label={<CommonT t="middle_name" />}
                  value={formState.middleName}
                  onChange={setFormAttribute("middleName")}
                  fullWidth
                />
                <InputText
                  label={<CommonT t="last_name" />}
                  value={formState.lastName}
                  onChange={setFormAttribute("lastName")}
                  fullWidth
                />
              </Flex>
              <InputCheckbox
                label={<T t="search_form.flexible_search" />}
                value={formState.flexibleSearch}
                onChange={setFormAttribute("flexibleSearch")}
              />
              <Flex row mobileColumn fullWidth>
                <InputDate
                  label={<CommonT t="date_of_birth" />}
                  value={formState.dateOfBirth}
                  onChange={setFormAttribute("dateOfBirth")}
                />
                <InputSSN
                  label={<T t="common.ssn" />}
                  value={formState.ssn}
                  onChange={setFormAttribute("ssn")}
                />
                <InputText
                  label={<T t="common.drivers_license" />}
                  value={formState.driversLicenseNumber}
                  onChange={setFormAttribute("driversLicenseNumber")}
                  fullWidth
                />
                <InputText
                  label={<T t="common.entity_id" />}
                  value={formState.entityId}
                  onChange={setFormAttribute("entityId")}
                  fullWidth
                />
              </Flex>
              <Flex row mobileColumn fullWidth>
                <Flex row>
                  <InputText
                    label={<T t="common.age_range_min" />}
                    type="number"
                    value={formState.ageRangeMin}
                    onChange={setFormAttribute("ageRangeMin")}
                  />
                  <InputText
                    label={<T t="common.age_range_max" />}
                    type="number"
                    value={formState.ageRangeMax}
                    onChange={setFormAttribute("ageRangeMax")}
                  />
                </Flex>
                <InputPhone
                  label={<CommonT t="phone_number" />}
                  value={formState.phoneNumber}
                  onChange={setFormAttribute("phoneNumber")}
                />
                <InputText
                  label={<CommonT t="email_address" />}
                  value={formState.email}
                  type="email"
                  onChange={setFormAttribute("email")}
                  fullWidth
                />
              </Flex>
              <Flex row mobileColumn fullWidth>
                <InputText
                  label={<T t="common.address_line_1" />}
                  value={formState.addressLine1}
                  onChange={setFormAttribute("addressLine1")}
                  fullWidth
                />
                <InputText
                  label={<T t="common.address_line_2" />}
                  value={formState.addressLine2}
                  onChange={setFormAttribute("addressLine2")}
                  fullWidth
                />
                <InputText
                  label={<T t="common.city" />}
                  value={formState.city}
                  onChange={setFormAttribute("city")}
                  fullWidth
                />
              </Flex>
              <Flex row mobileColumn fullWidth>
                <InputFilterable
                  label={<T t="common.state" />}
                  value={stateWithLabel(formState.state)}
                  values={states}
                  onChange={({ value }) => setFormAttribute("state")(value)}
                  fullWidth
                />
                <InputText
                  label={<T t="common.zip" />}
                  value={formState.zip}
                  onChange={setFormAttribute("zip")}
                  fullWidth
                />
              </Flex>
            </SurfaceForm>
          ),
        }}
      />
    </LoadingOverlay>
  );
};

SearchForm.propTypes = {
  editingSearchId: BintiPropTypes.ID,
  usStateOptions: PropTypes.object.isRequired,
};

export default SearchForm;
