import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import {
  Breadcrumbs,
  InputRadioGroup,
  InputDate,
  LoadingOverlay,
  Surface,
  Text,
  Wizard,
  WizardFieldset,
  WizardPage,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import { calculateProgress } from "@heart/components/wizard/Wizard";
import I18n from "i18n-js";
import { curry, isEqual, isNil, omitBy } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  adminChildrenPath,
  familyFindingSearchesPath,
  newAdminChildPath,
  rootPath,
} from "routes";

import { translationWithRoot } from "@components/T";
import BaseRelationshipToChildSection from "@components/family_finding/relationships/BaseRelationshipToChildSection";
import CopyRelationshipDetailsButton from "@components/family_finding/relationships/CopyRelationshipDetailsButton";
import {
  generateEmptyRelationshipForId,
  parseRelationshipForUI,
  relationshipForDB,
} from "@components/family_finding/relationships/relationshipConversion";
import { copySubsetOfRelationshipDetails } from "@components/family_finding/relationships/transitionRelationshipState";

import CreateFamilyFindingSearch from "@graphql/mutations/CreateFamilyFindingSearch.graphql";
import CreateOrUpdateRelationship from "@graphql/mutations/CreateOrUpdateRelationship.graphql";
import UpdateFamilyFindingSearch from "@graphql/mutations/UpdateFamilyFindingSearch.graphql";
import AgencyHumanAutocompleteForSearches from "@graphql/queries/AgencyHumanAutocompleteForSearches.graphql";
import AgencyHumanDetails from "@graphql/queries/AgencyHumanDetails.graphql";
import AgencyHumanRelationshipsForGroup from "@graphql/queries/AgencyHumanRelationshipsForGroup.graphql";
import AgencyWorkerAutocomplete from "@graphql/queries/AgencyWorkerAutocomplete.graphql";
import FamilyFindingSearch from "@graphql/queries/FamilyFindingSearch.graphql";

import generateId from "@lib/generateId";
import useLocation from "@lib/react-use/useLocation";
import { getSearchParamForAttribute, setSearchParams } from "@lib/searchParams";

import { RELATIVE, SIBLING } from "@root/constants";

import createNameAndDOBLabel from "../common/createNameAndDOBLabel";

const { t, T } = translationWithRoot("family_finding.search_form");

/** Create a key of the agency human ids for a relationship, where the larger id
 * always comes frist. We need to rely on agency human ids rather than the relationship
 * id in case there is no existing relationship between two children.
 */
const determineRelationshipKey = ({ id: idA }, { id: idB }) =>
  idA > idB ? `${idA}-${idB}` : `${idB}-${idA}`;

export const SearchForm = ({ originPath, searchId }) => {
  const location = useLocation();
  const isCreating = !searchId;
  const { formState, setFormAttribute, setFormState } = useBintiForm(null, {
    initialValue: {
      children: [],
    },
  });

  const [childAHIds, setChildAHIds] = useState("");
  const [childrenWithOpenSearch, setChildrenWithOpenSearch] = useState([]);

  const [createSearch] = useMutation(CreateFamilyFindingSearch);
  const [updateSearch] = useMutation(UpdateFamilyFindingSearch);
  const [createOrUpdateRelationship] = useMutation(CreateOrUpdateRelationship);

  const { loading } = useQuery(FamilyFindingSearch, {
    variables: { id: searchId },
    skip: isCreating,
    onCompleted: ({
      familyFindingSearch: {
        agencyHuman: { id: agencyHumanId, fullName, dateOfBirth },
        endDate,
        startDate,
        assignedWorkers,
      },
    }) => {
      setFormState({
        children: [
          {
            value: agencyHumanId,
            label: createNameAndDOBLabel(fullName, dateOfBirth),
          },
        ],
        startDate,
        endDate,
        workers: assignedWorkers.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      });
    },
  });

  const [
    loadAgencyHumanDetails,
    { data: agencyHumanData, loading: agencyHumanDataLoading },
  ] = useLazyQuery(AgencyHumanDetails);

  /** We're using query parameters to keep track of the selected children's
   * agency human ids in order to enable the flow allowing a redirect from
   * here, through intake child, and back with the children pre-populated
   */
  useEffect(() => {
    const AHIds = decodeURI(
      getSearchParamForAttribute({ attribute: "childAHIds" }) || ""
    );
    setChildAHIds(AHIds);

    if (isCreating)
      loadAgencyHumanDetails({
        variables: {
          ids: AHIds.split(",").filter(Boolean),
        },
      });
  }, [isCreating, loadAgencyHumanDetails, location.search]);
  useEffect(() => {
    if (agencyHumanData) {
      const newChildren = agencyHumanData.agencyHumanDetails.map(
        ({ id, firstName, fullName, dateOfBirth, familyFindingSearches }) => ({
          firstName,
          label: createNameAndDOBLabel(fullName, dateOfBirth),
          hasOpenSearch: familyFindingSearches.length > 0,
          value: id,
        })
      );
      setFormAttribute("children")(newChildren);
      setChildrenWithOpenSearch(newChildren.filter(c => c.hasOpenSearch));
    }
  }, [agencyHumanData, setFormAttribute]);

  const humansFromResponse = response => [
    ...(response?.agencyHumanMatches || []).map(
      ({ id, fullName, dateOfBirth, familyFindingSearches }) => ({
        label: createNameAndDOBLabel(fullName, dateOfBirth),
        value: id,
        hasOpenSearch: familyFindingSearches.length > 0,
      })
    ),
    { label: I18n.t("admin.header_links.intake_child"), value: "intake_child" },
  ];

  const workersFromResponse = response =>
    (response?.agencyWorkerMatches || []).map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  const onChildrenChange = newChildren => {
    /** As this onChange is called with the whole list of selected
     * values, we have to filter through the list to determine whether
     * the update to the list is the option to create a new child.
     *
     * Theoretically we should only ever be redirecting to intake child
     * or adding a new child agency human id to the search params, not
     * both in the same call to this function
     */
    const existingChildren = newChildren.filter(
      ({ value }) => value !== "intake_child"
    );
    setSearchParams({
      attribute: "childAHIds",
      value: existingChildren.map(({ value }) => value),
    });

    if (existingChildren.length !== newChildren.length)
      window.location.assign(
        `${window.location.origin}${newAdminChildPath({
          child_ah_ids: childAHIds,
          family_finding_intake: true,
        })}`
      );

    setChildrenWithOpenSearch(newChildren.filter(c => c.hasOpenSearch));
  };

  const formatNames = children => {
    const labels = children.map(c => c.label);
    if (labels.length === 1) {
      return labels[0];
    }
    if (labels.length === 2) {
      return labels.join(" and ");
    }

    return [labels.slice(0, -1).join(", "), labels[labels.length - 1]].join(
      ", and "
    );
  };

  const { data: relationshipsData } = useQuery(
    AgencyHumanRelationshipsForGroup,
    {
      variables: {
        agencyHumanIds: formState.children.map(({ value }) => value),
      },
    }
  );

  useEffect(() => {
    (relationshipsData?.agencyHumanRelationshipsForGroup || []).forEach(
      relationship => {
        const { destinationAgencyHuman, sourceAgencyHuman } = relationship;
        setFormAttribute(
          determineRelationshipKey(destinationAgencyHuman, sourceAgencyHuman)
        )({
          ...parseRelationshipForUI(relationship),
          areSiblings:
            relationship.kinshipRelationship === SIBLING ? "yes" : undefined,
        });
      }
    );
  }, [relationshipsData, setFormAttribute]);

  const selectChildren = () => (
    <WizardPage
      key="child_information"
      pageTitle={t("child_information")}
      progress={formState.children.length > 0 ? 100 : 0}
      actionsProps={{
        cancelHref: originPath,
        primaryAction: () => {},
        primaryText: t("next"),
        primaryDisabled: formState.children.length === 0,
      }}
    >
      <If condition={isCreating}>
        <WizardFieldset
          sectionTitle={t("select_children")}
          loading={agencyHumanDataLoading}
        >
          <InputAutocompleteGraphQL
            required
            label={t("children")}
            description={t("children_description")}
            query={AgencyHumanAutocompleteForSearches}
            queryVariables={{ filterForChildren: true }}
            valuesFromResponse={humansFromResponse}
            onChange={onChildrenChange}
            value={formState.children}
            isMulti
          />
          <If condition={childrenWithOpenSearch.length > 0}>
            <Text textColor="danger-700">
              <T
                t="existing_open_searches"
                names={formatNames(childrenWithOpenSearch)}
                count={childrenWithOpenSearch.length}
              />
            </Text>
          </If>
        </WizardFieldset>
      </If>
      <If condition={!isCreating}>
        <Surface title={t("selected_child")}>
          {formState.children.map(({ value, label }) => (
            <Text key={value}>{label}</Text>
          ))}
        </Surface>
      </If>
    </WizardPage>
  );

  const relationshipKeys = formState.children
    .map((child, index) =>
      formState.children.slice(index + 1).map(sibling => ({
        key: determineRelationshipKey(
          { id: child.value },
          { id: sibling.value }
        ),
        child,
        sibling,
      }))
    )
    .flat();

  const setRelationshipAttribute = key =>
    curry((attribute, value) => {
      if (!isNil(value) && !isEqual(value, formState[key][attribute])) {
        setFormAttribute(key)({ ...formState[key], [attribute]: value });
      }
    });

  const siblingRelationships = () =>
    formState.children.length > 1 ? (
      <WizardPage
        key="sibling_information"
        pageTitle={t("add_sibling_relationships")}
        progress={calculateProgress({
          requiredFields: relationshipKeys.map(
            ({ key }) => formState[key]?.areSiblings
          ),
        })}
        confirmBeforeCancel
        actionsProps={{
          cancelAction: () => {},
          primaryAction: async () => {
            await Promise.all(
              relationshipKeys.map(({ key, child, sibling }) =>
                formState[key]?.areSiblings === "yes"
                  ? createOrUpdateRelationship({
                      variables: relationshipForDB({
                        /** If no preexisting relationship between two agency humans exists, and there
                         * are no changes made to the relationship details for those two agency humans,
                         * the form data for that relationship will not have the required ids to successfully
                         * save an empty relationship. If that isn't the case, the information generated
                         * will be overwritten by the information held in formState
                         */
                        ...generateEmptyRelationshipForId(
                          child.value,
                          sibling.value
                        ),
                        relationshipCategory: { value: RELATIVE },
                        kinshipRelationship: { value: SIBLING },
                        ...omitBy(formState[key], isNil),
                      }),
                    })
                  : Promise.resolve
              )
            );
          },
          primaryText: t("save"),
          primarySubmittingText: t("saving"),
        }}
      >
        {relationshipKeys.map(({ key, child, sibling }) => (
          <WizardFieldset
            key={key}
            collapsible
            sectionTitle={t("relationship", {
              childName: child.label,
              siblingName: sibling.label,
            })}
            secondary={
              <CopyRelationshipDetailsButton
                copyToAgencyHumanId={key}
                copyFromAgencyHumanId={relationshipKeys[0].key}
                copyFromName={t("child_and_sibling", {
                  childName: relationshipKeys[0].child.firstName,
                  siblingName: relationshipKeys[0].sibling.firstName,
                })}
                formState={formState}
                defaultRelationshipState={generateEmptyRelationshipForId(
                  child.id,
                  sibling.id
                )}
                setFormAttribute={setFormAttribute(key)}
                copyRelationshipDetailsFunction={copySubsetOfRelationshipDetails(
                  {
                    fieldsToCopy: [
                      "areSiblings",
                      "relationshipCategory",
                      "kinshipRelationship",
                      "kinshipRelationshipOtherDescription",
                      "parentalLine",
                      "lineageType",
                      "emotionalRelationshipStatuses",
                    ],
                  }
                )}
              />
            }
          >
            <InputRadioGroup
              /** We're using a dynamically generated key to solve a bug where the
               * checkboxes would appear unchecked after the "Are you sure?" modal
               * was cancelled. There may be other solutions to this, but they'll
               * likely require a more intentional pass over our Modal/Alert components
               * TODO: https://binti.atlassian.net/browse/ENG-15612
               */
              key={generateId()}
              label={t("are_children_siblings", {
                childName: child.label,
                siblingName: sibling.label,
              })}
              onChange={e =>
                setFormAttribute(key)({
                  ...formState[key],
                  areSiblings: e,
                  /** We don't save the relationship if areSiblings isn't "Yes", so
                   * we can set the relationship attributes regardless
                   *
                   * In the case of an existing, non-sibling relationship, we want to
                   * clear out the existing fields that will be displayed in the form,
                   * and set the relationshipCategory and kinshipRelationship fields
                   * to indicate that the children are siblings
                   */
                  relationshipCategory: { value: RELATIVE },
                  kinshipRelationship: { value: SIBLING },
                  kinshipRelationshipOtherDescription: null,
                  parentalLine: [],
                  lineageType: null,
                  emotionalRelationshipStatuses: [],
                  fictiveKinDescription: null,
                })
              }
              value={formState[key]?.areSiblings}
              values={[
                {
                  label: I18n.t("javascript.components.common.yes"),
                  value: "yes",
                },
                {
                  label: I18n.t("javascript.components.common.no"),
                  value: "no",
                },
              ]}
            />
            <If condition={formState[key]?.areSiblings === "yes"}>
              <BaseRelationshipToChildSection
                relationship={{
                  ...formState[key],
                  relationshipCategory: { value: RELATIVE },
                }}
                setRelationshipAttribute={setRelationshipAttribute(key)}
              />
            </If>
          </WizardFieldset>
        ))}
      </WizardPage>
    ) : null;

  const searchInformation = () => (
    <WizardPage
      key="search_information"
      pageTitle={t("family_finding_search_information")}
      progress={formState.startDate ? 100 : 0}
      actionsProps={{
        cancelHref: originPath,
        primaryAction: async () => {
          if (isCreating) {
            await createSearch({
              variables: {
                childAgencyHumanIds: formState.children.map(
                  ({ value }) => value
                ),
                startDate: formState.startDate,
                assignedWorkerIds: formState.workers.map(({ value }) => value),
              },
            });

            window.location = originPath;
          } else {
            await updateSearch({
              variables: {
                searchId,
                startDate: formState.startDate,
                endDate: formState.endDate,
                assignedWorkerIds: formState.workers.map(({ value }) => value),
              },
            });

            // if the search is closed out, redirect to the closed searches page
            if (formState.endDate) {
              window.location = `${familyFindingSearchesPath()}?tab=Closed`;
            } else {
              window.location = originPath;
            }
          }
        },
        primaryDisabled:
          formState.children.length === 0 || !formState.startDate,
      }}
    >
      <WizardFieldset sectionTitle={t("search_information")}>
        <InputDate
          required
          label={t("start_date")}
          onChange={setFormAttribute("startDate")}
          value={formState.startDate}
          maxDate={new Date()}
        />
        <If condition={!isCreating}>
          <InputDate
            label={t("end_date")}
            onChange={setFormAttribute("endDate")}
            value={formState.endDate}
            maxDate={new Date()}
          />
        </If>
        <InputAutocompleteGraphQL
          required
          label={t("workers_assigned")}
          query={AgencyWorkerAutocomplete}
          queryVariables={{
            permissionsFilter: {
              resource: "family_finding_search",
              action: "be_assigned",
            },
            withoutPartnerAgencyWorkers: true,
          }}
          valuesFromResponse={workersFromResponse}
          onChange={setFormAttribute("workers")}
          value={formState.workers}
          isMulti
        />
      </WizardFieldset>
    </WizardPage>
  );

  const title = isCreating ? t("create_search") : t("edit_search");

  return (
    <LoadingOverlay active={!isCreating && loading}>
      <Wizard
        title={title}
        breadcrumbs={
          <Breadcrumbs
            pages={[
              { href: rootPath(), label: t("home") },
              { href: adminChildrenPath(), label: t("children_youth") },
              {
                href: familyFindingSearchesPath(),
                label: t("family_finding_searches"),
              },
              { href: "#", label: title },
            ]}
          />
        }
        pages={[
          selectChildren(),
          siblingRelationships(),
          searchInformation(),
        ].filter(Boolean)}
      />
    </LoadingOverlay>
  );
};

SearchForm.propTypes = {
  originPath: PropTypes.string.isRequired,
  searchId: PropTypes.string,
};

export default SearchForm;
