import { Button, InputDate, InputTextarea } from "@heart/components";
import inputstyles from "@heart/components/inputs/Input.module.scss";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import generateId from "@lib/generateId";

const REASONS = Object.freeze([
  "not_applicable",
  "tracked_outside_of_binti",
  "fulfilled_by_other_means",
  "exception_granted",
]);

const TRAINING_HOUR_OPTIONS = Object.freeze(
  [...Array(160).keys()].map(i => (i + 1) * 0.25)
); // [0.25, 0.5, 0.75, 1, 1.25, 1.5, ... 40]

const CreateOrEditTrainingWaiverForm = ({
  initialReason,
  initialDetails,
  initialGrantDate,
  initialTrainingHours,
  canEnterTrainingHours,
}) => {
  const [reason, setReason] = useState(initialReason);
  const [details, setDetails] = useState(initialDetails);
  const [grantDate, setGrantDate] = useState(initialGrantDate);
  const [trainingHours, setTrainingHours] = useState(initialTrainingHours);
  const disableSubmit =
    reason.length === 0 || details.length === 0 || grantDate.length === 0;

  const reasonId = generateId();
  const textareaId = generateId();
  const trainingHoursId = generateId();

  return (
    <div className="contains-inputs">
      <div className="form-group">
        <label className={inputstyles.requiredLabel} htmlFor={reasonId}>
          Reason Waived
        </label>
        <select
          id={reasonId}
          name="override_record[reason]"
          value={reason}
          required={true}
          onChange={({ target }) => setReason(target.value)}
        >
          <option value="" disabled></option>
          {REASONS.map(reasonKey => (
            <option key={reasonKey} value={reasonKey}>
              {I18n.t(
                `javascript.components.training.create_training_override_form.${reasonKey}`
              )}
            </option>
          ))}
        </select>
        {reason.length > 0 && (
          <p>
            <T
              t={`training.create_training_override_form.${reason}_description`}
            />
          </p>
        )}
      </div>
      {/* Commenting this out for now because we do not yet support expiration dates
      {reason === "exception_granted" && (
        <InputDate
          label={I18n.t(
            "javascript.components.training.create_training_override_form.expiration_date"
          )}
          name="override_record[name]"
        />
      )} */}
      <InputTextarea
        label="Details"
        required
        id={textareaId}
        name="override_record[details]"
        value={details}
        onChange={setDetails}
      />
      {canEnterTrainingHours && (
        <div className="form-group">
          <label
            className={inputstyles.requiredLabel}
            htmlFor={trainingHoursId}
          >
            Number of hours to waive
          </label>
          <select
            id={trainingHoursId}
            name="override_record[hours]"
            value={trainingHours}
            onChange={({ target }) => setTrainingHours(target.value)}
          >
            {TRAINING_HOUR_OPTIONS.map(hour => (
              <option key={hour} value={hour}>
                {hour}
              </option>
            ))}
          </select>
        </div>
      )}
      <InputDate
        label={I18n.t(
          "javascript.components.training.create_training_override_form.date_training_waiver_granted"
        )}
        name="override_record[grant_date]"
        value={grantDate}
        onBlur={setGrantDate}
        required={true}
      />
      <Button type="submit" disabled={disableSubmit}>
        Waive Training Requirement
      </Button>
    </div>
  );
};

CreateOrEditTrainingWaiverForm.propTypes = {
  initialReason: PropTypes.string,
  initialDetails: PropTypes.string,
  initialGrantDate: PropTypes.string,
  initialTrainingHours: PropTypes.string,
  canEnterTrainingHours: PropTypes.bool,
};

CreateOrEditTrainingWaiverForm.defaultProps = {
  initialReason: "",
  initialDetails: "",
  initialGrantDate: "",
  initialTrainingHours: "0.5",
  canEnterTrainingHours: false,
};

export default CreateOrEditTrainingWaiverForm;
