import { Link, Pill, Text } from "@heart/components";
import Icon from "@heart/components/icon/Icon";
import { Table, Tbody, Td, Th, Thead, Tr } from "@heart/components/table/Table";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";
import { useCallback, useEffect } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("placement_search.workers_assigned");

const emptyArray = [];

/** View and edit assigned workers for a given placement search.
 *    Placement Searches > Row Action > Edit Placements Search
 */
const WorkersAssignedForm = ({ formState, onChange }) => {
  const { agencyWorkers = emptyArray, primaryWorkerAssignedId } =
    formState || {};

  const setPrimaryWorkerAssignedId = useCallback(
    id =>
      onChange({
        agencyWorkers,
        primaryWorkerAssignedId: id,
      }),
    [onChange, agencyWorkers]
  );

  const unassignWorker = workerId =>
    onChange({
      agencyWorkers: agencyWorkers.filter(({ id }) => id !== workerId),
      primaryWorkerAssignedId,
    });

  const tableStyles = {
    verticalAlign: "middle",
  };

  useEffect(() => {
    if (isEmpty(agencyWorkers) && primaryWorkerAssignedId) {
      setPrimaryWorkerAssignedId(null);
    }

    if (
      (agencyWorkers.length === 1 &&
        primaryWorkerAssignedId !== agencyWorkers[0].id) ||
      (!isEmpty(agencyWorkers) && !primaryWorkerAssignedId)
    ) {
      setPrimaryWorkerAssignedId(agencyWorkers[0].id);
    }
  }, [agencyWorkers, primaryWorkerAssignedId, setPrimaryWorkerAssignedId]);

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>{I18n.t("admin.common.name")}</Th>
          <Th>{I18n.t("common.status")}</Th>
          <Th>{I18n.t("admin.common.actions")}</Th>
        </Tr>
      </Thead>
      <Tbody aria-live="polite">
        <Choose>
          <When condition={!isEmpty(agencyWorkers)}>
            {agencyWorkers.map(({ id, name }) => (
              <Tr key={id}>
                <Td style={tableStyles}>
                  <Text>{name}</Text>
                </Td>
                <Td style={tableStyles}>
                  {agencyWorkers.length > 1 &&
                    (formState.primaryWorkerAssignedId === id ? (
                      <Pill text={I18n.t("common.primary")} variant="info" />
                    ) : (
                      <Link onClick={() => setPrimaryWorkerAssignedId(id)}>
                        {t("primary_button")}
                      </Link>
                    ))}
                </Td>
                <Td style={tableStyles}>
                  <Icon
                    icon="trash"
                    description={`${t("unassign")} ${name}`}
                    disabled={
                      agencyWorkers.length > 1 && primaryWorkerAssignedId === id
                    }
                    onClick={() => unassignWorker(id)}
                  />
                </Td>
              </Tr>
            ))}
          </When>
          <Otherwise>
            <Tr>
              <Td colSpan={3} style={{ textAlign: "left" }}>
                {t("no_workers_assigned")}
              </Td>
            </Tr>
          </Otherwise>
        </Choose>
      </Tbody>
    </Table>
  );
};

WorkersAssignedForm.propTypes = {
  formState: PropTypes.object,
  onChange: PropTypes.func,
};

export default WorkersAssignedForm;
