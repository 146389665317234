import { useMutation, useQuery } from "@apollo/client";
import {
  Flex,
  InputCheckbox,
  InputCheckboxGroup,
  InputRadioGroup,
  Link,
  Modal,
  Text,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import { compact, isEmpty, isEqual, isNil, omit } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";

import { translationWithRoot } from "@components/T";

import CreateClearPersonReportDocument from "@graphql/mutations/CreateClearPersonReportDocument.graphql";
import CreateOrUpdateAgencyHuman from "@graphql/mutations/CreateOrUpdateAgencyHuman.graphql";
import AgencyHumanAutocompleteQuery from "@graphql/queries/AgencyHumanAutocomplete.graphql";
import PrimarySubdivisionsQuery from "@graphql/queries/PrimarySubdivisions.graphql";

import createNameAndDOBLabel from "../common/createNameAndDOBLabel";
import {
  convertToAddressInputs,
  convertToPhoneNumberInputs,
  selectedObjectsFromCheckboxGroup,
} from "./modalConversions";

const { t } = translationWithRoot(
  "family_finding.potential_kin_search.potential_kin"
);
const { t: selectAllT } = translationWithRoot(
  "heart.components.inputs.input_checkbox_group"
);

const formatAddress = ({ addressLine1, city, state, zip }) => {
  const prelude = [addressLine1, city, state]
    .filter(part => !isEmpty(part))
    .join(", ");
  return isEmpty(zip) ? prelude : `${prelude} ${zip}`;
};

const LinkPotentialConnectionModal = ({
  childAgencyHumans,
  setChildAgencyHumansForSuccessModal,
  searchData = {},
  socialMediaLinks,
  riskFlags,
  hidden,
  setModalTypeVisibility,
  onCancel,
  comprehensiveReportLoaded,
  comprehensiveReportId,
}) => {
  const [dobEnabled, setDobEnabled] = useState(true);
  const [ssnEnabled, setSsnEnabled] = useState(true);

  const { data: primarySubdivisionsData = {} } = useQuery(
    PrimarySubdivisionsQuery,
    {
      variables: { countryCode: "US" },
    }
  );
  const { primarySubdivisions = [] } = primarySubdivisionsData;

  const humansFromResponse = response => [
    ...(response?.agencyHumanMatches || []).map(
      ({ id, childId, fullName, dateOfBirth, agencyId }) => ({
        label: createNameAndDOBLabel(fullName, dateOfBirth),
        value: id,
        agencyId,
        childId,
      })
    ),
  ];

  /** As we're threading through info, we should figure out whether this is
   * really a single string or a list - should probably be a list?
   */
  const { addresses, phoneNumbers, dateOfBirth = "", ssn = "" } = searchData;
  const formattedAddresses = useMemo(
    () =>
      addresses
        ?.map((addr, idx) => ({
          label: formatAddress(addr),
          value: `searchData_addresses_${idx}`,
          valid: addr.valid,
        }))
        // Remove invalid addresses from the list of selectable options
        ?.filter(({ valid }) => valid) || [],
    [addresses]
  );
  const formattedPhoneNumbers = useMemo(
    () =>
      phoneNumbers
        ?.map((phone, idx) => ({
          label: phone.phoneNumber,
          value: `searchData_phoneNumbers_${idx}`,
          valid: phone.valid,
        }))
        // Remove invalid phone numbers from the list of selectable options
        ?.filter(({ valid }) => valid) || [],
    [phoneNumbers]
  );

  const allFieldsSelected = useMemo(
    () => ({
      firstName: true,
      middleName: true,
      lastName: true,
      dateOfBirth: searchData.dateOfBirth,
      ssn: searchData.ssn,
      addresses: formattedAddresses,
      phoneNumbers: formattedPhoneNumbers,
      emailAddresses: searchData.emailAddresses,
      socialMediaLinks: socialMediaLinks,
      riskFlags: !isEmpty(riskFlags),
    }),
    [
      searchData.dateOfBirth,
      searchData.ssn,
      formattedPhoneNumbers,
      searchData.emailAddresses,
      formattedAddresses,
      socialMediaLinks,
      riskFlags,
    ]
  );

  const { formState, setFormState, setFormAttribute } = useBintiForm(
    useMemo(
      () => ({
        childAgencyHumans: humansFromResponse({
          /** manipulating this data a smidge by nesting it under a key so we can
           * reuse the code to parse out options for the dropdown
           */
          agencyHumanMatches: childAgencyHumans,
        }),
        ...allFieldsSelected,
      }),
      [allFieldsSelected, childAgencyHumans]
    )
  );
  const [createOrUpdateAgencyHuman, { loading: createAHLoading }] = useMutation(
    CreateOrUpdateAgencyHuman,
    {
      onCompleted: data => {
        if (!isNil(comprehensiveReportId)) {
          createClearPersonReportDocument({
            variables: {
              clearPersonReportId: comprehensiveReportId,
              agencyHumanId: data.createOrUpdateAgencyHuman.agencyHuman.id,
            },
          });
        }
        setChildAgencyHumansForSuccessModal(formState.childAgencyHumans);
        setModalTypeVisibility("success");
      },
    }
  );

  const [createClearPersonReportDocument, { loading: createDocumentLoading }] =
    useMutation(CreateClearPersonReportDocument);

  return (
    <Modal
      asForm
      hidden={hidden}
      onCancel={onCancel}
      title={t("link_potential_kin_as_potential_connection", {
        name: searchData?.firstName,
      })}
      submitting={createAHLoading || createDocumentLoading}
      submitText={t("create_new_potential_connections")}
      onSubmit={() => {
        const selectedAddresses = selectedObjectsFromCheckboxGroup(
          formState.addresses,
          searchData?.addresses
        );

        // Convert the addresses from the API into AddressInput objects
        const addressInputs = convertToAddressInputs({
          addresses: selectedAddresses,
          agencyId: formState.childAgencyHumans[0].agencyId,
          primarySubdivisions,
        });

        const selectedPhoneNumbers = selectedObjectsFromCheckboxGroup(
          formState.phoneNumbers,
          searchData?.phoneNumbers
        );

        // Convert phone numbers into PhoneNumberInputs
        const phoneNumberInputs = convertToPhoneNumberInputs({
          phoneNumbers: selectedPhoneNumbers,
        });

        createOrUpdateAgencyHuman({
          variables: {
            agencyId: formState.childAgencyHumans[0].agencyId,
            firstName: formState.firstName ? searchData?.firstName : "",
            middleName: formState.middleName ? searchData?.middleName : "",
            lastName: formState.lastName ? searchData?.lastName : "",
            ssn: formState.ssn,
            ...(!isEmpty(formState.dateOfBirth)
              ? { dateOfBirth: formState.dateOfBirth }
              : {}),
            addresses: addressInputs || [],
            phoneNumbers: phoneNumberInputs || [],
            emailAddresses:
              formState.emailAddresses?.map(emailAddress => ({
                emailAddress,
                primary: false,
                inactive: false,
              })) || [],
            socialMediaLinks: formState.socialMediaLinks,
            riskFlags: formState.riskFlags ? riskFlags : [],
            childAgencyHumanIds: formState.childAgencyHumans.map(
              ({ value }) => value
            ),
          },
        });
      }}
    >
      <Flex column gap="200">
        <Text>{t("link_help_text")}</Text>
        <InputAutocompleteGraphQL
          required
          label={t("children")}
          query={AgencyHumanAutocompleteQuery}
          queryVariables={{ filterForChildren: true }}
          valuesFromResponse={humansFromResponse}
          onChange={setFormAttribute("childAgencyHumans")}
          value={formState.childAgencyHumans}
          isMulti
        />
        <Flex row>
          <Link
            onClick={() => {
              setFormState({
                childAgencyHumans: formState.childAgencyHumans,
                ...allFieldsSelected,
              });
              setDobEnabled(true);
              setSsnEnabled(true);
            }}
            disabled={isEqual(
              omit(formState, ["childAgencyHumans"]),
              allFieldsSelected
            )}
          >
            {selectAllT("select_all")}
          </Link>
          <Link
            onClick={() => {
              setFormState({ childAgencyHumans: formState.childAgencyHumans });
              setDobEnabled(false);
              setSsnEnabled(false);
            }}
            disabled={isEmpty(omit(formState, ["childAgencyHumans"]))}
          >
            {selectAllT("clear")}
          </Link>
        </Flex>
        <InputCheckbox
          label={t("form.first_name")}
          onChange={setFormAttribute("firstName")}
          value={formState.firstName}
        />
        <InputCheckbox
          label={t("form.middle_name")}
          onChange={setFormAttribute("middleName")}
          value={formState.middleName}
        />
        <InputCheckbox
          label={t("form.last_name")}
          onChange={setFormAttribute("lastName")}
          value={formState.lastName}
        />
        <InputRadioGroup
          hideLabel
          label={t("form.date_of_birth")}
          onChange={setFormAttribute("dateOfBirth")}
          overrideCheckbox={{
            label: t("form.date_of_birth"),
            enabled: dobEnabled,
            setEnabled: setDobEnabled,
          }}
          values={compact([dateOfBirth]).map(date => ({
            value: date,
            label: DateTime.fromISO(date, { setZone: true }).toLocaleString(),
          }))}
          value={formState.dateOfBirth}
        />
        <InputRadioGroup
          hideLabel
          label={t("form.ssn")}
          onChange={setFormAttribute("ssn")}
          overrideCheckbox={{
            label: t("form.ssn"),
            enabled: ssnEnabled,
            setEnabled: setSsnEnabled,
          }}
          values={compact([ssn])}
          value={formState.ssn}
        />
        <InputCheckboxGroup
          hideLabel
          label={t("form.addresses")}
          onChange={setFormAttribute("addresses")}
          selectAll={{ type: "checkbox", label: t("form.all_addresses") }}
          values={formattedAddresses}
          value={formState.addresses}
        />
        <InputCheckboxGroup
          hideLabel
          label={t("form.phone_numbers")}
          onChange={setFormAttribute("phoneNumbers")}
          selectAll={{ type: "checkbox", label: t("form.all_phone_numbers") }}
          values={formattedPhoneNumbers}
          value={formState.phoneNumbers}
        />
        <InputCheckboxGroup
          hideLabel
          label={t("form.email_addresses")}
          onChange={setFormAttribute("emailAddresses")}
          selectAll={{ type: "checkbox", label: t("form.all_emails") }}
          values={searchData?.emailAddresses || []}
          value={formState.emailAddresses}
        />
        <InputCheckboxGroup
          hideLabel
          label={t("form.social_media_links")}
          onChange={setFormAttribute("socialMediaLinks")}
          selectAll={{ type: "checkbox", label: t("form.all_social_media") }}
          values={socialMediaLinks || []}
          value={formState.socialMediaLinks}
          disabled={!comprehensiveReportLoaded}
        />
        <InputCheckbox
          label={t("form.all_risk_flags")}
          onChange={setFormAttribute("riskFlags")}
          value={formState.riskFlags}
          disabled={!(comprehensiveReportLoaded && riskFlags)}
        />
        <If condition={!comprehensiveReportLoaded}>
          {t("form.person_report_notice_no_report")}
        </If>
        <If condition={comprehensiveReportLoaded}>
          {t("form.person_report_notice_with_report")}
        </If>
      </Flex>
    </Modal>
  );
};
LinkPotentialConnectionModal.propTypes = {
  /** Whether modal should be hidden or not */
  hidden: PropTypes.bool.isRequired,
  /** A function to change the state variable which determines whether this modal is hidden */
  setModalTypeVisibility: PropTypes.func.isRequired,
  /** Action to take when cancel button is clicked */
  onCancel: PropTypes.func.isRequired,
  /** When provided, will prepopulate the InputFilterable with the provided child(ren) */
  childAgencyHumans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string,
    })
  ),
  setChildAgencyHumansForSuccessModal: PropTypes.func.isRequired,
  searchData: PropTypes.shape({
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        addressLine1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        valid: PropTypes.bool,
      })
    ),
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        phoneNumber: PropTypes.string.isRequired,
        valid: PropTypes.bool.isRequired,
        phoneNumberType: PropTypes.string,
      })
    ),
    emailAddresses: PropTypes.arrayOf(PropTypes.string),
    ssn: PropTypes.string,
  }),
  socialMediaLinks: PropTypes.arrayOf(PropTypes.string),
  riskFlags: PropTypes.arrayOf(PropTypes.string),
  comprehensiveReportLoaded: PropTypes.bool,
  comprehensiveReportId: PropTypes.string,
};

export default LinkPotentialConnectionModal;
