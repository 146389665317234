import { InputDate } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import styles from "./PlacementEnd.module.scss";
import PlacementEndedReason from "./PlacementEndedReason";

const PlacementEnd = ({ endDate: originalEndDate, ...props }) => {
  const [endDate, setEndDate] = useState(originalEndDate);

  return (
    <div className={styles.inputs}>
      <InputDate
        label={<T t="placement_end.date_placement_ended" />}
        name="placement_period[end_date]"
        value={endDate}
        onChange={setEndDate}
      />

      <If condition={endDate}>
        <PlacementEndedReason {...props} />
      </If>
    </div>
  );
};

PlacementEnd.propTypes = {
  daysOfNotice: PropTypes.string,
  endDate: PropTypes.string,
  endedExplanation: PropTypes.string,
  endedReason: PropTypes.string,
  isCurrentPlacement: PropTypes.bool.isRequired,
  requester: PropTypes.string,
};

export default PlacementEnd;
