import { useMutation } from "@apollo/client";
import {
  Actions,
  Flex,
  LoadingOverlay,
  Surface,
  SurfaceForm,
} from "@heart/components";
import I18n from "i18n-js";
import _ from "lodash";
import { useState } from "react";

import CreatePlacementSearchMutation from "@graphql/mutations/CreatePlacementSearch.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import AgenciesAssignedForm from "./agencies_assigned/AgenciesAssignedForm";
import AddAgencies from "./agencies_assigned/add_agencies/AddAgencies";
import PlacementSearchDetailsForm from "./placement_search_details/PlacementSearchDetailsForm";
import WorkersAssignedForm from "./workers_assigned/WorkersAssignedForm";
import AddWorkers from "./workers_assigned/add_workers/AddWorkers";

const getRedirectUrl = (childId, placementSearchId) =>
  `/admin/children/${childId}/placement_periods/new?placement_search_id=${placementSearchId}`;

const isPlacementFound = search => search?.reasonClosed === "placement_found";

const isRespite = search => search?.reasonClosed === "respite";

const CreatePlacementSearchForm = ({ childId }) => {
  const [formState, setFormState] = useState({
    agencyWorkers: [],
    assignedSearches: [],
  });

  const [createPlacementSearch, { loading }] = useMutation(
    CreatePlacementSearchMutation,
    {
      onCompleted: data => {
        const newSearch = data.createPlacementSearch.placementSearch;
        if (isPlacementFound(newSearch)) {
          window.location = getRedirectUrl(
            childId,
            data.createPlacementSearch.placementSearch.id
          );
        } else if (isRespite(newSearch)) {
          window.location = `/admin/children/${childId}/respites/new`;
        } else {
          window.location = "/admin/placement_search_dashboards";
        }
      },
    }
  );

  const setFormAttribute = attributeName => value =>
    setFormState({ ...formState, [attributeName]: value });

  const onSubmit = () =>
    createPlacementSearch({
      variables: {
        input: {
          ..._.omit(
            formState,
            "__typename",
            "agencyWorkers",
            "isAssignedFromOtherAgency",
            "assignedSearches"
          ),
          agencyWorkerIds: formState.agencyWorkers?.map(aw => aw.id),
          assignedAgencyIds:
            formState.assignedSearches?.map(as => as.agency.id) || [],
          childId,
        },
      },
    });

  // TOOD: i18n
  return (
    <LoadingOverlay active={loading}>
      <SurfaceForm
        actions={
          <Actions
            primaryText={isPlacementFound ? "Save and Continue" : "Submit"}
          />
        }
        title="Create Placement Search"
        onSubmit={preventDefault(onSubmit)}
      >
        <Surface
          title={I18n.t(
            "javascript.components.placement_search.workers_assigned.title"
          )}
        >
          <Flex column>
            <WorkersAssignedForm
              formState={formState}
              onChange={({ agencyWorkers, primaryWorkerAssignedId }) =>
                setFormState({
                  ...formState,
                  agencyWorkers,
                  primaryWorkerAssignedId,
                })
              }
            />
            <AddWorkers
              childId={childId}
              onSubmit={newWorkers =>
                setFormState({
                  ...formState,
                  agencyWorkers: [...formState.agencyWorkers, ...newWorkers],
                })
              }
            />
          </Flex>
        </Surface>
        <Surface
          title={I18n.t(
            "javascript.components.placement_search.agencies_assigned.title"
          )}
        >
          <Flex column>
            <AgenciesAssignedForm
              formState={formState}
              onChange={setFormAttribute("assignedSearches")}
            />
            <AddAgencies
              onSubmit={newAgencies =>
                setFormState({
                  ...formState,
                  assignedSearches: [
                    ...formState.assignedSearches,
                    ...newAgencies.map(agency => ({ agency })),
                  ],
                })
              }
            />
          </Flex>
        </Surface>
        <PlacementSearchDetailsForm
          formState={formState}
          setFormAttribute={setFormAttribute}
          childId={childId}
        />
      </SurfaceForm>
    </LoadingOverlay>
  );
};

CreatePlacementSearchForm.propTypes = {
  childId: BintiPropTypes.ID,
};

export default CreatePlacementSearchForm;
