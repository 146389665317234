import { isNil } from "lodash";

/** Determine existing search params in URL */
export const getSearchParams = () =>
  new URLSearchParams(window.location.search);

/** Determine existing search params in URL for a specific attribute */
export const getSearchParamForAttribute = ({ attribute }) => {
  const params = getSearchParams();
  return params.get(attribute);
};

/** Maintain existing search params & update provided attribute to value */
export const setSearchParams = ({ attribute, value }) => {
  const params = getSearchParams();
  if (isNil(value) || value === "") {
    params.delete(attribute);
  } else {
    params.set(attribute, value);
  }
  window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
};

/** Clear all but provided search params */
export const clearAllOtherSearchParams = ({ preserveAttributes = [] }) => {
  const preservedAttrs = new URLSearchParams();
  preserveAttributes.map(attribute => {
    const val = getSearchParamForAttribute({ attribute });
    return val ? preservedAttrs.set(attribute, val) : undefined;
  });
  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${preservedAttrs}`
  );
};
