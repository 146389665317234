import { Flex, Icons, Text } from "@heart/components";
import listStyles from "@heart/core/invisible_list.module.scss";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import SurfaceBase from "../../surface/SurfaceBase";

/**
 * A "recessed" surface to use for global form errors.
 *
 * ### Cypress
 *
 * To target this component in Cypress, use `cy.findNotification()`.
 */
const Notice = ({ title, children, "data-testid": testId, errorList = [] }) => (
  <SurfaceBase
    rootElement="section"
    rootElementProps={{ role: "alert" }}
    variant="recessed"
    title={title}
    data-testid={testId}
  >
    <Flex column>
      <If condition={children}>{children}</If>
      <If condition={!isEmpty(errorList)}>
        <ul className={listStyles.invisibleList}>
          {errorList.map(error => (
            <li key={error}>
              <Flex row>
                <Text textColor="danger-500">
                  <Icons.ExclamationTriangle />
                </Text>
                {error}
              </Flex>
            </li>
          ))}
        </ul>
      </If>
    </Flex>
  </SurfaceBase>
);

Notice.propTypes = {
  /** Title of the Notice, displayed in the top left. */
  title: PropTypes.string.isRequired,
  /** Notice contents */
  children: PropTypes.node,
  /** A list of errors to display beneath the children */
  errorList: PropTypes.arrayOf(PropTypes.node),
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default Notice;
