import { useQuery } from "@apollo/client";
import { Link } from "@heart/components";
import Honeybadger from "@honeybadger-io/js";
import download from "downloadjs";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { useCallback } from "react";

import FeatureFlag from "@graphql/queries/FeatureFlag.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

/**
 * Links to an attachment for download, potentially transforming HEIC/HEIF files
 * to JPGs.
 *
 * @param {Attachment} attachment the attachment for which to display a download link
 */
const AttachmentDownloadLink = ({ attachment }) => {
  const { data } = useQuery(FeatureFlag, {
    variables: { flag: "ff_heic_download_conversions_01_2024" },
  });

  const onDownloadHeic = useCallback(
    () =>
      // heic2any is fairly large, so only load it on-demand
      import("heic2any").then(async ({ default: heic2any }) => {
        const response = await fetch(attachment.downloadUrl);
        const blob = await response.blob();

        try {
          const jpeg = await heic2any({ blob, toType: "image/jpeg" });
          const filename = attachment.filename.replace(/\.hei[cf]$/i, ".jpg");
          download(jpeg, filename, "image/jpeg");
        } catch (err) {
          Honeybadger.notify(err);
          // if the jpeg conversion fails, fallback to downloading the original
          download(blob, attachment.filename, attachment.contentType);
        }
      }),
    [attachment]
  );

  if (
    data?.featureFlag &&
    ["image/heif", "image/heic"].includes(attachment.contentType)
  ) {
    return <Link onClick={onDownloadHeic}>{attachment.name}</Link>;
  }

  return (
    <Link href={attachment.downloadUrl} target={`attachment${attachment.id}`}>
      {attachment.name}
    </Link>
  );
};

AttachmentDownloadLink.propTypes = {
  attachment: PropTypes.shape({
    id: BintiPropTypes.ID.isRequired,
    downloadUrl: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default AttachmentDownloadLink;
