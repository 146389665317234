import { useQuery, useMutation } from "@apollo/client";
import { Actions, LoadingOverlay, SurfaceForm } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import UpdatePlacementSearchDetailsMutation from "@graphql/mutations/UpdatePlacementSearchDetails.graphql";
import PlacementSearchDetailsQuery from "@graphql/queries/PlacementSearchDetails.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import PlacementSearchDetailsForm from "./PlacementSearchDetailsForm";

const { t } = translationWithRoot("placement_search");

const PlacementSearchDetailsEdit = ({ id, childId, onCompleted }) => {
  const { data, loading: dataLoading } = useQuery(PlacementSearchDetailsQuery, {
    variables: { id },
  });

  const { formState, setFormAttribute } = useBintiForm(data?.placementSearch);

  const [updatePlacementSearch, { loading: updateLoading }] = useMutation(
    UpdatePlacementSearchDetailsMutation,
    { onCompleted }
  );

  const onSubmit = () =>
    updatePlacementSearch({
      variables: {
        input: {
          ..._.omit(
            formState,
            "__typename",
            "isAssignedFromOtherAgency",
            "primaryWorkerAssignedId"
          ),
        },
      },
    });

  return (
    <LoadingOverlay active={dataLoading || updateLoading}>
      <SurfaceForm
        title={t("edit_placement_search")}
        actions={<Actions />}
        onSubmit={preventDefault(onSubmit)}
      >
        <PlacementSearchDetailsForm
          formState={formState}
          setFormAttribute={setFormAttribute}
          childId={childId}
        />
      </SurfaceForm>
    </LoadingOverlay>
  );
};

PlacementSearchDetailsEdit.propTypes = {
  id: BintiPropTypes.ID,
  childId: BintiPropTypes.ID,
  onCompleted: PropTypes.func,
};

export default PlacementSearchDetailsEdit;
