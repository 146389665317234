import classnames from "classnames";
import PropTypes from "prop-types";
import { Fragment } from "react";

import styles from "./AfcarsTable.module.scss";

const AfcarsTable = ({ headers, data, key, stats, normalizeNumbers, note }) => (
  <Fragment>
    <table className={classnames(styles.infoTable, { [styles.stats]: stats })}>
      <thead>
        <tr>
          {headers.map(header => (
            <th key={header} scope="col">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={`${key}-row-${index}`}>
            {row.map((cell, cellIndex) => {
              // consistent numeric comma formatting
              if (normalizeNumbers && cellIndex === row.length - 1) {
                try {
                  return (
                    <td key={cell}>
                      {parseFloat(
                        cell.replace(/[^0-9.]/g, ""),
                        10
                      ).toLocaleString("en-US")}
                    </td>
                  );
                } catch {
                  // whatever
                }
              }

              return <td key={cell}>{cell}</td>;
            })}
          </tr>
        ))}
      </tbody>
    </table>
    <If condition={note}>
      <p style={{ width: "95%", margin: "0 20px" }}>
        <b>NOTE:</b> {note}
      </p>
    </If>
  </Fragment>
);

AfcarsTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  key: PropTypes.string.isRequired,
  stats: PropTypes.bool,
  normalizeNumbers: PropTypes.bool,
  note: PropTypes.string,
};

export default AfcarsTable;
