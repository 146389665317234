import { useQuery } from "@apollo/client";
import { LoadingOverlay, Surface } from "@heart/components";

import PlacementSearchQuery from "@graphql/queries/PlacementSearch.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import AgenciesAssignedEdit from "./agencies_assigned/AgenciesAssignedEdit";
import PlacementSearchDetails from "./placement_search_details/PlacementSearchDetails";
import WorkersAssignedEdit from "./workers_assigned/WorkersAssignedEdit";

const UpdatePlacementSearchForm = ({ id, childId }) => {
  const { data, loading: dataLoading } = useQuery(PlacementSearchQuery, {
    variables: { id },
  });

  return (
    <LoadingOverlay active={dataLoading}>
      <Surface>
        <WorkersAssignedEdit placementSearchId={id} childId={childId} />
        <If condition={!data?.placementSearch?.isAssignedFromOtherAgency}>
          <AgenciesAssignedEdit placementSearchId={id} childId={childId} />
        </If>
        <PlacementSearchDetails id={id} childId={childId} />
      </Surface>
    </LoadingOverlay>
  );
};

UpdatePlacementSearchForm.propTypes = {
  id: BintiPropTypes.ID,
  childId: BintiPropTypes.ID,
};

export default UpdatePlacementSearchForm;
