import { useQuery } from "@apollo/client";
import {
  Layout,
  Surface,
  GraphQLDataTable,
  TableCellList,
  LabeledContent,
} from "@heart/components";
import { isEmpty, snakeCase } from "lodash";
import { DateTime } from "luxon";

import { translationWithRoot } from "@components/T";

import ClearPersonSearches from "@graphql/queries/ClearPersonSearches.graphql";
import CurrentUserQuery from "@graphql/queries/CurrentUser.graphql";

import { LUXON_DATETIME_WITH_ZONE } from "@lib/dates";

const { t } = translationWithRoot("family_finding.potential_kin_search");

const renderSearchFields = ({ searchFields = {} }) => (
  <TableCellList
    items={Object.keys(searchFields)
      .map(
        fieldName =>
          // Skip the __typename field from GraphQL
          fieldName !== "__typename" &&
          // Skip empty fields
          !isEmpty(searchFields[fieldName]) && (
            <LabeledContent
              key={fieldName}
              label={t(`search_fields.${snakeCase(fieldName)}`)}
              content={searchFields[fieldName]}
            />
          )
      )
      .filter(Boolean)}
  />
);

const Table = () => {
  const { data: currentUser } = useQuery(CurrentUserQuery);

  const columns = [
    {
      columnName: { name: t("audit_trail.columns.created_at") },
      id: "createdAt",
      cell: ({ createdAt }) =>
        DateTime.fromISO(createdAt).toFormat(LUXON_DATETIME_WITH_ZONE),
    },
    currentUser?.user?.bintiAdmin && {
      columnName: { name: t("audit_trail.columns.agency") },
      id: "agencyName",
      cell: "agency.name",
    },
    {
      columnName: { name: t("audit_trail.columns.search_fields") },
      id: "searchFields",
      cell: renderSearchFields,
    },
    {
      columnName: { name: t("audit_trail.columns.initiator") },
      id: "initiator",
      cell: "initiator.name",
    },
  ].filter(Boolean);

  const filters = [
    {
      label: t("audit_trail.filters.created_at"),
      type: "custom_dates",
      field: "createdAt",
    },
    {
      label: t("audit_trail.filters.criteria"),
      type: "search",
      field: "searchCriteriaContains",
    },
    {
      label: t("audit_trail.filters.worker"),
      type: "search",
      field: "workerNameContains",
    },
  ];

  return (
    <Surface title={t("audit_trail.table_title")}>
      <GraphQLDataTable
        query={ClearPersonSearches}
        queryTitle="clearPersonSearches"
        filters={filters}
        columns={columns}
        defaultSort={{ sortBy: "createdAt", sortDirection: "DESC" }}
      />
    </Surface>
  );
};

const AuditTrail = () => (
  <Layout
    pageTitle={t("audit_trail.page_title")}
    main={{ content: <Table /> }}
  />
);

export default AuditTrail;
