import { useQuery } from "@apollo/client";
import {
  InputDropdown,
  InputDate,
  InputTextarea,
  LoadingOverlay,
} from "@heart/components";
import { PropTypes } from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import PlacementSearchFormDataQuery from "@graphql/queries/PlacementSearchFormData.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import ReasonSearchClosed from "../ReasonSearchClosed";

const { t } = translationWithRoot("placement_search");

export const reasonClosedNeedsExplanationTitles = [
  "closed_other",
  "silp",
  "hospitalization",
  "juvenile_detention_center",
  "hotel",
  "receiving_center",
  "office",
  "shelter",
  "other",
].reduce(
  (reasons, reason) => ({
    ...reasons,
    [reason]: t(`reason_closed_explanation_title.${reason}`),
  }),
  {}
);

const PlacementSearchDetailsForm = ({
  childId,
  formState,
  setFormAttribute,
}) => {
  const { data: formData, loading } = useQuery(PlacementSearchFormDataQuery, {
    variables: { childId },
  });

  const isPlacementFound = formState?.reasonClosed === "placement_found";

  const { reasonRequestedOptions, closedOptions, responseOptions } =
    formData?.placementSearchFormData || {
      reasonRequestedOptions: [],
      closedOptions: [],
      responseOptions: [],
    };

  return (
    <LoadingOverlay active={loading}>
      <Fragment>
        <InputDate
          label={t("date_placement_requested")}
          value={formState.requestedAt}
          onChange={setFormAttribute("requestedAt")}
          required
        />
        <InputDropdown
          label={t("reason_requested.title")}
          value={formState.reasonRequested}
          values={reasonRequestedOptions}
          onChange={setFormAttribute("reasonRequested")}
        />
        <InputTextarea
          label={t("reason_requested_explanation")}
          value={formState.reasonRequestedExplanation}
          onChange={setFormAttribute("reasonRequestedExplanation")}
        />
        <InputDate
          label={t("date_placement_due")}
          value={formState.dueAt}
          onChange={setFormAttribute("dueAt")}
        />
        <InputDate
          label={t("search_completed_date")}
          value={formState.closedAt}
          onChange={setFormAttribute("closedAt")}
          required={isPlacementFound}
        />
        <ReasonSearchClosed
          hasPreviousPlacement={formState.hasPreviousPlacement}
          values={closedOptions}
          value={formState.reasonClosed}
          onChange={setFormAttribute("reasonClosed")}
        />
        <If
          condition={reasonClosedNeedsExplanationTitles[formState.reasonClosed]}
        >
          <InputTextarea
            label={reasonClosedNeedsExplanationTitles[formState.reasonClosed]}
            value={formState.reasonClosedOtherExplanation}
            onChange={setFormAttribute("reasonClosedOtherExplanation")}
          />
        </If>
        <If condition={formState?.isAssignedFromOtherAgency}>
          <InputDropdown
            label={t("response")}
            value={formState.response}
            values={responseOptions}
            onChange={setFormAttribute("response")}
          />
        </If>
      </Fragment>
    </LoadingOverlay>
  );
};

PlacementSearchDetailsForm.propTypes = {
  childId: BintiPropTypes.ID,
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
};

export default PlacementSearchDetailsForm;
