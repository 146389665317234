import { gql, useMutation, useQuery } from "@apollo/client";
import React from "react";

import UpdateChildAgencyPlacementReferralMutation from "@graphql/mutations/UpdateChildAgencyPlacementReferral.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";

import ChildAgencyPlacementReferralForm from "./ChildAgencyPlacementReferralForm";
import ChildAgencyPlacementReferralPage from "./ChildAgencyPlacementReferralPage";

const UpdateChildAgencyPlacementReferral = ({ childId, referralId }) => {
  const [
    updateChildReferral,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UpdateChildAgencyPlacementReferralMutation, {
    onCompleted: () => {
      window.location.href = "/admin/children";
    },
  });

  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useQuery(
    gql`
      query ChildAgencyPlacementReferral($id: ID!) {
        childAgencyPlacementReferral(id: $id) {
          id
          assignedAt
          withdrawnAt
          referredAgencyId
        }
      }
    `,
    { variables: { id: referralId } }
  );

  return (
    <ChildAgencyPlacementReferralPage
      childId={childId}
      loading={queryLoading || mutationLoading}
      error={mutationError || queryError}
    >
      <ChildAgencyPlacementReferralForm
        childId={childId}
        onSubmit={formState =>
          updateChildReferral({
            variables: { input: formState },
          })
        }
        referral={data?.childAgencyPlacementReferral}
      />
    </ChildAgencyPlacementReferralPage>
  );
};

UpdateChildAgencyPlacementReferral.propTypes = {
  childId: BintiPropTypes.ID,
  referralId: BintiPropTypes.ID,
};

export default UpdateChildAgencyPlacementReferral;
