import { Button, InputDropdown } from "@heart/components";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";

import preventDefault from "@lib/preventDefault";

import Block from "./Block";

class ListBlock extends Component {
  constructor(props) {
    super(props);
    let items = this.props.items || [];

    if (items.length === 0 && this.props.ask_number_value === undefined) {
      items = [this.templateCopy(1)];
    }
    this.state = {
      items: items,
      maxIndex: items.length,
    };
  }

  componentDidMount() {
    if (this.props.ask_number_value) {
      this.handleAskNumberChange(this.props.ask_number_value);
    } else if (this.props.minimum > this.state.items.length) {
      this.handleAskNumberChange(this.props.minimum);
    }
  }

  templateCopy = index =>
    // We need to replace all instances of the placeholder,
    // including nested list blocks or conditional blocks
    this.props.template.map(qTemplate => {
      let question = JSON.stringify(qTemplate);
      const re = new RegExp(this.props.template_placeholder, "g");
      question = question.replace(re, index);
      return JSON.parse(question);
    });

  addBlock = () => {
    const newIndex = this.state.maxIndex + 1;

    return {
      items: this.state.items.concat([this.templateCopy(newIndex)]),
      maxIndex: newIndex,
    };
  };

  removeBlock = index => {
    let newItems = this.state.items.slice();

    newItems.splice(index, 1);
    if (newItems.length === 0 && this.props.ask_number_value === undefined) {
      newItems = [this.templateCopy(1)];
    }
    return {
      items: newItems,
    };
  };

  handleAddClick = preventDefault(() => {
    this.setState(this.addBlock());
  });

  handleRemoveClick = index => {
    this.setState(this.removeBlock(index));
  };

  handleAskNumberChange = val => {
    const intVal = parseInt(val, 10);
    if (intVal === this.state.items.length || isNaN(intVal)) {
      return;
    }

    if (intVal > this.state.items.length) {
      this.setState(this.addBlock(), () => this.handleAskNumberChange(intVal));
    } else {
      this.setState(this.removeBlock(this.state.items.length - 1), () =>
        this.handleAskNumberChange(intVal)
      );
    }
  };

  render() {
    const { items } = this.state;
    const canRemove = !this.props.ask_number;
    const showAdd = this.props.can_grow && items.length < this.props.maximum;

    return (
      <div>
        <p>{this.props.title}</p>
        <p className="description">{this.props.description}</p>
        {this.props.ask_number && (
          <InputDropdown
            label={this.props.ask_number}
            id={this.props.ask_number_id}
            values={this.props.ask_number_values}
            value={`${this.props.ask_number_value}`}
            name={this.props.ask_number_name}
            onChange={this.handleAskNumberChange}
            disabled={this.props.disabled}
          />
        )}
        {items.map((block, idx) => (
          <div className={`test-list-block-${idx + 1}`} key={block[0].id}>
            <Block
              questions={block}
              title={
                <Fragment>
                  {this.props.title} {idx + 1}
                </Fragment>
              }
              onRemoveClick={() => this.handleRemoveClick(idx)}
              canRemove={canRemove}
              disabled={this.props.disabled}
              renderErrors={this.props.renderErrors}
            />
          </div>
        ))}
        {showAdd && (
          <Button
            className="pull-right test-add-another-btn"
            onClick={this.handleAddClick}
            disabled={this.props.disabled}
          >
            {I18n.t("views.questions.list_block.add_another")}
          </Button>
        )}
      </div>
    );
  }
}

ListBlock.propTypes = {
  can_grow: PropTypes.bool,
  ask_number: PropTypes.string,
  ask_number_id: PropTypes.string,
  ask_number_name: PropTypes.string,
  ask_number_value: PropTypes.number,
  ask_number_values: PropTypes.array,
  template: PropTypes.array,
  template_placeholder: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.node,
  description: PropTypes.node,
  minimum: PropTypes.number,
  maximum: PropTypes.number,
  disabled: PropTypes.bool,
  renderErrors: PropTypes.bool,
};
export default ListBlock;
