import classnames from "classnames";
import PropTypes from "prop-types";
import { useState, useEffect, Fragment } from "react";

import styles from "./NavBar.module.scss";
import NavBarMenu from "./NavBarMenu";

const notificationPreferencesLink = Object.freeze({
  path: "/notification_preferences",
  type: "GET",
  name: I18n.t("views.applications.navbar.my_account.notification_preferences"),
});

const NavBar = ({
  currentUrl,
  logo,
  root,
  userName,
  signOut,
  switchTo = [],
  showMasquerading = false,
  stopMasquerading,
  showLicenseManagerHomepage = false,
  licenseManagerHomepageLink,
  showLocales = false,
  locales,
  showMyDocuments = false,
  showMyDocumentsAlert = false,
  showNotificationPreferences = false,
  myDocumentsLink,
  showStaffRoster = false,
  showStaffRequirements = false,
  staffRosterLink,
  staffRequirementsLink,
  contactsLink,
  hideLogo = false,
  showContacts,
  showSelfWithdraw = false,
  showPersonalInformation = false,
  childrenWithViewableProfiles = [],
  appId,
  hideAllLinks = false,
  showYouthPreferences = false,
  youthPreferencesLink,
}) => {
  const [selected, setSelected] = useState(null);

  const tempLinkNamesMap = {
    myDocuments: "My Documents",
    staffRoster: "Staff Roster",
    staffRequirements: "Staff Requirements",
    application: "Application",
    forms: "Forms",
    contacts: "Contacts",
    applicationStages: "Application Stages",
    youthPreferences: "Youth Preferences",
  };
  childrenWithViewableProfiles.forEach(({ name, childId }) => {
    tempLinkNamesMap[childId] = name;
  });
  const linkNamesMap = Object.freeze(tempLinkNamesMap);

  // NOTE: Order of the patterns matter, example: my_documents links have
  // application in their path as well. my_documents link will match on
  // linkNamesMap.myDocuments and linkNamesMap.application
  const patterns = Object.freeze(
    [
      [linkNamesMap.myDocuments, /\/my_documents$/],
      [linkNamesMap.staffRoster, /\/staff_roster$/],
      [linkNamesMap.staffRequirements, /\/staff_requirements$/],
      [linkNamesMap.contacts, /\/agency_contacts$/],
      [linkNamesMap.applicationStages, /\/application_stages\//],
      [linkNamesMap.forms, /\/forms\//],
      [linkNamesMap.application, /\/application\//],
    ].concat(
      childrenWithViewableProfiles.map(({ path, childId }) => [
        linkNamesMap[childId],
        new RegExp(path),
      ])
    )
  );

  useEffect(() => {
    const match = patterns.filter(
      pattern => currentUrl.match(pattern[1]) != null
    );
    if (!match || match.length === 0) {
      setSelected(null);
    } else {
      // we want to select based on first match so we select the zeroth index
      // Look at the comment for patterns above
      setSelected(match[0][0]);
    }
  });

  const transformedLocales = (locales || []).map(locale => ({
    name: I18n.t("language_name", { locale }),
    type: "POST",
    path: `/users/locale/${locale}`,
    data: "",
    dataType: "text",
    redirectLink: null,
  }));

  const isSelected = (matches = []) =>
    matches.filter(match => match === selected).length >= 1;

  const applicationSelected = (other = []) =>
    isSelected([
      linkNamesMap.application,
      linkNamesMap.forms,
      linkNamesMap.applicationStages,
      ...other,
    ]);

  const kidsInMyCareSelected = () =>
    isSelected(
      childrenWithViewableProfiles.map(({ childId }) => linkNamesMap[childId])
    );

  const showMyDocs = showDocs =>
    showDocs && applicationSelected([linkNamesMap.myDocuments]);

  const showRoster = showStaffRos =>
    showStaffRos && applicationSelected([linkNamesMap.staffRoster]);

  const showRequirements = showStaffReq =>
    showStaffReq && applicationSelected([linkNamesMap.staffRequirements]);

  const BintiLogo = () =>
    !hideLogo && (
      <div className={styles["binti-logo"]}>
        <a href={root}>
          <img src={logo} alt="Binti Logo" />
        </a>
      </div>
    );

  const ChildProfileListItem = () =>
    childrenWithViewableProfiles.length > 0 && (
      <li
        className={classnames(
          {
            [styles.active]: kidsInMyCareSelected(),
          },
          styles["nav-bar-link"]
        )}
      >
        <a
          className={classnames({
            [styles["selected-link"]]: kidsInMyCareSelected(),
          })}
          href={childrenWithViewableProfiles[0].path}
        >
          <i
            className={classnames(styles["align-middle"], "fas", "fa-child")}
          />
          <div
            className={classnames(
              styles["align-middle"],
              styles["inline-block"]
            )}
          >
            {I18n.t("views.child_profile.kids_in_my_care")}
          </div>
        </a>
        {kidsInMyCareSelected() && (
          <ul className={classnames(styles["sub-list"])}>
            {childrenWithViewableProfiles.map(({ path, name, childId }) => (
              <li key={childId}>
                <a
                  className={classnames({
                    [styles["selected-link"]]: isSelected([
                      linkNamesMap[childId],
                    ]),
                  })}
                  href={path}
                >
                  <div
                    className={classnames(
                      styles["align-middle"],
                      styles["inline-block"]
                    )}
                  >
                    {name}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    );

  const ApplicationListItem = () => {
    const MyDocumentsList = () =>
      showMyDocs(showMyDocuments) && (
        <ul className={classnames(styles["sub-list"])}>
          <li>
            <a
              className={classnames({
                [styles["selected-link"]]: isSelected([
                  linkNamesMap.myDocuments,
                ]),
              })}
              href={myDocumentsLink}
            >
              <div
                className={classnames(
                  styles["align-middle"],
                  styles["inline-block"]
                )}
              >
                {showMyDocumentsAlert && (
                  <i
                    className="fa fa-exclamation-triangle"
                    aria-hidden="true"
                  />
                )}
                {I18n.t("views.common.my_documents")}
              </div>
            </a>
          </li>
        </ul>
      );

    const StaffRoster = () =>
      showRoster(showStaffRoster) && (
        <ul className={classnames(styles["sub-list"])}>
          <li>
            <a
              className={classnames({
                [styles["selected-link"]]: isSelected([
                  linkNamesMap.staffRoster,
                ]),
              })}
              href={staffRosterLink}
            >
              <div
                className={classnames(
                  styles["align-middle"],
                  styles["inline-block"]
                )}
              >
                {I18n.t("views.common.staff_roster")}
              </div>
            </a>
          </li>
        </ul>
      );

    const StaffRequirements = () =>
      showRequirements(showStaffRequirements) && (
        <ul className={classnames(styles["sub-list"])}>
          <li>
            <a
              className={classnames({
                [styles["selected-link"]]: isSelected([
                  linkNamesMap.staffRequirements,
                ]),
              })}
              href={staffRequirementsLink}
            >
              <div
                className={classnames(
                  styles["align-middle"],
                  styles["inline-block"]
                )}
              >
                {I18n.t("views.common.staff_requirements")}
              </div>
            </a>
          </li>
        </ul>
      );
    return (
      <li
        className={classnames(
          {
            [styles.active]: applicationSelected([linkNamesMap.myDocuments]),
            [styles.active]: applicationSelected([linkNamesMap.staffRoster]),
          },
          styles["nav-bar-link"]
        )}
      >
        <a
          className={classnames({
            [styles["selected-link"]]: applicationSelected(),
          })}
          href="/"
        >
          <i
            className={classnames(styles["align-middle"], "fas", "fa-tasks")}
          />
          <div
            className={classnames(
              styles["align-middle"],
              styles["inline-block"]
            )}
          >
            {I18n.t("views.common.application")}
          </div>
        </a>
        <MyDocumentsList />
        <StaffRoster />
        <StaffRequirements />
      </li>
    );
  };

  const ContactsListItem = () =>
    showContacts && (
      <li
        className={classnames(
          { [styles.active]: isSelected([linkNamesMap.contacts]) },
          styles["nav-bar-link"]
        )}
      >
        <a
          className={classnames({
            [styles["selected-link"]]: isSelected([linkNamesMap.contacts]),
          })}
          href={contactsLink}
        >
          <i
            className={classnames(
              styles["align-middle"],
              "far",
              "fa-address-card"
            )}
          />
          <div
            className={classnames(
              styles["align-middle"],
              styles["inline-block"]
            )}
          >
            {I18n.t("views.common.need_help")}
          </div>
        </a>
      </li>
    );

  const YouthPreferencesListItem = () => {
    if (!showYouthPreferences) {
      return null;
    }

    return (
      <li
        className={classnames(
          { [styles.active]: isSelected([linkNamesMap.youthPreferences]) },
          styles["nav-bar-link"]
        )}
      >
        <a
          className={classnames({
            [styles["selected-link"]]: isSelected([
              linkNamesMap.youthPreferences,
            ]),
          })}
          href={youthPreferencesLink}
        >
          <i
            className={classnames(
              styles["align-middle"],
              "fas",
              "fa-sliders-h"
            )}
          />
          <div
            className={classnames(
              styles["align-middle"],
              styles["inline-block"]
            )}
          >
            {I18n.t(
              "javascript.components.youth_preferences.youth_preferences"
            )}
          </div>
        </a>
      </li>
    );
  };

  const MyAccountSettingsMenu = () => {
    if (!showSelfWithdraw) {
      return false;
    }

    return (
      <NavBarMenu
        title={I18n.t(
          "views.applications.navbar.my_account.my_account_settings"
        )}
        links={[
          {
            path: `/applications/${appId}/self_withdraw`,
            type: "GET",
            name: I18n.t("views.applications.navbar.my_account.link.title"),
          },
        ]}
        currentUrl={currentUrl}
      />
    );
  };

  const LocalesMenu = () =>
    showLocales && (
      <NavBarMenu
        iconClass="fa-globe"
        title={I18n.t("views.common.select_language")}
        links={transformedLocales}
        currentUrl={currentUrl}
      />
    );

  const MinimalLinks = () => (
    <NavBarMenu
      title={userName}
      links={[
        signOut,
        ...(showMasquerading ? [stopMasquerading] : []),
        ...(showLicenseManagerHomepage ? [licenseManagerHomepageLink] : []),
      ]}
      linkClass="test-end-user-menu"
      currentUrl={currentUrl}
    />
  );

  const personalInformationLink = Object.freeze({
    path: `/applications/${appId}/applicant_personal_information`,
    type: "GET",
    name: I18n.t("views.applications.navbar.my_account.ethnicity_profile"),
  });

  const AllLinks = () => (
    <Fragment>
      <ul className={styles.list}>
        <ApplicationListItem />
        <ChildProfileListItem />
        <ContactsListItem />
        <YouthPreferencesListItem />
      </ul>
      <NavBarMenu
        title={userName}
        currentUrl={currentUrl}
        links={[
          ...switchTo,
          ...(showNotificationPreferences ? [notificationPreferencesLink] : []),
          ...(showPersonalInformation ? [personalInformationLink] : []),
          signOut,
          ...(showMasquerading ? [stopMasquerading] : []),
          ...(showLicenseManagerHomepage ? [licenseManagerHomepageLink] : []),
        ]}
        linkClass="test-end-user-menu"
      />
      <MyAccountSettingsMenu />
      <LocalesMenu />
    </Fragment>
  );

  return (
    <nav className={styles.navbar}>
      <BintiLogo />
      {hideAllLinks ? <MinimalLinks /> : <AllLinks />}
    </nav>
  );
};

NavBar.propTypes = {
  appId: PropTypes.number.isRequired,
  contactsLink: PropTypes.string.isRequired,
  childrenWithViewableProfiles: PropTypes.array,
  currentUrl: PropTypes.string.isRequired,
  hideLogo: PropTypes.bool,
  hideAllLinks: PropTypes.bool,
  licenseManagerHomepageLink: PropTypes.object,
  locales: PropTypes.array,
  logo: PropTypes.string.isRequired,
  myDocumentsLink: PropTypes.string.isRequired,
  root: PropTypes.string.isRequired,
  showContacts: PropTypes.bool.isRequired,
  showLocales: PropTypes.bool,
  showLicenseManagerHomepage: PropTypes.bool,
  showMasquerading: PropTypes.bool,
  showMyDocuments: PropTypes.bool,
  showMyDocumentsAlert: PropTypes.bool,
  showNotificationPreferences: PropTypes.bool,
  showPersonalInformation: PropTypes.bool,
  showSelfWithdraw: PropTypes.bool,
  showYouthPreferences: PropTypes.bool,
  signOut: PropTypes.object.isRequired,
  showStaffRoster: PropTypes.bool,
  showStaffRequirements: PropTypes.bool,
  staffRosterLink: PropTypes.string.isRequired,
  staffRequirementsLink: PropTypes.string.isRequired,
  stopMasquerading: PropTypes.object,
  switchTo: PropTypes.array,
  userName: PropTypes.string.isRequired,
  youthPreferencesLink: PropTypes.string.isRequired,
};

export default NavBar;
